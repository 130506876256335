import React, { useState, useEffect } from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Input, Switch, Text,
  useColorModeValue, useToast, Table, Thead, Tbody, Tr, Th, Td,
  Badge, Progress, Stack, Heading, Container, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure
} from "@chakra-ui/react";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { signUpApoderado } from "services/userService";
import { createCustomer, updateCustomer, findCustomerByUsername, getCustomersWithRole, updateCustomerRole, getCustomerByEmail } from "services/woo";

// npm install xlsx papaparse --save

const ImportUsers = () => {
  const [files, setFiles] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [importName, setImportName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [onlyNewUsers, setOnlyNewUsers] = useState(false);
  const [currentProcessing, setCurrentProcessing] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [previewLimit, setPreviewLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Añadir estos estados para gestión de roles
  const [rolePreview, setRolePreview] = useState(null);
  const [showRolePreview, setShowRolePreview] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [roleUpdateProgress, setRoleUpdateProgress] = useState({ total: 0, processed: 0 });

  // Chakra UI hooks
  const toast = useToast();
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  // Función para dividir un array en lotes
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Filtrar datos para vista previa
  const filteredData = parsedData.filter(user => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      (user.username && user.username.toLowerCase().includes(query)) ||
      (user.first_name && user.first_name.toLowerCase().includes(query)) ||
      (user.last_name && user.last_name.toLowerCase().includes(query)) ||
      (user._tipoEnsenanza && user._tipoEnsenanza.toLowerCase().includes(query))
    );
  });

  // Función para obtener vista previa de cambios de rol
  const previewRoleChanges = async () => {
    setIsLoadingRoles(true);
    try {
      // Obtener usuarios actuales con rol "customer"
      const usersToChange = [];
      let page = 1;
      let hasMore = true;
      
      // Obtener todos los clientes (múltiples páginas si es necesario)
      while (hasMore) {
        console.log(`Obteniendo usuarios customer - página ${page}`);
        const results = await getCustomersWithRole("customer", page, 100);
        usersToChange.push(...results);
        
        // Verificar si hay más páginas
        if (results.length < 100) {
          hasMore = false;
        } else {
          page++;
        }
      }
      
      console.log(`Total de usuarios con rol "customer" encontrados: ${usersToChange.length}`);
      
      // Normalizar usernames de los usuarios en nuestra lista de importación
      const usersInList = files
      .filter(u => u && u.username) // Filtrar elementos sin username
      .map(u => u.username.trim().toLowerCase());

      console.log(`Total de usuarios en la lista de importación: ${usersInList.length}`);
      
      // DEBUG: Mostrar los primeros usuarios de nuestra lista para verificar
      console.log("Primeros 5 usuarios en nuestra lista de importación:");
      usersInList.slice(0, 5).forEach((username, idx) => {
        console.log(`  ${idx+1}. ${username}`);
      });
      
      // Usuarios que serán degradados a "subscriber"
      const willBeDemoted = usersToChange.filter(
        user => !usersInList.includes(user.username.trim().toLowerCase())
      );
      
      console.log(`Usuarios que serán degradados a "subscriber": ${willBeDemoted.length}`);
      
      // DEBUG: Mostrar todos los usuarios que cambiarán de rol para revisar
      console.log("LISTADO COMPLETO DE USUARIOS QUE CAMBIARÁN DE 'CUSTOMER' A 'SUBSCRIBER':");
      willBeDemoted.forEach((user, idx) => {
        console.log(`${idx+1}. Username: ${user.username}, Nombre: ${user.first_name} ${user.last_name}, Email: ${user.email}, ID: ${user.id}`);
      });
      
      // Verificar también usuarios que recibirán promoción a "customer"
      const existingUsernames = usersToChange.map(u => u.username.trim().toLowerCase());
      const willBePromoted = files.filter(
        user => !existingUsernames.includes(user.username.trim().toLowerCase())
      );
      
      console.log(`Usuarios que serán promovidos a "customer": ${willBePromoted.length}`);
      
      // Establecer datos para la vista previa
      setRolePreview({
        totalExistingCustomers: usersToChange.length,
        willBeDemoted: willBeDemoted.length,
        willBePromotedOrMaintained: files.length,
        newCustomers: willBePromoted.length,
        demotedSample: willBeDemoted.slice(0, 100), // Mostrar hasta 100 ejemplos
        allDemoted: willBeDemoted, // Lista completa para referencia
        // Añadir listados completos para facilitar la revisión
        demotion_details: willBeDemoted.map(user => ({
          id: user.id,
          username: user.username,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email
        }))
      });
      
      // Guardar información en localStorage para facilitar la revisión
      try {
        localStorage.setItem('roleDemotionPreview', JSON.stringify(willBeDemoted.map(user => ({
          id: user.id,
          username: user.username,
          name: `${user.first_name} ${user.last_name}`, 
          email: user.email
        }))));
        console.log("Detalles de cambio de rol guardados en localStorage['roleDemotionPreview']");
      } catch (e) {
        console.log("No se pudo guardar en localStorage:", e);
      }
      
      setShowRolePreview(true);
    } catch (error) {
      console.error("Error al obtener vista previa de roles:", error);
      toast({
        title: "Error",
        description: "No se pudo obtener la vista previa de cambios de roles",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsLoadingRoles(false);
    }
  };

  // Función mejorada para actualizar roles realmente
  const updateExistingUserRoles = async () => {
    setIsLoadingRoles(true);
    setRoleUpdateProgress({ total: 0, processed: 0 });
    
    try {
      // Obtener todos los usuarios con rol "customer"
      const usersToCheck = [];
      let page = 1;
      let hasMore = true;
      
      console.log("Obteniendo lista completa de usuarios con rol 'customer'...");
      
      while (hasMore) {
        const results = await getCustomersWithRole("customer", page, 100);
        usersToCheck.push(...results);
        
        if (results.length < 100) {
          hasMore = false;
        } else {
          page++;
        }
      }
      
      console.log(`Total de usuarios con rol 'customer' encontrados: ${usersToCheck.length}`);
      
      // Usernames de usuarios en nuestra lista (los que deben mantenerse como "customer")
      const usersToKeep = files.map(u => u.username.trim().toLowerCase());
      
      console.log(`Usuarios en la lista de importación (mantendrán rol 'customer'): ${usersToKeep.length}`);
      
      // DEBUG: Mostrar algunos ejemplos de usuarios que mantendrán rol
      console.log("Ejemplos de usuarios que mantendrán rol 'customer':");
      usersToKeep.slice(0, 5).forEach((username, idx) => {
        console.log(`  ${idx+1}. ${username}`);
      });
      
      // Filtrar usuarios que deben cambiar a "subscriber"
      const usersToChange = usersToCheck.filter(
        user => !usersToKeep.includes(user.username.trim().toLowerCase())
      );
      
      console.log(`Usuarios que cambiarán a 'subscriber': ${usersToChange.length}`);
      
      // DEBUG: Mostrar algunos ejemplos de usuarios que cambiarán de rol
      console.log("Ejemplos de usuarios que cambiarán a 'subscriber':");
      usersToChange.slice(0, 5).forEach((user, idx) => {
        console.log(`  ${idx+1}. Username: ${user.username}, Nombre: ${user.first_name} ${user.last_name}`);
      });
      
      setRoleUpdateProgress({ total: usersToChange.length, processed: 0 });
      
      // Guardar la lista completa de IDs que deben cambiarse para usar durante la importación
      const idsToChange = usersToChange.map(user => ({
        id: user.id,
        username: user.username,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`
      }));
      
      // Guardar en localStorage para su uso posterior
      localStorage.setItem('usersToChangeRole', JSON.stringify(idsToChange));
      
      // Guardar también una copia separada para referencia
      localStorage.setItem('usersToChangeRole_backup', JSON.stringify(idsToChange));
      
      toast({
        title: "Roles preparados",
        description: `Se han identificado ${usersToChange.length} usuarios que cambiarán a "subscriber" durante la importación`,
        status: "success",
        duration: 3000,
        isClosable: true
      });
      
      // Actualizar el progreso para la UI
      setRoleUpdateProgress({
        total: usersToChange.length,
        processed: usersToChange.length
      });
      
      // Devolver la lista para uso interno si se necesita
      return idsToChange;
      
    } catch (error) {
      console.error("Error al preparar roles:", error);
      toast({
        title: "Error",
        description: "Ocurrió un error al preparar los cambios de roles",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      return [];
    } finally {
      setIsLoadingRoles(false);
    }
  };

  const handleFileUpload = (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      const validExtensions = ['xls', 'xlsx', 'csv'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      
      if (!validExtensions.includes(fileExtension)) {
        toast({
          title: "Error",
          description: "Por favor, selecciona un archivo Excel (.xls, .xlsx) o CSV válido",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        try {
          if (fileExtension === 'csv') {
            processCSV(event.target.result);
          } else {
            processExcel(event.target.result);
          }
        } catch (error) {
          toast({
            title: "Error al procesar el archivo",
            description: error.message || "Formato de archivo no válido",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      };

      if (fileExtension === 'csv') {
        fileReader.readAsText(file, "UTF-8");
      } else {
        fileReader.readAsArrayBuffer(file);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Error al procesar el archivo",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Función para procesar Excel con depuración exhaustiva
  const processExcel = (fileContent) => {
    // Leer el workbook
    const workbook = XLSX.read(fileContent, { type: 'array', cellDates: true });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    
    // Usar header: 1 para obtener matriz con encabezados en la primera fila
    const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
    
    if (rawData.length <= 1) { // Si solo hay encabezados o está vacío
      toast({
        title: "Error",
        description: "El archivo está vacío o solo contiene encabezados",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Extraer encabezados y mapearlos a índices
    const headerRow = rawData[0];
    console.log("ENCABEZADOS EXACTOS:", headerRow);
    
    // Crear un mapa de nombres de columna a índices
    const columnMap = {};
    headerRow.forEach((header, index) => {
      if (header) {
        columnMap[header.trim().toLowerCase()] = index;
      }
    });
    
    console.log("MAPA DE COLUMNAS:", columnMap);
    
    // Encontrar índices específicos para cada campo
    const findColumnIndex = (possibleNames) => {
      for (const name of possibleNames) {
        const lowerName = name.toLowerCase();
        if (columnMap[lowerName] !== undefined) {
          return columnMap[lowerName];
        }
      }
      return -1;  // No encontrado
    };
    
    // Buscar columnas críticas
    const runIndex = findColumnIndex(['run', 'rut', 'documento']);
    const nombreIndex = findColumnIndex(['nombre']);
    const apellidoPaternoIndex = findColumnIndex([
      'apellido paterno', 'apellidopaterno', 'paterno', 'primer apellido'
    ]);
    const apellidoMaternoIndex = findColumnIndex([
      'apellido materno', 'apellidomaterno', 'materno', 'segundo apellido'
    ]);
    const emailIndex = findColumnIndex(['email', 'correo']);
    const tipoEnsenanzaIndex = findColumnIndex([
      'tipo enseñanza', 'tipoenseñanza', 'tipo de enseñanza', 'educación'
    ]);
    const nivelIndex = findColumnIndex(['nivel', 'grado', 'nombre grado']);
    const cursoIndex = findColumnIndex(['curso', 'nombre curso']);
    
    console.log("ÍNDICES ENCONTRADOS:");
    console.log(`Run: ${runIndex}, Nombre: ${nombreIndex}`);
    console.log(`Apellido Paterno: ${apellidoPaternoIndex}, Apellido Materno: ${apellidoMaternoIndex}`);
    console.log(`Email: ${emailIndex}, Tipo Enseñanza: ${tipoEnsenanzaIndex}`);
    console.log(`Nivel: ${nivelIndex}, Curso: ${cursoIndex}`);
    
    // Validar columnas críticas
    if (runIndex === -1 || nombreIndex === -1) {
      toast({
        title: "Formato incorrecto",
        description: "El archivo no contiene las columnas mínimas requeridas (Run, Nombre)",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Saber si tenemos o no columnas de apellido
    const hasApellidoColumns = apellidoPaternoIndex !== -1 || apellidoMaternoIndex !== -1;
    console.log(`Tiene columnas de apellido: ${hasApellidoColumns}`);

    // Procesar datos (excluyendo fila de encabezados)
    const mappedData = [];
    
    // Procesar cada fila de datos
    for (let rowIndex = 1; rowIndex < rawData.length; rowIndex++) {
      const row = rawData[rowIndex];
      
      // Verificar si la fila tiene datos relevantes
      if (!row || row.length === 0 || !row[runIndex]) continue;
      
      // Formatear RUN: quitar puntos pero mantener guión
      let run = row[runIndex] ? String(row[runIndex]).replace(/\./g, '').trim() : '';
      
      // Asegurar formato con guión
      if (run && !run.includes('-')) {
        const dgv = run.slice(-1);
        const numero = run.slice(0, -1);
        run = `${numero}-${dgv}`;
      }
      
      // Extraer nombre
      const nombre = row[nombreIndex] ? String(row[nombreIndex]).trim() : '';
      
      // Extraer apellidos según disponibilidad de columnas
      let apellidoPaterno = '';
      let apellidoMaterno = '';
      let apellidoCompleto = '';
      
      if (apellidoPaternoIndex !== -1) {
        apellidoPaterno = row[apellidoPaternoIndex] ? String(row[apellidoPaternoIndex]).trim() : '';
      }
      
      if (apellidoMaternoIndex !== -1) {
        apellidoMaterno = row[apellidoMaternoIndex] ? String(row[apellidoMaternoIndex]).trim() : '';
      }
      
      // Construir apellido completo
      apellidoCompleto = apellidoPaterno.trim();
      if (apellidoMaterno.trim()) {
        apellidoCompleto += apellidoCompleto ? ` ${apellidoMaterno.trim()}` : apellidoMaterno.trim();
      }
      
      // Si no hay apellidos, intentar extraer del nombre completo
      if (!apellidoCompleto && nombre.split(' ').length > 2) {
        const nombreParts = nombre.split(' ');
        if (nombreParts.length >= 3) {
          // Asumir que los últimos dos son apellidos
          apellidoMaterno = nombreParts.pop();
          apellidoPaterno = nombreParts.pop();
          // Reconstruir el nombre
          const nombreReconstruido = nombreParts.join(' ');
          apellidoCompleto = `${apellidoPaterno} ${apellidoMaterno}`;
          
          console.log(`[${rowIndex}] EXTRACCIÓN DEL NOMBRE: "${nombre}" -> Nombre="${nombreReconstruido}", Apellidos="${apellidoCompleto}"`);
        }
      }
      
      // Otros campos
      const email = emailIndex !== -1 && row[emailIndex] 
        ? String(row[emailIndex]).trim() 
        : `${run}@example.com`;
      
      const tipoEnsenanza = tipoEnsenanzaIndex !== -1 && row[tipoEnsenanzaIndex] 
        ? String(row[tipoEnsenanzaIndex]).trim() 
        : '';
      
      const nombreGrado = nivelIndex !== -1 && row[nivelIndex] 
        ? String(row[nivelIndex]).trim() 
        : '';
      
      const nombreCurso = cursoIndex !== -1 && row[cursoIndex] 
        ? String(row[cursoIndex]).trim() 
        : '';
      
      // Determinar subscription_name
      let subscriptionName = '';
      if (tipoEnsenanza.toLowerCase().includes('parvularia')) {
        subscriptionName = 'Mensualidad PreBásica';
      } else {
        subscriptionName = 'Mensualidad BásicaMedia';
      }
      
      // Construir group_name
      let groupName = '';
      if (nombreGrado && tipoEnsenanza) {
        groupName = `${tipoEnsenanza.trim()} - ${nombreGrado.trim()}`;
        if (nombreCurso.trim()) {
          groupName += ` - ${nombreCurso.trim()}`;
        }
      }
      
      // Log para debugging
      const userInfo = {
        username: run,
        first_name: nombre,
        last_name: apellidoCompleto,
        email: email,
        meta_data: [
          { key: 'suscription_name', value: subscriptionName },
          { key: 'group_name', value: groupName }
        ],
        _tipoEnsenanza: tipoEnsenanza,
        _nombreGrado: nombreGrado,
        _nombreCurso: nombreCurso,
        _apellidoPaterno: apellidoPaterno,
        _apellidoMaterno: apellidoMaterno,
        _rowIndex: rowIndex
      };
      
      // Debugging para filas específicas
      if (rowIndex < 6 || run === "27498048-6") {
        console.log(`Procesando fila ${rowIndex}:`);
        console.log(`  - Run: "${run}"`);
        console.log(`  - Nombre: "${nombre}"`);
        console.log(`  - ApellidoPaterno: "${apellidoPaterno}", index: ${apellidoPaternoIndex}, valor en fila: "${row[apellidoPaternoIndex]}"`);
        console.log(`  - ApellidoMaterno: "${apellidoMaterno}", index: ${apellidoMaternoIndex}, valor en fila: "${row[apellidoMaternoIndex]}"`);
        console.log(`  - ApellidoCompleto: "${apellidoCompleto}"`);
        console.log(`  - Fila completa: ${JSON.stringify(row)}`);
      }
      
      // Solo agregar usuarios con username y nombre
      if (run && nombre) {
        mappedData.push(userInfo);
      }
    }
    
    // Mostrar algunos resultados para verificación
    console.log(`Total de registros procesados: ${mappedData.length}`);
    console.log("Ejemplos de registros procesados:", mappedData.slice(0, 3));
    
    // Buscar específicamente el usuario de ejemplo
    const ejemploUsuario = mappedData.find(user => user.username === "27498048-6");
    if (ejemploUsuario) {
      console.log("USUARIO EJEMPLO ENCONTRADO:");
      console.log(JSON.stringify(ejemploUsuario, null, 2));
    } else {
      console.log("¡USUARIO EJEMPLO NO ENCONTRADO!");
    }
    
    if (mappedData.length === 0) {
      toast({
        title: "Error",
        description: "No se encontraron datos válidos para importar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    setParsedData(mappedData);
    setFiles(mappedData);
    setActiveStep(1); // Avanzar al paso de vista previa
    
    toast({
      title: "Archivo procesado",
      description: `Se han encontrado ${mappedData.length} registros válidos para importar`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
    

  const processCSV = (fileContent) => {
    Papa.parse(fileContent, {
      header: true,
      skipEmptyLines: true,
      encoding: "UTF-8",
      complete: (result) => {
        if (result.errors.length > 0) {
          toast({
            title: "Error al procesar el archivo",
            description: "El archivo CSV contiene errores",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        
        // Mapear datos CSV
        const mappedData = result.data.map(row => {
          let run = (row.run || row.Run || row.RUN || '').replace(/\./g, '').trim();
          if (run && !run.includes('-')) {
            const dgv = run.slice(-1);
            const numero = run.slice(0, -1);
            run = `${numero}-${dgv}`;
          }
  
          // CORRECCIÓN: Procesar nombre y apellidos correctamente
          let nombre = (row.nombre || row.Nombre || '').trim();
          let apellidoPaterno = '';
          let apellidoMaterno = '';
          let apellidoCompleto = '';
          
          // Caso 1: Si hay columnas separadas para apellidos
          if ((row.apellido_paterno || row['apellido paterno']) && 
              (row.apellido_materno || row['apellido materno'])) {
            
            apellidoPaterno = (row.apellido_paterno || row['apellido paterno'] || '').trim();
            apellidoMaterno = (row.apellido_materno || row['apellido materno'] || '').trim();
            apellidoCompleto = apellidoPaterno + (apellidoMaterno ? ` ${apellidoMaterno}` : '');
          }
          // Caso 2: Si hay una columna general de apellidos
          else if (row.apellido || row.apellidos || row.Apellido || row.Apellidos) {
            apellidoCompleto = (row.apellido || row.apellidos || row.Apellido || row.Apellidos || '').trim();
            
            // Intentar dividir en paterno y materno si hay espacio
            const apellidosParts = apellidoCompleto.split(' ');
            if (apellidosParts.length >= 2) {
              apellidoPaterno = apellidosParts[0];
              apellidoMaterno = apellidosParts.slice(1).join(' ');
            } else {
              apellidoPaterno = apellidoCompleto;
              apellidoMaterno = '';
            }
          }
          // Caso 3: Si el nombre incluye los apellidos (formato completo)
          else if (nombre.split(' ').length > 2) {
            // Asumimos que la estructura es: Nombre1 [Nombre2] Apellido1 Apellido2
            const nombreCompleto = nombre.split(' ');
            
            // Si hay al menos 3 palabras, consideramos la última como apellido materno
            // y la penúltima como apellido paterno
            if (nombreCompleto.length >= 3) {
              apellidoMaterno = nombreCompleto.pop(); // Último elemento
              apellidoPaterno = nombreCompleto.pop(); // Penúltimo elemento
              nombre = nombreCompleto.join(' '); // Resto es el nombre
              apellidoCompleto = `${apellidoPaterno} ${apellidoMaterno}`;
            }
            // Si solo hay 2 palabras, consideramos la segunda como apellido paterno
            else if (nombreCompleto.length === 2) {
              apellidoPaterno = nombreCompleto.pop();
              nombre = nombreCompleto.join(' ');
              apellidoCompleto = apellidoPaterno;
            }
          }
          
          // Si después de todo no tenemos apellido, dejamos un valor por defecto
          if (!apellidoCompleto) {
            apellidoCompleto = "Sin Apellido";
          }
          
          const tipoEnsenanza = (row.tipo_ensenanza || row.tipoEnsenanza || row['tipo enseñanza'] || '').trim();
          const email = (row.email || row.Email || row.correo || row.Correo || `${run}@example.com`).trim();
          
          // Datos para group_name
          const nombreGrado = (row.nivel || row.nombre_grado || row['nombre grado'] || '').trim();
          const nombreCurso = (row.curso || row.nombre_curso || row['nombre curso'] || '').trim();
          
          let subscriptionName = '';
          if (tipoEnsenanza.toLowerCase().includes('parvularia')) {
            subscriptionName = 'Mensualidad PreBásica';
          } else {
            subscriptionName = 'Mensualidad BásicaMedia';
          }
          
          // Construir group_name manteniendo el ID original
          let groupName = '';
          if (nombreGrado && tipoEnsenanza) {
            // Extraer el prefijo completo si existe (como "110:")
            const prefixMatch = tipoEnsenanza.match(/^(\s*\d+\s*:)/);
            const prefix = prefixMatch ? prefixMatch[1] : ""; 
            
            // Limpiar solo la parte del texto, no el prefijo
            const tipoEnsenanzaClean = prefix 
              ? tipoEnsenanza.replace(prefix, "").trim() 
              : tipoEnsenanza.trim();
            
            // Reconstruir con el prefijo original
            groupName = prefix 
              ? `${prefix.trim()} ${tipoEnsenanzaClean} - ${nombreGrado}${nombreCurso ? ` - ${nombreCurso}` : ''}`
              : `${tipoEnsenanzaClean} - ${nombreGrado}${nombreCurso ? ` - ${nombreCurso}` : ''}`;
          }
  
          // Información de debugging para diagnóstico
          console.log(`Procesando usuario ${run}: Nombre="${nombre}", Apellidos="${apellidoCompleto}" (${apellidoPaterno} ${apellidoMaterno})`);
  
          return {
            username: run,
            first_name: nombre,
            last_name: apellidoCompleto,
            email: email,
            meta_data: [
              { key: 'suscription_name', value: subscriptionName },
              { key: 'group_name', value: groupName }
            ],
            _tipoEnsenanza: tipoEnsenanza,
            _nombreGrado: nombreGrado,
            _nombreCurso: nombreCurso,
            _apellidoPaterno: apellidoPaterno,
            _apellidoMaterno: apellidoMaterno
          };
        });
  
        // Filtrar registros inválidos
        const validData = mappedData.filter(item => item.username && item.first_name);
        
        if (validData.length === 0) {
          toast({
            title: "Error",
            description: "No se encontraron datos válidos para importar",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        
        setParsedData(validData);
        setFiles(validData);
        setActiveStep(1); // Avanzar al paso de vista previa
      },
      error: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  const updateUserStatus = (username, accountType, status, message) => {
    setCurrentProcessing(prev => {
      const userIndex = prev.findIndex(u => u.username === username);
      if (userIndex === -1) return prev;

      const updatedProcessing = [...prev];
      const user = {...updatedProcessing[userIndex]};

      // Actualizar el estado específico de la cuenta
      user.accounts[accountType] = { status, message };

      // Actualizar el estado general del usuario
      if (status === 'error') {
        user.status = 'error';
        user.message = message;
      } else if (user.accounts.apoderado.status === 'success' && 
                 user.accounts.customer.status === 'success') {
        user.status = 'success';
        user.message = 'Proceso completado exitosamente';
      } else if (user.accounts.apoderado.status === 'processing' || 
                 user.accounts.customer.status === 'processing') {
        user.status = 'processing';
        user.message = 'Procesando...';
      }

      updatedProcessing[userIndex] = user;
      return updatedProcessing;
    });
  };

  const getProgressPercent = () => {
    if (!currentProcessing.length) return 0;
    const completed = currentProcessing.filter(
      user => user.status === 'success' || user.status === 'error'
    ).length;
    return (completed / currentProcessing.length) * 100;
  };

  const getStatusBadge = (status) => {
    const statusConfig = {
      success: { color: "green", text: "Éxito" },
      error: { color: "red", text: "Error" },
      processing: { color: "yellow", text: "Procesando" },
      pending: { color: "gray", text: "Pendiente" }
    };

    const config = statusConfig[status] || statusConfig.pending;
    return <Badge colorScheme={config.color}>{config.text}</Badge>;
  };

  // Modificar la función startProcessing para mostrar la vista previa
  const startProcessing = () => {
    if (!importName.trim()) {
      toast({
        title: "Error",
        description: "Por favor, ingresa un nombre para la importación",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Mostrar vista previa de cambios de rol
    previewRoleChanges();
  };

  // Versión optimizada para procesar muchos usuarios en menos tiempo
  const importUsers = async () => {
    try {
      if (!files.length) {
        toast({
          title: "Error", description: "No hay datos para procesar",
          status: "error", duration: 3000, isClosable: true,
        });
        return;
      }

      setIsLoading(true);
      let procesados = 0;
      let exitosos = 0;
      let errores = 0;

      // Verificar si hay roles pendientes de cambio
      let usersToChangeRole = [];
      try {
        const storedUsers = localStorage.getItem('usersToChangeRole');
        if (storedUsers) {
          usersToChangeRole = JSON.parse(storedUsers);
          console.log(`Se procesarán ${usersToChangeRole.length} cambios de rol durante la importación`);
        }
      } catch (e) {
        console.error("Error al cargar usuarios para cambio de rol:", e);
      }

      // Configuración para procesamiento en lotes
      const BATCH_SIZE = 10; // Procesar 10 usuarios en paralelo
      const TIMEOUT_MS = 30000; // 30 segundos de timeout por operación
      const DELAY_BETWEEN_BATCHES = 1000; // 1 segundo entre lotes

      // Preparar los usuarios para procesamiento
      const userBatches = [];
      for (let i = 0; i < files.length; i += BATCH_SIZE) {
        userBatches.push(files.slice(i, i + BATCH_SIZE));
      }

      console.log(`Preparados ${userBatches.length} lotes de hasta ${BATCH_SIZE} usuarios cada uno`);

      // Función para procesar un único usuario
      const processUser = async (user) => {
        try {
          // Si el email sigue siendo el email por defecto de ejemplo.com, usar blaspascal.cl
          let userEmail = user.email && user.email.trim() ? user.email.trim().toLowerCase() : '';
          
          // Verificar si es un email por defecto o está vacío
          if (!userEmail || userEmail.includes('@example.com') || userEmail === '') {
            // Mantener el guion pero quitar los puntos
            const formattedRun = user.username.replace(/\./g, '').trim();
            userEmail = `${formattedRun}@blaspascal.cl`;
          }
          
          // Preparar datos - SIEMPRE ASIGNAMOS ROL "CUSTOMER"
          const registrationData = {
            username: user.username.trim(),
            email: userEmail,
            password: user.username.replace('-', '').slice(0, -1).trim(),
            first_name: user.first_name.trim(),
            last_name: user.last_name.trim(),
            role: 'customer' // Aseguramos rol "customer" para todos los usuarios de la lista
          };
      
          const customerData = {
            ...registrationData,
            meta_data: Array.isArray(user.meta_data) ? user.meta_data : [],
            role: 'customer' // Redundante pero garantiza que siempre se asigna el rol correcto
          };
          
          // Asegurar meta_data necesarios
          if (!customerData.meta_data.find(m => m.key === 'suscription_name')) {
            customerData.meta_data.push({
              key: 'suscription_name',
              value: user._tipoEnsenanza && user._tipoEnsenanza.toLowerCase().includes('parvularia') 
                ? 'Mensualidad PreBásica' : 'Mensualidad BásicaMedia'
            });
          }
      
          updateUserStatus(user.username, 'apoderado', 'processing', 'Procesando apoderado...');
          updateUserStatus(user.username, 'customer', 'processing', 'Procesando cliente...');
      
          // PASO 1: Buscar cliente existente
          let existingCustomer = null;
          try {
            const userSearchPromise = Promise.race([
              findCustomerByUsername(user.username),
              new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout búsqueda")), TIMEOUT_MS))
            ]);
            
            existingCustomer = await userSearchPromise;
          } catch (findError) {
            console.log(`⚠️ Error búsqueda para ${user.username}: ${findError.message}`);
          }
      
          // PASO 2: Procesar apoderado (solo si no existe)
          let apoderadoSuccess = false;
          if (!existingCustomer) {
            try {
              const apoderadoPromise = Promise.race([
                signUpApoderado(registrationData),
                new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout apoderado")), TIMEOUT_MS))
              ]);
              
              const registrationResult = await apoderadoPromise;
              
              if (registrationResult && registrationResult.message) {
                updateUserStatus(user.username, 'apoderado', 'success', registrationResult.message);
                apoderadoSuccess = true;
              } else {
                updateUserStatus(user.username, 'apoderado', 'error', 
                  registrationResult?.message || 'Error en registro de apoderado');
              }
            } catch (error) {
              updateUserStatus(user.username, 'apoderado', 'error', error.message || 'Error en registro');
            }
          } else {
            // Si ya existe, marcar apoderado como éxito
            updateUserStatus(user.username, 'apoderado', 'success', 'Usuario ya existente');
            apoderadoSuccess = true;
          }
      
          // PASO 3: Procesar cliente (SIEMPRE actualizar si existe)
          let customerSuccess = false;
          try {
            let customerResult;
            
            if (existingCustomer && existingCustomer.id) {
              // CORRECCIÓN: Siempre actualizar cliente existente
              const { username, ...updateData } = customerData;
              
              if (existingCustomer.email && existingCustomer.email.toLowerCase() === updateData.email.toLowerCase()) {
                delete updateData.email;
              }
              
              // ASEGURAR QUE LA ACTUALIZACIÓN INCLUYE ROL "CUSTOMER"
              updateData.role = 'customer';
              
              const updatePromise = Promise.race([
                updateCustomer(existingCustomer.id, updateData),
                new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout actualización")), TIMEOUT_MS))
              ]);
              
              customerResult = await updatePromise;
              
              // Si hay error y es de email, reintentar con email único
              if (customerResult && !customerResult.success && updateData.email && 
                  customerResult.message && (
                    customerResult.message.includes('email') || 
                    customerResult.message.includes('correo')
                  )) {
                
                const formattedRun = user.username.replace(/\./g, '').trim();
                const modifiedEmail = `${formattedRun}@blaspascal.cl`;
                
                const retryPromise = Promise.race([
                  updateCustomer(existingCustomer.id, { ...updateData, email: modifiedEmail }),
                  new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout reintento")), TIMEOUT_MS))
                ]);
                
                customerResult = await retryPromise;
              }
            } else {
              // Crear nuevo cliente con rol "customer"
              const createPromise = Promise.race([
                createCustomer(customerData),
                new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout creación")), TIMEOUT_MS))
              ]);
              
              customerResult = await createPromise;
              
              // Si hay error y es de email o username, reintentar con email único
              if (customerResult && !customerResult.success && customerResult.message && (
                  customerResult.message.includes('email') || 
                  customerResult.message.includes('correo') ||
                  customerResult.message.includes('username') ||
                  customerResult.message.includes('ya existe')
                )) {
                
                const formattedRun = user.username.replace(/\./g, '').trim();
                const modifiedEmail = `${formattedRun}@blaspascal.cl`;
                
                const retryPromise = Promise.race([
                  createCustomer({ ...customerData, email: modifiedEmail }),
                  new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout reintento")), TIMEOUT_MS))
                ]);
                
                customerResult = await retryPromise;
              }
            }
            
            // Actualizar estado del cliente
            if (customerResult && (customerResult.success || customerResult.status === 200 || customerResult.status === 201)) {
              updateUserStatus(
                user.username, 
                'customer', 
                'success', 
                existingCustomer ? 'Cliente actualizado con rol "customer"' : 'Cliente creado con rol "customer"'
              );
              customerSuccess = true;
            } else {
              updateUserStatus(
                user.username, 
                'customer', 
                'error',
                customerResult?.message || 'Error en cliente'
              );
            }
          } catch (customerError) {
            updateUserStatus(
              user.username, 
              'customer', 
              'error',
              customerError.message || 'Error en cliente'
            );
          }
          
          return { 
            username: user.username, 
            success: apoderadoSuccess && customerSuccess 
          };
        } catch (userError) {
          console.error(`Error general en usuario ${user.username}: ${userError.message}`);
          return { username: user.username, success: false };
        }
      };

      // Procesar lotes de usuarios en paralelo
      for (let batchIndex = 0; batchIndex < userBatches.length; batchIndex++) {
        const currentBatch = userBatches[batchIndex];
        console.log(`Procesando lote ${batchIndex + 1}/${userBatches.length} (${currentBatch.length} usuarios)`);
        
        try {
          // Procesar este lote en paralelo
          const batchPromises = currentBatch.map(user => processUser(user));
          const batchResults = await Promise.allSettled(batchPromises);
          
          // Contar resultados
          const batchSuccess = batchResults.filter(
            r => r.status === 'fulfilled' && r.value && r.value.success
          ).length;
          
          const batchErrors = batchResults.filter(
            r => r.status === 'rejected' || (r.value && !r.value.success)
          ).length;
          
          // Actualizar contadores
          procesados += currentBatch.length;
          exitosos += batchSuccess;
          errores += batchErrors;
          
          console.log(`Lote ${batchIndex + 1} completado: ${batchSuccess} exitosos, ${batchErrors} errores`);
          console.log(`Total: ${procesados}/${files.length} (${exitosos} exitosos, ${errores} errores)`);
          
          // Pequeña pausa entre lotes para no sobrecargar el servidor
          if (batchIndex < userBatches.length - 1) {
            await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_BATCHES));
          }
        } catch (batchError) {
          console.error(`Error procesando lote ${batchIndex + 1}: ${batchError.message}`);
          // Continuar con el siguiente lote aunque haya error
        }
      }

      // Procesar los cambios de rol pendientes después de la importación
      if (usersToChangeRole.length > 0) {
        console.log("Procesando cambios de rol pendientes...");
        
        // Procesar en lotes pequeños
        const batches = chunkArray(usersToChangeRole, 10);
        
        let rolesUpdated = 0;
        let roleErrors = 0;
        
        for (let i = 0; i < batches.length; i++) {
          const batch = batches[i];
          
          const results = await Promise.allSettled(
            batch.map(user => updateCustomerRole(user.id, "subscriber"))
          );
          
          // Contar resultados
          results.forEach(result => {
            if (result.status === 'fulfilled' && result.value && result.value.success) {
              rolesUpdated++;
            } else {
              roleErrors++;
            }
          });
          
          // Pequeña pausa entre lotes
          if (i < batches.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 500));
          }
        }
        
        console.log(`Cambios de rol procesados: ${rolesUpdated} exitosos, ${roleErrors} errores`);
        
        // Limpiar localStorage
        localStorage.removeItem('usersToChangeRole');
        
        toast({
          title: "Roles actualizados",
          description: `Se cambiaron ${rolesUpdated} usuarios a rol "subscriber"`,
          status: "success",
          duration: 3000,
          isClosable: true
        });
      }

      // Finalizar
      toast({
        title: "Proceso completado",
        description: `Importación finalizada. Procesados: ${procesados}, Exitosos: ${exitosos}, Errores: ${errores}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log("❌ ERROR GENERAL:", error);
      toast({
        title: "Error",
        description: error.message || "Error en el proceso de importación",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  // Modal de detalles de usuario
  const UserDetailModal = ({ user, isOpen, onClose }) => {
    if (!user) return null;
    
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalles del usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Box>
                <Heading size="sm" mb={2}>Información básica</Heading>
                <Table variant="simple" size="sm">
                  <Tbody>
                    <Tr><Td fontWeight="bold">RUN</Td><Td>{user.username}</Td></Tr>
                    <Tr><Td fontWeight="bold">Nombre</Td><Td>{user.first_name}</Td></Tr>
                    <Tr><Td fontWeight="bold">Apellidos</Td><Td>{user.last_name}</Td></Tr>
                    <Tr><Td fontWeight="bold">Email</Td><Td>{user.email}</Td></Tr>
                    <Tr>
                      <Td fontWeight="bold">Contraseña</Td>
                      <Td>{user.username.replace('-', '').slice(0, -1)}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              
              <Box>
                <Heading size="sm" mb={2}>Información académica</Heading>
                <Table variant="simple" size="sm">
                  <Tbody>
                    <Tr><Td fontWeight="bold">Tipo Enseñanza</Td><Td>{user._tipoEnsenanza || '-'}</Td></Tr>
                    <Tr><Td fontWeight="bold">Nombre Grado</Td><Td>{user._nombreGrado || '-'}</Td></Tr>
                    <Tr><Td fontWeight="bold">Nombre Curso</Td><Td>{user._nombreCurso || '-'}</Td></Tr>
                  </Tbody>
                </Table>
              </Box>
              
              <Box>
                <Heading size="sm" mb={2}>Metadatos</Heading>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Clave</Th>
                      <Th>Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {user.meta_data && user.meta_data.map((meta, index) => (
                      <Tr key={index}>
                        <Td>{meta.key}</Td>
                        <Td>{meta.value}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  // Modal para vista previa de cambios de rol
  const RolePreviewModal = ({ isOpen, onClose, preview, onContinue, isLoading, progress }) => {
    if (!preview) return null;
    const [showFullList, setShowFullList] = useState(false);
    
    return (
      <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Vista previa de cambios de rol</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Text>
                Esta acción mantendrá o asignará el rol <Badge colorScheme="blue">customer</Badge> solo a los {preview.willBePromotedOrMaintained} usuarios en tu lista de importación.
              </Text>
              
              <Box p={4} bg="orange.50" borderRadius="md">
                <Text fontWeight="bold" color="orange.700">
                  {preview.willBeDemoted} usuarios actuales cambiarán de <Badge colorScheme="blue">customer</Badge> a <Badge colorScheme="purple">subscriber</Badge>
                </Text>
              </Box>
              
              {preview.demotedSample && preview.demotedSample.length > 0 && (
                <Box>
                  <Text fontWeight="bold" mb={2}>Ejemplos de usuarios que cambiarán a subscriber:</Text>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Username</Th>
                        <Th>Nombre</Th>
                        <Th>Email</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {preview.demotedSample.map((user, idx) => (
                        <Tr key={idx}>
                          <Td>{user.username}</Td>
                          <Td>{user.first_name} {user.last_name}</Td>
                          <Td>{user.email}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  {preview.willBeDemoted > 5 && (
                    <Text fontSize="sm" color="gray.500" mt={1}>
                      Y {preview.willBeDemoted - 5} usuarios más...
                    </Text>
                  )}
                </Box>
              )}
              
              <Box p={4} bg="green.50" borderRadius="md">
                <Text fontWeight="bold" color="green.700">
                  {preview.newCustomers} nuevos usuarios recibirán el rol <Badge colorScheme="blue">customer</Badge>
                </Text>
                <Text fontWeight="bold" color="blue.700" mt={2}>
                  {preview.willBePromotedOrMaintained - preview.newCustomers} usuarios mantendrán su rol de <Badge colorScheme="blue">customer</Badge> (ya existen)
                </Text>
              </Box>
              
              {isLoading && progress && (
                <Box>
                  <Text mb={2}>Actualizando roles de usuarios existentes...</Text>
                  <Progress
                    value={(progress.processed / progress.total) * 100}
                    size="sm"
                    colorScheme="blue"
                    hasStripe
                    isAnimated
                  />
                  <Text fontSize="sm" mt={1}>
                    {progress.processed} de {progress.total} usuarios procesados
                  </Text>
                </Box>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button 
              onClick={() => setShowFullList(true)}
              variant="outline"
              mt={2}
            >
              Ver todos ({preview.willBeDemoted})
            </Button>
            <Button onClick={onClose} mr={3} isDisabled={isLoading}>
              Cancelar
            </Button>
            <Button 
              colorScheme="blue" 
              onClick={onContinue}
              isLoading={isLoading}
              loadingText="Actualizando roles"
            >
              Actualizar roles y continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        {preview.demotion_details && (
          <RoleChangeFullListModal 
            isOpen={showFullList}
            onClose={() => setShowFullList(false)}
            userList={preview.demotion_details}
          />
        )}
      </>
    );
  };

  // Componente para visualizar el listado completo de usuarios que cambiarán de rol
  const RoleChangeFullListModal = ({ isOpen, onClose, userList }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(userList || []);
    
    // Actualizar usuarios filtrados cuando cambia la búsqueda
    useEffect(() => {
      if (!userList) return;
      
      if (!searchTerm) {
        setFilteredUsers(userList);
        return;
      }
      
      const lowerTerm = searchTerm.toLowerCase();
      const filtered = userList.filter(user => 
        user.username.toLowerCase().includes(lowerTerm) ||
        user.name.toLowerCase().includes(lowerTerm) ||
        user.email.toLowerCase().includes(lowerTerm)
      );
      
      setFilteredUsers(filtered);
    }, [searchTerm, userList]);
    
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxH="80vh">
          <ModalHeader>Listado completo de cambios de rol</ModalHeader>
          <ModalCloseButton />
          
          <ModalBody>
            <Stack spacing={4}>
              <Text>
                Este listado muestra todos los usuarios ({userList?.length || 0}) que cambiarán 
                de <Badge colorScheme="blue">customer</Badge> a <Badge colorScheme="purple">subscriber</Badge>
              </Text>
              
              <FormControl>
                <FormLabel>Buscar usuario</FormLabel>
                <Input
                  placeholder="Buscar por nombre, username o email"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </FormControl>
              
              {searchTerm && (
                <Text fontSize="sm">
                  Mostrando {filteredUsers.length} de {userList?.length || 0} usuarios
                </Text>
              )}
              
              <Box 
                borderWidth="1px" 
                borderRadius="md" 
                p={0} 
                maxH="400px" 
                overflowY="auto"
              >
                <Table size="sm" variant="simple">
                  <Thead position="sticky" top={0} bg="white" zIndex={1}>
                    <Tr>
                      <Th>#</Th>
                      <Th>Username</Th>
                      <Th>Nombre</Th>
                      <Th>Email</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredUsers.map((user, idx) => (
                      <Tr key={user.id}>
                        <Td>{idx + 1}</Td>
                        <Td fontWeight="medium">{user.username}</Td>
                        <Td>{user.name}</Td>
                        <Td fontSize="sm">{user.email}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              
              {filteredUsers.length === 0 && (
                <Box p={4} textAlign="center">
                  <Text color="gray.500">No se encontraron usuarios que coincidan con la búsqueda</Text>
                </Box>
              )}
            </Stack>
          </ModalBody>
          
          <ModalFooter>
            <Button onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Box bg={bgColor} p={8} borderRadius="lg" boxShadow="lg" border="1px" borderColor={borderColor}>
        <Stack spacing={6}>
          <Heading size="lg">Importar Usuarios</Heading>
          
          {/* Pasos de la importación */}
          <Flex gap={2} alignItems="center" wrap="wrap">
            <Badge colorScheme={activeStep >= 0 ? "green" : "gray"} p={2} borderRadius="md">
              1. Subir archivo
            </Badge>
            <Text>&gt;</Text>
            <Badge colorScheme={activeStep >= 1 ? "green" : "gray"} p={2} borderRadius="md">
              2. Vista previa
            </Badge>
            <Text>&gt;</Text>
            <Badge colorScheme={activeStep >= 2 ? "green" : "gray"} p={2} borderRadius="md">
              3. Procesamiento
            </Badge>
          </Flex>
          
          {/* Paso 1: Subir archivo */}
          {activeStep === 0 && (
            <Stack spacing={6}>
              <FormControl>
                <FormLabel>Nombre de la importación</FormLabel>
                <Input
                  type="text"
                  placeholder="Nueva migración"
                  value={importName}
                  onChange={(e) => setImportName(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Archivo</FormLabel>
                <Input
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileUpload}
                />
                <Text fontSize="sm" color="gray.500" mt={1}>
                  Formatos aceptados: Excel (.xls, .xlsx) y CSV (.csv)
                </Text>
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <Switch
                  id="only-new-users"
                  isChecked={onlyNewUsers}
                  onChange={(e) => setOnlyNewUsers(e.target.checked)}
                />
                <FormLabel htmlFor="only-new-users" mb="0" ml={2}>
                  Solo nuevos usuarios
                </FormLabel>
              </FormControl>
            </Stack>
          )}
          
          {/* Paso 2: Vista previa de datos */}
          {activeStep === 1 && (
            <Stack spacing={4}>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md">Vista previa de datos</Heading>
                <Flex gap={2}>
                  <Button size="sm" onClick={() => setActiveStep(0)} variant="outline">
                    Volver
                  </Button>
                  <Button 
                    colorScheme="blue" 
                    size="sm" 
                    onClick={startProcessing}
                    isDisabled={!importName.trim() || !parsedData.length || isLoadingRoles}
                  >
                    Continuar con {parsedData.length} registros
                  </Button>
                </Flex>
              </Flex>
              
              <Text>Se han encontrado {parsedData.length} registros para importar</Text>
              
              {/* Información sobre roles */}
              <Box p={4} bg="blue.50" borderRadius="md">
                <Heading size="sm" mb={2}>Información importante sobre roles</Heading>
                <Text>
                  Los usuarios en esta lista serán los únicos con rol <Badge colorScheme="blue">customer</Badge>.
                  Todos los demás (excepto admins) pasarán a tener rol <Badge colorScheme="purple">subscriber</Badge>.
                </Text>
                <Text mt={2}>
                  Al continuar, verás una vista previa de estos cambios.
                </Text>
              </Box>
              
              {/* Filtros y controles */}
              <Flex gap={4} flexWrap="wrap">
                <FormControl maxW="250px">
                  <FormLabel fontSize="sm">Buscar usuario</FormLabel>
                  <Input
                    placeholder="Buscar por RUN o nombre"
                    size="sm"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </FormControl>
                
                <FormControl display="flex" alignItems="flex-end">
                  <Switch 
                    id="show-all-data"
                    onChange={(e) => {
                      setPreviewLimit(e.target.checked ? parsedData.length : 10);
                    }}
                  />
                  <FormLabel htmlFor="show-all-data" mb="0" ml={2} fontSize="sm">
                    Mostrar todos los registros
                  </FormLabel>
                </FormControl>
              </Flex>
              
              <Box 
                overflowX="auto" 
                maxH="400px" 
                overflowY="auto"
                borderWidth="1px"
                borderRadius="md"
                borderColor={borderColor}
              >
                <Table variant="simple" size="sm">
                  <Thead position="sticky" top={0} bg={bgColor} zIndex={1}>
                    <Tr>
                      <Th>RUN</Th>
                      <Th>Nombre</Th>
                      <Th>Apellidos</Th>
                      <Th>Email</Th>
                      <Th>Tipo Enseñanza</Th>
                      <Th>Grado</Th>
                      <Th>Curso</Th>
                      <Th>Acciones</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredData.slice(0, previewLimit).map((user, index) => (
                      <Tr key={index} _hover={{ bg: "gray.50" }}>
                        <Td fontWeight="medium">{user.username}</Td>
                        <Td>{user.first_name}</Td>
                        <Td>{user.last_name}</Td>
                        <Td fontSize="sm">{user.email}</Td>
                        <Td fontSize="sm" color="purple.600">{user._tipoEnsenanza || '-'}</Td>
                        <Td fontSize="sm" fontWeight="bold">{user._nombreGrado || '-'}</Td>
                        <Td fontSize="sm">{user._nombreCurso || '-'}</Td>
                        <Td>
                          <Flex gap={2}>
                            <Button 
                              size="xs" 
                              colorScheme="blue" 
                              variant="ghost"
                              onClick={() => {
                                setSelectedUser(user);
                                onOpen();
                              }}
                            >
                              Ver
                            </Button>
                            <Button 
                              size="xs" 
                              colorScheme="red" 
                              variant="ghost"
                              onClick={() => {
                                // Eliminar usuario de la lista
                                const newData = [...parsedData];
                                newData.splice(parsedData.findIndex(u => u.username === user.username), 1);
                                setParsedData(newData);
                                setFiles(newData);
                                
                                toast({
                                  title: "Usuario eliminado",
                                  description: `Se ha eliminado ${user.first_name} ${user.last_name} de la lista`,
                                  status: "info",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              }}
                            >
                              Eliminar
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              
              {filteredData.length > previewLimit && (
                <Text fontSize="sm" color="gray.500">
                  Mostrando {previewLimit} de {filteredData.length} registros
                </Text>
              )}
              
              {/* Estadísticas básicas */}
              <Stack direction={["column", "row"]} spacing={4} mt={2}>
                <Box p={3} bg="blue.50" borderRadius="md" flex={1}>
                  <Text fontSize="sm" color="blue.700" fontWeight="medium">
                    Total Registros
                  </Text>
                  <Text fontSize="xl" fontWeight="bold" color="blue.500">
                    {parsedData.length}
                  </Text>
                </Box>
                
                <Box p={3} bg="purple.50" borderRadius="md" flex={1}>
                  <Text fontSize="sm" color="purple.700" fontWeight="medium">
                    Tipos Enseñanza
                  </Text>
                  <Text fontSize="xl" fontWeight="bold" color="purple.500">
                    {Array.from(new Set(parsedData.map(u => u._tipoEnsenanza).filter(Boolean))).length}
                  </Text>
                </Box>
              </Stack>
            </Stack>
          )}
          
          {/* Paso 3: Procesamiento de datos */}
          {activeStep === 2 && (
            <Stack spacing={6}>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md">Procesando importación: {importName}</Heading>
                <Button 
                  size="sm" 
                  onClick={() => setActiveStep(1)} 
                  variant="outline"
                  isDisabled={isLoading}
                >
                  Volver a vista previa
                </Button>
              </Flex>
              
              <Button
                colorScheme="teal"
                isLoading={isLoading}
                onClick={importUsers}
                mb={4}
              >
                {isLoading ? "Procesando..." : "Iniciar procesamiento"}
              </Button>
              
              {isProcessing && currentProcessing.length > 0 && (
                <>
                  <Progress 
                    value={getProgressPercent()} 
                    size="sm" 
                    colorScheme="teal" 
                    borderRadius="full"
                    mb={4}
                  />
                
                  <Text color={textColor}>
                    Procesados: {currentProcessing.filter(u => u.status !== 'pending').length} 
                    de {currentProcessing.length}
                  </Text>

                  <Box overflowX="auto" maxH="400px" overflowY="auto">
                    <Table variant="simple" size="sm">
                      <Thead position="sticky" top={0} bg={bgColor} zIndex={1}>
                        <Tr>
                          <Th>RUN</Th>
                          <Th>Nombre</Th>
                          <Th>Cuenta Apoderado</Th>
                          <Th>Estado</Th>
                          <Th>Cuenta Cliente</Th>
                          <Th>Estado</Th>
                          <Th>Estado General</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentProcessing.map((user) => (
                          <Tr key={user.username}>
                            <Td fontWeight="medium">{user.username}</Td>
                            <Td>{user.nombre}</Td>
                            <Td fontSize="sm">{user.accounts.apoderado.message}</Td>
                            <Td>{getStatusBadge(user.accounts.apoderado.status)}</Td>
                            <Td fontSize="sm">{user.accounts.customer.message}</Td>
                            <Td>{getStatusBadge(user.accounts.customer.status)}</Td>
                            <Td>{getStatusBadge(user.status)}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>

                  {/* Resumen de resultados */}
                  <Stack direction="row" spacing={4} mt={4}>
                    <Box p={4} bg="green.50" borderRadius="md" flex={1}>
                      <Text color="green.700" fontWeight="medium">
                        Exitosos
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="green.500">
                        {currentProcessing.filter(u => u.status === 'success').length}
                      </Text>
                    </Box>
                    <Box p={4} bg="red.50" borderRadius="md" flex={1}>
                      <Text color="red.700" fontWeight="medium">
                        Errores
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="red.500">
                        {currentProcessing.filter(u => u.status === 'error').length}
                      </Text>
                    </Box>
                    <Box p={4} bg="yellow.50" borderRadius="md" flex={1}>
                      <Text color="yellow.700" fontWeight="medium">
                        En proceso
                      </Text>
                      <Text fontSize="2xl" fontWeight="bold" color="yellow.500">
                        {currentProcessing.filter(u => u.status === 'processing').length}
                      </Text>
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      
      {/* Modal para ver detalles de usuario */}
      <UserDetailModal 
        user={selectedUser}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedUser(null);
        }}
      />

      {/* Modal de vista previa de roles */}
      <RolePreviewModal 
        isOpen={showRolePreview}
        onClose={() => setShowRolePreview(false)}
        preview={rolePreview}
        onContinue={async () => {
          await updateExistingUserRoles();
          setShowRolePreview(false);
          
          // Inicializar el estado de procesamiento
          const initialProcessing = files.map(user => ({
            username: user.username,
            nombre: `${user.first_name} ${user.last_name}`,
            status: 'pending',
            message: 'Pendiente de proceso',
            accounts: {
              apoderado: { status: 'pending', message: 'Pendiente' },
              customer: { status: 'pending', message: 'Pendiente' }
            }
          }));
          
          setCurrentProcessing(initialProcessing);
          setIsProcessing(true);
          setActiveStep(2); // Avanzar al paso de procesamiento
        }}
        isLoading={isLoadingRoles}
        progress={roleUpdateProgress}
      />
    </Container>
  );
};

export default ImportUsers;