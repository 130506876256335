import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axios, {post, get} from 'axios';
const AppContext = createContext({});
const API_URL = 'https://datofy.cl/api';
import { getToken, saveToken } from "utils/auth";

const AppContextProvider = ({ children }) => {
    const authProvider = useProvideAuth();
    const alumnosProvider = useProvideAlumnos();
    const customersProvider = useProvideCustomers();
    
    const [updateCount, setUpdateCount] = useState(0);

    const handleUpdateData = () => {
      setUpdateCount(updateCount + 1);
    };

    return (
        <AppContext.Provider
        value={{
            isAuthenticated: authProvider.isAuthenticated,
            authenticate: authProvider.authenticate,
            token: authProvider.token,
            handleLogout: authProvider.handleLogout,
            setIsAuthenticated: authProvider.setIsAuthenticated,

            alumnos_seleccionados: alumnosProvider.alumnos,
            setAlumnos: alumnosProvider.setAlumnos,
            alumnosTable: alumnosProvider.alumnosTable,
            getAlumnosTable: alumnosProvider.getAlumnosTable,
            alumnosGetParams: alumnosProvider.alumnosGetParams,
            setAlumnosGetParams: alumnosProvider.setAlumnosGetParams,

            groupSelected: customersProvider.groupSelected,
            setGroupSelected: customersProvider.setGroupSelected,
            customersTable: customersProvider.customersTable,
            setCustomersTable: customersProvider.setCustomersTable,
            customersGetParams: customersProvider.customersGetParams,
            setCustomersGetParams: customersProvider.setCustomersGetParams,
            ordersGetParams: customersProvider.ordersGetParams,
            setOrdersGetParams: customersProvider.setOrdersGetParams,

            updateCount,
            handleUpdateData
        }}
        >
            {children}
        </AppContext.Provider>
    );
};

function useProvideAuth() {
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(token ? true : false);
    setToken(token)    
  }, []);

  return {
    isAuthenticated,
    setIsAuthenticated,
    token,
    setToken,
    handleLogout,
  };
}

function useProvideAlumnos() {
  const [alumnos, setAlumnos] = useState([]);
  const [alumnosTable, setAlumnosTable] = useState([]);
  const [alumnosGetParams, setAlumnosGetParams] = React.useState();
  const [tableRef, setTableRef] = React.useState(null);

  const getAlumnosTable = (params) => {
    return get(`${API_URL}/alumnos`, {
      headers,
      params: params || alumnosGetParams,
    })
      .then((response) => {
        setAlumnosTable(response.data);
        return response.data;
      });
  }
  
  return {
    alumnos,
    setAlumnos,
    alumnosTable,
    alumnosGetParams,
    setAlumnosGetParams,
    getAlumnosTable,
  }
}

function useProvideCustomers() {
  const [groupSelected, setGroupSelected] = useState({});
  const [customersTable, setCustomersTable] = useState([]);
  const [customersGetParams, setCustomersGetParams] = useState({per_page: 10, page: 1});
  const [ordersGetParams, setOrdersGetParams] = useState({
    orderby: 'modified',
    order: 'desc',
  });

  // Cargar los datos de LocalStorage al inicio
  useEffect(() => {
      const selectedGroup = JSON.parse(localStorage.getItem('groupSelected'));
      const tableCustomers = JSON.parse(localStorage.getItem('customersTable'));
      const getParamsCustomers = JSON.parse(localStorage.getItem('customersGetParams'));
      const getParamsOrders = JSON.parse(localStorage.getItem('ordersGetParams'));

      if (selectedGroup) { // Aquí se debe usar selectedGroup en lugar de groupSelected
        console.log('selectedGroup', selectedGroup)
        setGroupSelected(selectedGroup);
      }

      if (tableCustomers) {
          setCustomersTable(tableCustomers);
      }

      if (getParamsCustomers) {
          setCustomersGetParams(getParamsCustomers);
      }

      // if (getParamsOrders) {
      //   setOrdersGetParams(getParamsOrders);
      // }
  }, []);

  // Actualizar los datos en LocalStorage cuando cambien
  useEffect(() => {
    localStorage.setItem('groupSelected', JSON.stringify(groupSelected));
    localStorage.setItem('customersTable', JSON.stringify(customersTable));
    localStorage.setItem('ordersGetParams', JSON.stringify(ordersGetParams));
  }, [groupSelected, customersTable, customersGetParams, ordersGetParams]);

  return {
    groupSelected,
    setGroupSelected,
    customersTable,
    setCustomersTable,
    customersGetParams,
    setCustomersGetParams,
    ordersGetParams,
    setOrdersGetParams
  }
}


const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };