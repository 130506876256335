import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button, Grid, Box, useColorModeValue, HStack, VStack, Input, Center, Text, GridItem, Textarea, Heading, SimpleGrid, List, ListItem, Flex, ListIcon, Image, RadioGroup, Radio, Stack, Spinner } from '@chakra-ui/react';
import { modifyOrder, getOrderDetails, sendReminder } from '../../services/woo'
import SurveyCard from 'views/Dashboard/Profile/components/SurveyCard';
import axios from 'axios';
import { getAllSurveys, API_TOKEN, API_URL } from 'services/surveyService';
import { transformarFecha, getSiteConfig } from 'utils/site';
import { useForm, Controller } from "react-hook-form"
import get from 'lodash/get';
import { getSurvey } from 'services/surveyService';
import SignatureInput from 'components/Signature';
import { MdLocalPhone, MdLocationCity } from 'react-icons/md';
import { getUserAuth } from "services/surveyService";

const ModalAddSurveyResponse = ({ isOpen, onClose, customer, updateSurveyResponses }) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.500", "gray.300");
    const bgColor = useColorModeValue("gray.100", "gray.600");
    const [surveyNameLoaded, setSurveyNameLoaded] = useState({});
    const [activeComponent, setActiveComponent] = useState('surveyList');
    const [surveySiteList, setSurveySiteList] = useState([
        { name: 'Encuesta 1', description: 'Descripción 1', "created_at":"2024-02-29T04:36:12.000000Z" },
        { name: 'Encuesta 2', description: 'Descripción 2', "created_at":"2024-02-29T04:36:12.000000Z" },
    ]);
    const datofy_api = {
        url: API_URL,
        token: `${API_TOKEN}`
    }
    
    // Cruzamos el nombre del sitio Woo registrado en API Suscriptions (config) con el slug de la API Surveys
    const [glosario, setGlosario] = useState({ 'Pagos CapitalWeb CL': 'pagos-capitalweb-cl', 'Pagos BlasPascal CL': 'blaspascal', 'Pagos Testing': 'blaspascal' });
    
    const handleGlosario = (valor) => { return glosario[valor] || 'blaspascal'; };
    
    const [siteConfig, setSiteConfig] = useState({});
    const [slugCurrentSite, setSlugCurrentSite] = useState('');
    const site = slugCurrentSite;
    const [userAuth, setUserAuth] = useState({});
    
    useEffect(() => {
        const get_site_config = getSiteConfig()
        const site_config = JSON.parse(get_site_config)
        const slug_current_site = handleGlosario(site_config.name)
        console.log('slug_current_site ', slug_current_site)
        setSlugCurrentSite(slug_current_site);
        setSiteConfig(site_config);

        const fetchSurveys = async () => {
            try {
                const response = await getAllSurveys(slug_current_site);
                const surveys = response;
                setSurveySiteList(surveys);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSurveys();

        const userAuth = async () => {
            try {
                const user = await getUserAuth();
                console.log('userAuth ', user);
                setUserAuth(user);
            }
            catch (error) {
              console.error(error);
            }
          };
          userAuth();
    }, [isOpen]);

        
    const FormFieldCreator = () => {
        const [fields, setFields] = useState([]);
        const [fieldName, setFieldName] = useState('');
        const {
            register,
            handleSubmit,
            watch,
            setValue,
            formState: { errors },
          } = useForm();

        const handleFieldNameChange = (event) => {
            setFieldName(event.target.value);
        };
        
        const handleAddField = (fieldName, fieldType) => {
            setFields(prevFields => [...prevFields, { text: fieldName, type: fieldType ? fieldType : 'text' }]);
            setFieldName('');
        };
        
        const onSubmit = async (data, e) => {
            e.preventDefault();
            console.log(data);
            // Resto de tu código
            alert(JSON.stringify(data, null, 2));
            const response = await handleSave(data);
            console.log(response);
        };

        const handleSave = async (data) => {
            if (window.confirm('¿Desea guardar el formulario?')) {
                // Crear formulario asociado al sitio
                try {
                    const response = await axios.post( datofy_api.url + '/sites/' + site + '/surveys', data, {
                        headers: {
                            Authorization: `Bearer ${datofy_api.token}`,
                        },
                    });
                    console.log('Form saved successfully');
                    alert('Form saved successfully');
                    return response;
                } catch (error) {
                    console.error('Error saving form:', error);
                    alert('Error saving form');
                }
            } else {
                // Close the alert
                console.log('Form not saved');
            }
        };

        const handleRemoveField = (indexToRemove) => {
            setFields(prevFields => prevFields.filter((_, index) => index !== indexToRemove));
        };   

        const surveyName = watch("survey_name");

        useEffect(() => {
        setValue("sections[0].section_name", surveyName);
        }, [surveyName, setValue]);

        return (
            <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input {...register("survey_name", { required: true, maxLength: 60 } )} placeholder={'Nombre de la encuesta'} mb={6} isInvalid={errors.survey_name ? true : false} />
                {/* Resto del formulario */}
                <VStack spacing={2}>
                  <HStack spacing={2}>
                    <Input 
                      placeholder='Nombre del campo' 
                      value={fieldName} 
                      onChange={handleFieldNameChange} 
                    />
                    <Button onClick={() => handleAddField(fieldName)}>+</Button>
                  </HStack>
                  {fields.map((field, index) => (
                    <HStack key={index} spacing={2} alignItems="flex-start" width="full">
                      <VStack spacing={1} flex={2}>
                        <Input 
                          defaultValue={field.text} 
                          {...register(`sections[0].questions[${index}].text`, { required: true })} 
                          isInvalid={errors.sections && errors.sections[0] && errors.sections[0].questions && errors.sections[0].questions[index] && errors.sections[0].questions[index].text ? true : false} 
                          errorBorderColor='red.300' 
                          placeholder="Texto de la pregunta" 
                        />
                        <Input 
                          defaultValue={field.subtext} 
                          {...register(`sections[0].questions[${index}].subtext`)} 
                          placeholder="Subtexto de la pregunta (opcional)" 
                          variant='flushed' 
                          size={'xs'}
                        />
                      </VStack>
                      <HStack flex={1} justifyContent="space-between">
                        <Input 
                          defaultValue={field.type} 
                          variant='flushed' 
                          size={'xs'} 
                          {...register(`sections[0].questions[${index}].type`)} 
                          type='text' 
                        />
                        <Button 
                          size="sm" 
                          colorScheme="red" 
                          variant='outline' 
                          onClick={() => handleRemoveField(index)}
                        >
                          x
                        </Button>
                      </HStack>
                    </HStack>
                  ))}
                  <Button type='submit'>Guardar</Button>
                </VStack>
            </form>
            </>
        );
    };

    const modalContentFormFieldCreator = () => (
        <>
        <ModalHeader><Button onClick={ () => setActiveComponent('surveyList') }>Volver</Button> Crear formulario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <FormFieldCreator />
        </ModalBody>
        </>
    );

    const SurveyList = () => {
        return (<>
            <Text>Todos los formularios</Text>
            {surveySiteList?.length > 0 && (
            <Grid
            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(1, 1fr)" }}
            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
            gap='24px'
            overflowY={{ sm: "auto" }}
            maxHeight={{ sm: "300px", md: "200px" }}
            className="custom-scrollbar"
            pr={{ sm: "16px" }}
            w='100%'
            my={6}
            >
                {surveySiteList?.map((survey, index) => {
                const created_at = transformarFecha(survey.created_at);
                return (
                    <Button bg={bgColor} onClick={ () => handleShowSurveyResponse(survey) }
                    key={index}
                    name={survey?.name}
                    description={survey?.description}
                    >
                    {survey?.name}
                    </Button>
                );
                })}
            </Grid>
            )}
            {/* TODO: Activar para rol administrador */}
            {/* <Center><Button onClick={ () => setActiveComponent('formFieldCreator') } mb={3}>+</Button></Center> */}
        </>);
    };

    const modalContentSurveyList = () => (
        <>
        <ModalHeader>{customer.first_name} {customer.last_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <SurveyList />
        </ModalBody>
        </>
    );

    const SurveyResponse = () => {
        const surveyResponseName = surveyNameLoaded?.slug
        const [uploadError, setUploadError] = useState(false);
        const [messageError, setMessageError] = useState({ show: false, message: '' });
        const [loading, setLoading] = useState({ show: false, message: '', status: null });
        const [uploaded, setUploaded] = useState([]);
        const source = axios.CancelToken.source();
        const headers = { 'Authorization': 'Bearer ' + datofy_api.token, "content-type": "multipart/form-data" };
        const [files, setFiles] = useState({});
        
        const {
            handleSubmit,
            formState: { errors },
            control,
            getValues,
            register,
            setValue,
          } = useForm();

        const onSubmit = async (data) => {
            console.log('data', data)
            setLoading({ show: true, message: 'Guardando respuesta...' });
            setMessageError({ show: false, message: '' });
            const responses = []

            try {
                for (const key in data) {
                    if (data[key] instanceof FileList) {
                        const files = Array.from(data[key]); // Convertir FileList a Array
                        console.log('files', files)
                        for (const file of files) {
                            console.log('file', file)
                            const formData = new FormData();
                            formData.append(`filenames[${key}]`, file); // Usar 'key' como nombre del archivo
                            const uploadResponse = await uploadFiles(formData, customer.username)
                            
                            const question_id = key
                            const fileSurvey = { answer: uploadResponse, question_id: question_id }
                            console.log('uploadResponse', uploadResponse)
                            uploadResponse ? responses.push(fileSurvey) : setUploadError(true)
                        }
                    } else {
                        // Si no es un archivo, dar formato a la respuesta de texto
                        const textSurvey = { answer: data[key], question_id: key }
                        responses.push(textSurvey)
                    }
                }
            } catch (error) {
                console.error("Proceso detenido debido a un error:", error.message);
                // Aquí puedes agregar lógica adicional para manejar el error si es necesario
                alert("Error al guardar la respuesta. Intente nuevamente.");
                return;
            }

            
            // Obtener el valor del input del apoderado email
            const apoderadoEmail = document.querySelector('input[data-name="Correo Apoderado(a)"]').value;

            // Reemplazar el campo email en el objeto customer
            const updatedCustomer = { ...customer, email: apoderadoEmail };

            const surveyResponse = { 
                survey_slug: surveyResponseName, 
                answers: responses,
                username: customer.username,
                customer: updatedCustomer
            }
            console.log('surveyResponse', surveyResponse)

            setUploaded(responses);
            console.log("responses", responses)
            if (responses.length > 0) {
                const confirmResponse = window.confirm("¿Desea enviar la respuesta de la encuesta?");
                if (confirmResponse) {
                    const surveyRes = await newSurveyResponse(surveyResponse);
                    setLoading({ show: true, message: 'Guardado exitoso', status: 'success' });
                    console.log(surveyRes);
                    
                    setTimeout(() => {
                        updateSurveyResponses(newSurveyResponse);
                    }, 1000);
                } else {
                    setLoading({ show: false, message: '', status: null });
                }
            }

        }

        const uploadFiles = async (formData, username) => {
            formData.append(`userfound[username]`, username);
            // TODO: Solo para el formulario de atención no adjuntar archivos en DB
            formData.append(`assign_to_user`, false);
            try {
                const response = await axios.post(datofy_api.url + "/files", formData, headers);
                const fileUploaded = response.data.data[0];
                return fileUploaded;
            } catch (error) {
                if (typeof setMessageError === 'function') {
                    setMessageError({ show: true, message: "Error al subir el archivo" });
                    setLoading({ show: false, message: '', status: null });
                } else {
                    console.error("setMessageError no está definida");
                }
                throw new Error("Error al subir el archivo");
            }
        };

        const newSurveyResponse = async (surveyResponse) => {
            try {
                const response = await axios.post( datofy_api.url + "/sites/" + site + "/surveys/" + surveyResponseName + "/webhook", surveyResponse, { cancelToken: source.token });
                console.log(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }

        useEffect(() => {
            // Seleccionar los inputs con data-name específicos y establecer sus valores
            const inputs = [
                { name: 'Alumno', value: `${customer.first_name} ${customer.last_name}` },
                { name: 'Curso', value: '' },
                { name: 'Profesor', value: userAuth ? userAuth.first_name + ' ' + userAuth.last_name : '' },
                { name: 'Correo Apoderado(a)', value: customer.email, required: true },
                { name: "meta_data_extra['survey_interviewer_auth']", value: userAuth ? userAuth.username : '' },
            ];

            inputs.forEach(input => {
                const element = document.querySelector(`input[data-name="${input.name}"]`);
                if (element) {
                    setValue(element.name, input.value);
                    if (input.required) {
                        element.required = true;
                    }
                    if (input.name === 'Alumno' || input.name === 'Profesor') {
                        element.style.border = 'none';
                        element.readOnly = true;
                    }
                }
            });
        }, [setValue, customer, userAuth]);       

        return (
            <>
            <ModalHeader>
                <Button onClick={ () => setActiveComponent('surveyList') } mb={6}>Volver</Button> 
                <SimpleGrid columns={2} gap={0} mb={8} templateColumns="50% 50%" pb={'6'} borderBottom={'1px solid'} borderColor={'gray.200'}>
                <Flex alignItems="center">
                <Image src="https://blaspascal.cl/wp-content/uploads/2022/10/logo_relieve-150x150-1-150x150.png" boxSize="80px" mr={4} />
                <Box>
                    <Text fontSize="xl" fontWeight="bold">Blas Pascal School</Text>
                    <Text fontSize="lg" color="gray.600">Colegio Osorno</Text>
                </Box>
                </Flex>
                <Box>
                    <List spacing={2} textAlign={'right'} fontSize={'md'} color={'gray.500'}>
                    <ListItem>
                        <ListIcon as={MdLocalPhone} color="gray.400" />
                        +56 9 6762 2809
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdLocationCity} color="gray.400" />
                        Av. Manuel Rodriguez 895, 5310785 Osorno, Los Lagos
                    </ListItem>
                    </List>
                </Box>
            </SimpleGrid>

            <SimpleGrid columns={2} gap={0} mb={8} templateColumns="50% 50%" pb={'6'} borderBottom={'1px solid'} borderColor={'gray.200'}>
                <Flex alignItems="center">
                <Box>
                    <Text fontSize="xl" fontWeight="bold">{customer.first_name} {customer.last_name}</Text>
                    <Text fontSize="lg" color="gray.500">{customer.username}</Text>
                </Box>
                </Flex>
                <Box>
                    <List spacing={0} textAlign={'right'} fontSize={'md'}>
                    <ListItem>
                        Correo electrónico <Text fontSize="md" color="gray.500">{customer.email}</Text>
                    </ListItem>
                    </List>
                </Box>
            </SimpleGrid>
                
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody px={12}>

            {loading.show && (
                <Box display="flex" justifyContent="center" alignItems="center" height="10vh">
                    <Spinner size="xl" /> <Text ml={4}>{loading.message}</Text>
                </Box>
            )}

            <Heading size="2xl" mb={'3rem'} textAlign={'center'}>{surveyNameLoaded?.name}</Heading>

            <form onSubmit={handleSubmit(onSubmit)}>
                {surveyNameLoaded && surveyNameLoaded.questions && Object.values(surveyNameLoaded.questions.reduce((sections, question) => {
                    // Agrupar las preguntas por sección
                    (sections[question.section.id] = sections[question.section.id] || []).push(question);
                    return sections;
                }, {})).map((questions, index) => (                        
                        <Box mb={6} key={index}>
                            <Text fontSize={'xl'} mb={'4'}>{questions[0]?.section?.name}</Text>
                            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                            {questions.map((question, questionIndex) => (
                                <GridItem colSpan={question.column_width} key={questionIndex}>
                                    {question.type.name !== 'hidden' && <label htmlFor={question.id}>{question.text}</label>}
                                    {question.type.name === 'signature' ? (
                                    <Controller
                                        name={`${question.id}`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                        <SignatureInput
                                            onChange={field.onChange}
                                            value={field.value}
                                        />
                                        )}
                                    />
                                    ) : question.type.name === 'textarea' ? (
                                    <Textarea
                                        {...register(`${question.id}`)}
                                        id={question.id}
                                    />
                                    ) : question.type.name === 'radio' ? (
                                    <Controller
                                        name={`${question.id}`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <RadioGroup {...field}>
                                                <Stack direction="row">
                                                    {(question.response_choices && question.response_choices.length > 0) ? (
                                                        question.response_choices.map((option, optionIndex) => (
                                                            <Radio key={optionIndex} value={option.text}>{option.text}</Radio>
                                                        ))
                                                    ) : (
                                                        <Radio value="">No options available</Radio>
                                                    )}
                                                </Stack>
                                            </RadioGroup>
                                        )}
                                    />
                                    ) :
                                    (
                                    <Input 
                                        {...register(`${question.id}`)} 
                                        type={question.type.name} 
                                        data-name={question.text}
                                        id={question.id}
                                    />
                                    )}
                                </GridItem>
                            ))}
                            </Grid>
                        </Box>
                ))}

                {messageError.show && <Text color="red.500">{messageError.message}</Text>}
                <Button type='submit' mb={'4'} colorScheme='teal' isLoading={loading.show} float={'right'}>Guardar</Button>
            </form>
            
            </ModalBody>
            </>
        );
    };

    const handleShowSurveyResponse = (survey) => {
        setSurveyNameLoaded(survey);
        setActiveComponent('surveyResponse');
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
            <ModalOverlay />
            <ModalContent>
                {activeComponent === 'formFieldCreator' ? modalContentFormFieldCreator() : activeComponent === 'surveyResponse' ? <SurveyResponse /> : modalContentSurveyList()}
            </ModalContent>
        </Modal>
    );
};

export default ModalAddSurveyResponse;