import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import CheckoutLayout from "layouts/Checkout.js";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import { AppContextProvider, useAppContext } from "./context/appContext";
import "./assets/custom-styles.css";
import CheckoutCustomOrder from "views/Dashboard/Suscriptions/components/CheckoutCustomOrder";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-0XT1JWTL8Q"; // Reemplaza con tu ID de seguimiento de Google Analytics
ReactGA.initialize([{ trackingId: TRACKING_ID }]);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, token } = useAppContext();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/signin" />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, token } = useAppContext();

  // TODO: Redirect to /admin if user is authenticated
  // if (isAuthenticated) {
  //   return <Redirect to="/admin" />
  // }

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  );
};

const LogoutRoute = () => {
  const { handleLogout } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    handleLogout();
    history.push("/auth/signin");
  }, [handleLogout, history]);

  return null;
};

const Analytics = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return children;
};

ReactDOM.render(
  <AppContextProvider>
    <HashRouter>
      <Analytics>
        <Switch>
          <PublicRoute path={`/auth`} component={AuthLayout} />
          <PublicRoute path={`/checkout`} component={CheckoutLayout} />
          <PrivateRoute path={`/admin`} component={AdminLayout} />
          <Route path={`/rtl`} component={RTLLayout} />
          <Route path={`/logout`} component={LogoutRoute} />
          <Redirect from={`/`} to="/admin/dashboard" />
        </Switch>
      </Analytics>
    </HashRouter>
  </AppContextProvider>,
  document.getElementById("root")
);
