// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import imageArchitect1 from "assets/img/ImageArchitect1.png";
import imageArchitect2 from "assets/img/ImageArchitect2.png";
import imageArchitect3 from "assets/img/ImageArchitect3.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import ProjectCard from "./ProjectCard";
import SurveyCard from "./SurveyCard";
import { transformarFecha } from "utils/site";
import ModalAddSurveyResponse from "components/Modal/ModalAddSurveyResponse";
import ModalSurveyResponse from "components/Modal/ModalSurveyResponse";
import { getRespondentSurveys, getSurveyResponses } from "services/surveyService";
import { getSurveyResponse } from "services/surveyService";

const Surveys = ({ title, description, data, customer, updateSurveyResponses }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSurveyResponse, setIsOpenSurveyResponse] = useState(false);
  const [surveyModalData, setSurveyModalData] = useState({});

  const handleOpenModal = (customer) => {
    console.log('handleOpenModal ', customer);
    setIsOpen(true);
  };

  const handleUpdateSurveyResponses = async () => {
    console.log('handleOpenModalSurveyResponse ', customer);
      const response = await getRespondentSurveys(customer.username);
      console.log('response ', response);
      updateSurveyResponses(response);

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCloseModalSurveyResponse = () => {
    setIsOpenSurveyResponse(false);
  };

  const handleOpenModalSurveyResponse = (survey) => {
    console.log('handleOpenModalSurveyResponse ', survey);
    setSurveyModalData(null);
    setIsOpenSurveyResponse(true);
    // TODO: No sería necesario volver a cargar la encuesta
    const fetchSurvey = async () => {
      try {
        const surveyResponse = await getSurveyResponse(survey.id);
        console.log('surveyResponse ', surveyResponse);
        setSurveyModalData(surveyResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSurvey();

  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Card p='16px' my='0'>

      <ModalAddSurveyResponse 
        isOpen={isOpen} 
        onClose={handleCloseModal} 
        customer={customer}
        updateSurveyResponses={handleUpdateSurveyResponses}
        userAuth={customer}
      />

      <ModalSurveyResponse
        isOpen={isOpenSurveyResponse}
        onClose={handleCloseModalSurveyResponse}
        customer={customer}
        data={surveyModalData}
        onSurveyDeleted={handleUpdateSurveyResponses}
      />

      <CardHeader p='12px 5px' mb='12px'>
        <Flex direction='row' justify='space-between' align='center' w={'100%'}>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <Text fontSize='sm' color='gray.500' fontWeight='400'>
            {description}
          </Text>

          <Button p='2' bg='transparent' color='gray.500' border='1px solid lightgray' borderRadius='15px' ml='auto' fontSize='xs' leftIcon={<FaEdit />}
            onClick={() => handleOpenModal(customer)}
          >
             Llenar ficha
          </Button>
        </Flex>
      </CardHeader>
      <CardBody px='5px'>
        
        {!data?.data?.length > 1 && (
          <Text fontSize='sm' color='gray.500' fontWeight='400'>
            No hay documentos registrados
          </Text>
        )}

        {data && !data.success && (
          <Text fontSize='sm' color='gray.500' fontWeight='400'>
          Esta cuenta no registra actividad
        </Text>
        )}

        {data?.data?.length >= 1 && (
        <Grid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
          templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
          gap='24px'
          overflowY={{ sm: "auto" }}
          maxHeight={{ sm: "300px", md: "500px" }}
          className="custom-scrollbar"
          pr={{ sm: "16px" }}
          w='100%'
          >
            {data.data.map((surveyResponse, index) => {
              const created_at = transformarFecha(surveyResponse.created_at);
              return (
                <SurveyCard
                  key={index}
                  image={imageArchitect1}
                  name={surveyResponse?.survey?.name}
                  category={surveyResponse?.survey?.slug}
                  description={created_at}
                  avatars={[avatar2, avatar4, avatar6]}
                  template={'survey-default'}
                  surveyResponse={surveyResponse}
                  onClick={() => handleOpenModalSurveyResponse(surveyResponse)}
                />
              );
            })}

          {/* <Button
            p='0px'
            bg='transparent'
            color='gray.500'
            border='1px solid lightgray'
            borderRadius='15px'
            minHeight={{ sm: "200px", md: "100%" }}>
            <Flex direction='column' justifyContent='center' align='center'>
              <Icon as={FaPlus} fontSize='lg' mb='12px' />
              <Text fontSize='lg' fontWeight='bold'>
                Create a New Project
              </Text>
            </Flex>
          </Button> */}
        </Grid>
        )}
        
      </CardBody>
    </Card>
  );
};

export default Surveys;
