// SurveyGroupSelector.js
import React from 'react';
import { Box, Button, HStack, Text, useColorModeValue } from '@chakra-ui/react';

const SurveyGroupSelector = ({ onSelectGroup }) => {
  const groups = [
    {
      name: "Becas 2025",
      surveys: ["becas-2025", "becas-2025-archivos"]
    },
    {
      name: "Matrículas 2025",
      surveys: ["pre-matriculas-2025-contrato", "pre-matriculas-2025-salud", "pre-matriculas-2025-antecedentes"]
    }
  ];
  
  const buttonBg = useColorModeValue('gray.100', 'gray.700');
  const buttonHoverBg = useColorModeValue('gray.200', 'gray.600');
  
  return (
    <Box mb={4}>
      <Text fontWeight="medium" mb={2}>Filtros rapidos</Text>
      <HStack spacing={2} wrap="wrap">
        {groups.map(group => (
          <Button
            key={group.name}
            size="sm"
            bg={buttonBg}
            _hover={{ bg: buttonHoverBg }}
            onClick={() => onSelectGroup(group.surveys)}
          >
            {group.name}
          </Button>
        ))}
        <Button
          size="sm"
          variant="outline"
          onClick={() => onSelectGroup([])}
        >
          Limpiar selección
        </Button>
      </HStack>
    </Box>
  );
};

export default SurveyGroupSelector;