// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
    Image,
    Tag,
    Avatar,
    Select,
  } from "@chakra-ui/react";
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, { useEffect, useState } from "react";
  import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import { useAppContext } from "../../../../context/appContext";
  import { updateCustomer, createOrderCuota, getCustomer, getProducts } from "../../../../services/woo";

  function CustomersCreate({title, customer}) {
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
    const { customersSelected, handleUpdateData } = useAppContext();
    const [customerId, setCustomerId] = useState({ id: 2, first_name: "Thomas", last_name: "Delgado" });
    const [product, setProduct] = useState({ id: null });
    const [log, setLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [generateOrder, setGenerateOrder] = useState(false);

    const handleInputChange = (e) => console.log(e.target.value)

    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const createSuscription = async () => {

      console.log('create suscription')
      setLoading(true);
      const product_id = product.id;
      const customer_id = customer.id;

      // Extrar producto seleccionado de la lista de productos
      const product_found = products.find(p => p.id === parseInt(product_id));

      // Consultar la suscripción del cliente
      const customer_found = await getCustomer(customer_id);
      const suscription = _.find(customer_found.data.meta_data, { key: 'suscription_name' })
      console.log('suscription:', !_.isEmpty(suscription?.value))

      if(!_.isEmpty( suscription?.value) && !confirm('El cliente ya tiene una suscripción activa, ¿desea actualizarla?') ) {
        setLoading(false);
        return;
      } else {

        // Actualizar el estado de la suscripción del cliente
        const meta_data = { meta_data: [ { key: "suscription_name", value: product_found.name }, ] };
        const customerUpdated = await updateCustomer(customer_id, meta_data)
        console.log(customerUpdated)
        // alert('Suscripción del cliente actualizada')
        setLog([...log, {'meta_updated' : customerUpdated.data.meta_data}])
        setLoading(false)
        handleUpdateData()


        // Crear la primera orden a la suscripción del cliente
        if (generateOrder) {
          setLoading(true);
          const mes_actual = new Date().getMonth();
          const anio_actual = new Date().getFullYear();
          const cuota = mes_actual - 1; // El numero de la cuota comienza en marzo = 1
          const mes_actual_nombre = monthNames[mes_actual]
          const mes_actual_format = String(mes_actual + 1).padStart(2, '0')

          const meta_data = [
            {
              key: 'mes',
              value: anio_actual + '-' + mes_actual_format,
            },
            {
              key: 'concepto',
              value: product_found.name,
            },
            {
              key: 'monto',
              value: product_found.price,
            },
            {
              key: 'fecha_vencimiento',
              value: '15 ' + mes_actual_nombre,
            },
            {
              key: 'order_type',
              value: 'subscription_payment',
            }
          ]
          const orderCreated = await createOrderCuota(customer, product_found, meta_data);
          console.log(orderCreated);
          setLog([...log, {'order_created' : orderCreated}]);
          setLoading(false);
        }

      }
    }

    useEffect(() => {
      getProducts().then(data => {
        console.log(data)
        setProducts(data)
      })
    }
    , [])

    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader p='6px 0px 22px 0px'>
              <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              {title || 'Filtro'}
              </Text>
          </CardHeader>
          <CardBody>

          <FormControl>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              ClienteId #{customer.id}
            </FormLabel>

            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Concepto #{product?.id} {product?.name}
            </FormLabel>
            <Select onChange={(e) => setProduct({id: e.target.value})}>
            <option value="">Seleccione una opción</option>
            {products && products.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
            </Select>

            <FormControl display='flex' alignItems='center' my='24px'>
              <Switch id='generate-order' colorScheme='teal' me='10px' isChecked={generateOrder} onChange={ (e) => setGenerateOrder(e.target.checked) } />
              <FormLabel htmlFor='generate-order' mb='0' fontWeight='normal'>
                Generar orden por pagar del mes actual
              </FormLabel>
            </FormControl>

            <Button
              type='submit'
              bg='teal.300'
              fontSize='14px'
              color='white'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => createSuscription()}
              isLoading={loading}
              >
              CONFIRMAR ACTUALIZACIÓN
            </Button>
          </FormControl>

          </CardBody>
        </Card>
    );
  }
  
  export default CustomersCreate;
  