// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Custom icons
import {
  ProfileIcon,
  GlobeIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React, { useEffect, useState } from "react";
import ChartStatistics from "./ChartStatistics";
import { getSiteConfig } from "utils/site";
import { getProductsByCategories, getSummaryOrdersByMonth } from "services/woo";
import { getAllSurveyResponses, getAllFiles, getDateRange, getStatistics } from "services/surveyService";

const HEALTH_QUESTIONS = [
  "Fracturas",
  "Crisis de Asma",
  "Esguinces",
  "Convulsiones Febriles",
  "Colon Irritable",
  "Luxaciones",
  "Anteojos",
  "Jaquecas",
  "Plantillas"
];

const ActiveUsers = ({ title, percentage, chart, respondents }) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [ dashboardStats, setDashboardStats ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [currentMonthSurveyResponses, setCurrentMonthSurveyResponses] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);



  // Obtener respuestas de encuestas del mes actual
  const fetchCurrentMonthResponses = () => {
    const { startDate, endDate } = getDateRange('currentMonth');
    getAllSurveyResponses({ startDate, endDate })
      .then(data => {
        console.log('Survey responses for the current month:', data);
        setCurrentMonthSurveyResponses(data);
      })
      .catch(error => {
        console.error('Error fetching survey responses:', error);
      });
  };


  useEffect(() => {
    const getConfig = getSiteConfig()
    const config = JSON.parse(getConfig)
    setGroups(config.groups)

    // TODO: Mes actual
    // Uso de la función
    const { startDate, endDate } = getDateRange('currentMonth');
    console.log(startDate, endDate);

    getSummaryOrdersByMonth('wc-completed', startDate, endDate).then( data => {
      console.log(data)
      const months = Object.keys(data)
      const currentMonth = data[months[0]]
      const values = Object.values(data).map(item => item);
      setDashboardStats(currentMonth)
      console.log(currentMonth)

    })

    fetchCurrentMonthResponses();

  }, [])



  const calculateRatio = (yes, no) => {
    if (no === 0) return 0;
    return (yes / no).toFixed(2);
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        setLoading(true);
        const statsParams = {
          questions: HEALTH_QUESTIONS,
          respondents,
          include_detailed_responses: false
        };

        const response = await getStatistics(statsParams);
        
        const processedStats = response.data.map(item => {
          const yesOption = item.options.find(opt => opt.option === 1);
          const noOption = item.options.find(opt => opt.option === 0);
          
          return {
            title: item.question,
            amount: `${yesOption.percentage.toFixed(1)}%`,
            subText: `${yesOption.count} de ${item.response_count} casos`,
            percentage: yesOption.percentage, // Usamos el porcentaje de "Sí" para la barra
            totalResponses: item.response_count,
            icon: <StatsIcon h="15px" w="15px" color={iconBoxInside} />
          };
        });

        // Ordenamos por porcentaje de casos positivos
        const sortedStats = processedStats.sort((a, b) => 
          parseFloat(b.percentage) - parseFloat(a.percentage)
        );

        setStatistics(sortedStats);
      } catch (error) {
        console.error('Error al obtener estadísticas:', error);
        setStatistics([]);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <Card p='16px'>
      <CardBody>
        <Flex direction='column' w='100%'>
          {chart}
          
          <Flex direction='column' mt='24px' mb='16px' alignSelf='flex-start'>
            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
              {title}
            </Text>
            {/* <Text fontSize='md' fontWeight='medium' color='gray.400'>
              <Text
                as='span'
                color={percentage > 0 ? "green.400" : "red.400"}
                fontWeight='bold'>
                {percentage > 0 ? `+${percentage}%` : `-${percentage}%`}
              </Text>{" "}
              que la semana pasada
            </Text> */}
          </Flex>

          <SimpleGrid gap={{ sm: "12px" }} columns={{ sm: 1, md: 2, xl: 3 }}>
            {loading ? (
              <Text>Cargando estadísticas...</Text>
            ) : statistics.length > 0 ? (
              statistics.map((stat, index) => (
                <ChartStatistics
                  key={index}
                  title={stat.title}
                  amount={stat.amount}
                  subText={stat.subText}
                  percentage={stat.percentage}
                  icon={stat.icon}
                />
              ))
            ) : (
              <Text>No hay estadísticas disponibles</Text>
            )}
          </SimpleGrid>
          
        </Flex>
      </CardBody> 
    </Card>
  );
};

export default ActiveUsers;
