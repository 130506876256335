import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Select,
  Textarea,
  VStack,
  IconButton,
  Alert,
  AlertIcon,
  Grid,
  GridItem,
  Divider,
  HStack,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';
import { InfoOutlineIcon, RepeatIcon, TimeIcon, WarningIcon } from '@chakra-ui/icons';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { editTicket } from 'services/woo';

const ArchivedTicketCard = ({ ticket, onRestore }) => {
  const cardBg = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Box 
      p={4} 
      bg={cardBg} 
      borderRadius="lg" 
      borderWidth="1px" 
      position="relative"
    >
      <Grid templateColumns="1fr auto" gap={4}>
        <GridItem>
          <Text fontWeight="bold" mb={2}>{ticket.name}</Text>
          <HStack spacing={4} color={textColor} fontSize="sm">
            <HStack>
              <TimeIcon />
              <Text>{ticket.archivedDate}</Text>
            </HStack>
            <Divider orientation="vertical" height="20px" />
            <Text>Ventas: {ticket.soldCount}</Text>
          </HStack>
          <Text mt={2} color={textColor} fontSize="sm">
            Razón: {ticket.reason}
          </Text>
        </GridItem>
        <GridItem>
          <IconButton
            icon={<RepeatIcon />}
            colorScheme="teal"
            variant="ghost"
            onClick={() => onRestore(ticket.variantId)}
            aria-label="Restaurar ticket"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

const ArchiveTicketModal = ({ ticket, isOpen, onClose, onSuccess }) => {
  const [reason, setReason] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const cardBg = useColorModeValue('gray.50', 'gray.700');

  const archivedTickets = [
    {
      variantId: 1,
      name: 'SUPER EARLY BIRD',
      archivedDate: '2025-01-15',
      reason: 'Fin de Early Bird',
      price: 6000,
      soldCount: 50
    },
    {
      variantId: 2,
      name: 'PREVENTA INICIAL',
      archivedDate: '2025-01-18',
      reason: 'Cambio de Precio',
      price: 9000,
      soldCount: 75
    }
  ];

  const handleArchive = async () => {
    setIsLoading(true);
    try {
      const finalReason = reason === 'other' ? otherReason : reason;
      
      // Mantener la estructura del payload consistente con el endpoint de edición
      const result = await editTicket(ticket.variantId, {
        id: ticket.variantId,
        name: ticket.name,
        price: ticket.price,
        stock: ticket.stock,
        description: ticket.description,
        is_active: false, // Para archivar/desactivar
        archive_reason: finalReason,
        archive_date: new Date().toISOString()
      });

      if (result.success) {
        toast({
          title: 'Ticket archivado',
          description: 'El ticket ha sido archivado exitosamente',
          status: 'success',
          duration: 3000,
        });
        onSuccess?.();
        onClose();
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'No se pudo archivar el ticket',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestore = async (ticketId) => {
    setIsLoading(true);
    try {
      const result = await editTicket(ticketId, {
        id: ticketId,
        name: ticket.name,
        price: ticket.price,
        stock: ticket.stock,
        description: ticket.description,
        is_active: true // Para restaurar/activar
      });

      if (result.success) {
        toast({
          title: 'Ticket restaurado',
          description: 'El ticket ha sido restaurado exitosamente',
          status: 'success',
          duration: 3000,
        });
        onSuccess?.();
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'No se pudo restaurar el ticket',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent mx={4}>
        {!showArchived ? (
          <>
            <ModalHeader borderBottomWidth="1px">Archivar Tipo de Ticket</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Alert 
                status="warning" 
                mb={6} 
                borderRadius="md"
                variant="left-accent"
              >
                <AlertIcon />
                <Box>
                  <Text fontWeight="medium">Precaución al archivar</Text>
                  <Text fontSize="sm">
                    Este ticket no estará disponible para nuevas compras, pero los tickets ya vendidos seguirán siendo válidos.
                  </Text>
                </Box>
              </Alert>

              <Box
                p={4}
                bg={cardBg}
                borderRadius="lg"
                borderWidth="1px"
                borderColor={borderColor}
                mb={6}
              >
                <Text fontWeight="bold" mb={3}>{ticket?.name}</Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <Box>
                    <Text fontSize="sm" color="gray.500">Stock Vendido</Text>
                    <Text fontWeight="medium">{ticket?.stock?.sold} tickets</Text>
                  </Box>
                  <Box>
                    <Text fontSize="sm" color="gray.500">Stock Disponible</Text>
                    <Text fontWeight="medium">{ticket?.stock?.available} tickets</Text>
                  </Box>
                  <Box gridColumn="span 2">
                    <Text fontSize="sm" color="gray.500">Precio</Text>
                    <Text fontWeight="medium">${ticket?.price?.toLocaleString()}</Text>
                  </Box>
                </Grid>
              </Box>

              <VStack spacing={4} align="stretch">
                <Box>
                  <Text fontSize="sm" fontWeight="medium" mb={2}>Razón del Archivo</Text>
                  <Select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Seleccionar razón..."
                    size="lg"
                  >
                    <option value="sold_out">Agotado</option>
                    <option value="early_bird_ended">Fin de Early Bird</option>
                    <option value="price_change">Cambio de Precio</option>
                    <option value="other">Otra razón</option>
                  </Select>
                </Box>

                {reason === 'other' && (
                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={2}>Especificar Razón</Text>
                    <Textarea
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      placeholder="Describe la razón..."
                      rows={3}
                      size="lg"
                    />
                  </Box>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter borderTopWidth="1px">
              <Button 
                variant="ghost" 
                leftIcon={<InfoOutlineIcon />}
                onClick={() => setShowArchived(true)}
                mr="auto"
              >
                Ver Archivados
              </Button>
              <Button 
                variant="ghost" 
                mr={3} 
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button 
                colorScheme="red" 
                leftIcon={<WarningIcon />}
                isDisabled={!reason || (reason === 'other' && !otherReason)}
                onClick={handleArchive}
                isLoading={isLoading}
                loadingText="Archivando..."
              >
                Archivar Ticket
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader borderBottomWidth="1px">Tickets Archivados</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                {archivedTickets.map(ticket => (
                  <ArchivedTicketCard 
                    key={ticket.variantId} 
                    ticket={ticket} 
                    onRestore={ () => handleRestore(ticket.variantId) }
                  />
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter borderTopWidth="1px">
              <Button 
                w="full" 
                onClick={() => setShowArchived(false)}
                size="lg"
              >
                Volver
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ArchiveTicketModal;