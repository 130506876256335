import React, { useState } from 'react';
import {
  Box,
  Text,
  Heading,
  Stack,
  Progress,
  SimpleGrid,
  Divider,
  useColorModeValue,
  HStack,
  Badge,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { Edit2, Archive, Plus } from 'lucide-react';
import { formatCurrency } from '../../../../utils/site';
import AddTicketModal from './AddTicketModal';
import EditTicketModal from './EditTicketModal';
import ArchiveTicketModal from './ArchiveTicketModal';

export const calculateTicketTypeStats = (orders, variationsStock, stats) => {
    return Object.entries(variationsStock).map(([variantId]) => {
      const variantItems = orders.reduce((items, order) => {
        const lineItems = order.line_items || [];
        const matchingItems = lineItems.filter(
          item => item.variation_id && item.variation_id.toString() === variantId.toString()
        );
        return [...items, ...matchingItems];
      }, []);
  
      const stockInfo = variationsStock[variantId] || {};
      console.log('variationsStock', variationsStock);
      const stockQuantity = stockInfo.stock_quantity || 0;
      const stockStatus = stockInfo.stock_status || 'outofstock';
      const variantName = stockInfo.name || 'Entrada General';
      const price = stockInfo.price || 0;
      const description = stockInfo.description || '';
  
      const quantity = (stats.ticketsByType && stats.ticketsByType[variantId]) || 0;
      const totalCapacity = quantity + stockQuantity;
      const occupancyPercentage = totalCapacity > 0 
        ? ((quantity / totalCapacity) * 100).toFixed(1)
        : "0.0";
  
      const variantTotal = variantItems.reduce((total, item) => {
        const itemTotal = parseFloat(item.total || 0);
        return !isNaN(itemTotal) ? total + itemTotal : total;
      }, 0);
  
      const averagePrice = quantity > 0 ? variantTotal / quantity : price;
      const serviceFee = variantTotal * 0.10;
      const transbankFee = variantTotal * 0.02;
  
      return {
        variantId,
        name: variantName,
        quantity,
        total: variantTotal,
        averagePrice,
        stockQuantity,
        stockStatus,
        totalCapacity,
        occupancyPercentage,
        serviceFee,
        transbankFee,
        percentage: stats.totalTicketsSold ? ((quantity / stats.totalTicketsSold) * 100).toFixed(1) : 0,
        price,
        description
      };
    });
  };

  const TicketTypes = ({ 
    ticketTypeStats, 
    bgBox, 
    secondaryText, 
    dividerColor, 
    showHeading = true,
    my = {},
    ms = {},
    onAddTicket,
    onEditTicket,
    onArchiveTicket,
  }) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    
    const defaultBgBox = useColorModeValue('white', 'gray.800');
    const defaultSecondaryText = useColorModeValue('gray.600', 'gray.400');
    const defaultDividerColor = useColorModeValue('gray.200', 'gray.600');
  
    return (
      <Box p={4} bg={bgBox || defaultBgBox} borderRadius="lg" shadow="sm" mb={6} my={my} ms={ms}>
        <HStack justify="space-between" mb={4}>
          {showHeading && <Heading size="sm">Tipos de Entrada</Heading>}
          <HStack>
            <Button
              onClick={() => setIsAddModalOpen(true)}
              colorScheme='teal'
              borderColor='teal.300'
              color='teal.300'
              variant='outline'
              fontSize='xs'
              p='8px 32px'
              mx='2'
              mb={{ sm: '1rem', md: '0' }}
              w={{ sm: '100%', md: 'auto' }}>
              AGREGAR ENTRADA
            </Button>
          </HStack>
        </HStack>
  
        <Stack spacing={4}>
          {ticketTypeStats.map((ticket) => (
            <Box key={ticket.variantId}>
              <HStack justify="space-between" mb={2}>
                <Text fontWeight="medium">{ticket.name}</Text>
                <HStack spacing={2}>
                  <Badge 
                    colorScheme={
                      ticket.stockStatus === 'instock' 
                        ? ticket.stockQuantity > 20 ? "green" 
                        : ticket.stockQuantity > 10 ? "yellow" 
                        : "red"
                        : "gray"
                    }
                  >
                    {ticket.stockStatus === 'instock' 
                      ? `${ticket.stockQuantity} disponibles`
                      : 'No disponible'}
                  </Badge>
                  <IconButton
                    icon={<Edit2 size={16} />}
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setSelectedTicket(ticket);
                      setIsEditModalOpen(true);
                    }}
                    aria-label="Editar ticket"
                  />
                  <IconButton
                    icon={<Archive size={16} />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => {
                      setSelectedTicket(ticket);
                      setIsArchiveModalOpen(true);
                    }}
                    aria-label="Archivar ticket"
                  />
                </HStack>
              </HStack>
              
              <SimpleGrid columns={{ base: 1, md: 5 }} spacing={2} mb={2}>
                <Text fontSize="sm">Vendidos: {ticket.quantity}</Text>
                <Text fontSize="sm">Ingresos: {formatCurrency(ticket.total)}</Text>
                <Text fontSize="sm">Cargo Servicio: {formatCurrency(ticket.serviceFee)}</Text>
                <Text fontSize="sm">Cargo Transbank: {formatCurrency(ticket.transbankFee)}</Text>
                <Text fontSize="sm">Precio/entrada: {formatCurrency(ticket.averagePrice)}</Text>
              </SimpleGrid>
              
              <Progress 
                value={parseFloat(ticket.occupancyPercentage)} 
                size="sm" 
                colorScheme={
                  parseFloat(ticket.occupancyPercentage) > 90 ? "gray" 
                  : parseFloat(ticket.occupancyPercentage) > 75 ? "yellow" 
                  : "green"
                }
              />
              <Text fontSize="xs" color={secondaryText || defaultSecondaryText}>
                {ticket.occupancyPercentage}% de ocupación ({ticket.quantity} de {ticket.totalCapacity} entradas)
              </Text>
              <Divider mt={2} borderColor={dividerColor || defaultDividerColor} />
            </Box>
          ))}
        </Stack>
  
        <AddTicketModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onAddTicket={onAddTicket}
        />
  
        <EditTicketModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={onEditTicket}
          ticket={selectedTicket}
        />
  
        <ArchiveTicketModal
          isOpen={isArchiveModalOpen}
          onClose={() => setIsArchiveModalOpen(false)}
          ticket={selectedTicket}
          onArchive={onArchiveTicket}
        />

      </Box>
    );
  };
  
  export default TicketTypes;