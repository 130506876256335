// Chakra imports
import { Flex, Icon, Link, Text, useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button
 } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useMemo } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import {   
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
  HamburgerIcon,
  ChevronDownIcon,
  DeleteIcon
 } from '@chakra-ui/icons'
 import moment from "moment";
 import 'moment/locale/es';
import CustomersCreate from "views/Dashboard/Suscriptions/components/CustomersCreate";
import { useModuleCheck, MODULES } from "utils/site";

const ProfileInformation = ({
  title,
  description,
  name,
  email,
  customer,
}) => {
  moment().locale('es')
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const { id: customer_id } = customer;
  const subscription_name = customer?.meta_data.find( meta => meta.key === 'suscription_name' )?.value;
  const group_name = customer?.meta_data.find( meta => meta.key === 'group_name' )?.value;
  const wc_last_active = customer?.meta_data.find( meta => meta.key === 'wc_last_active' )?.value;
  const timestamp = moment.unix(wc_last_active);
  const last_active = timestamp.format('MMMM D, YYYY');
  const full_name = `${customer.first_name} ${customer.last_name}`
  const [toogleForm, setToogleForm] = useState(false);
  const [loading, setLoading] = useState({ state: false, message: '' });

  const moduleStatus = useMemo(() => ({
      [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
      [MODULES.FILES]: useModuleCheck(MODULES.FILES),
      [MODULES.SUBSCRIPTIONS]: useModuleCheck(MODULES.SUBSCRIPTIONS),
    }), []);

  const cancelSuscription = async () => {
    console.log('cancel suscription')
    if (!confirm('¿Está seguro que desea cancelar la suscripción?')) {
      return;
    }
    setLoading({ state: true, message: 'Cancelando suscripción...' });
    const meta_data = { meta_data: [ { key: "suscription_name", value: null }, ] };
    const customerUpdated = await updateCustomer(customer_id, meta_data);
    console.log('customerUpdated:', customerUpdated)
    setLoading({ state: false });
  }

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='0 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column'>
          {moduleStatus[MODULES.SUBSCRIPTIONS] && 
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  {subscription_name ? <>
                    <Text color={'green'}>{subscription_name}</Text>
                    </> : <Text color='gray'>No tiene suscripción activa</Text>}
              </MenuButton>
              <MenuList>
                  <MenuItem icon={<RepeatIcon />} onClick={() => {
                  setToogleForm(!toogleForm)
                }}>
                    Cambiar suscripción
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={() => {
                cancelSuscription()
              }}>
                  Cancelar suscripción
                </MenuItem>
              </MenuList>
            </Menu>
          }

          {toogleForm && <CustomersCreate title='Nueva suscripción' customer={customer} />}

          <Text fontSize='md' color='gray.500' fontWeight='400' mb='30px'>
            {description}
          </Text>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Nombre:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400' float={'right'}>
              {name}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Email:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {email}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Grupo:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {group_name}
            </Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileInformation;
