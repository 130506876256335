import { Badge, useBreakpointValue } from "@chakra-ui/react";

export const isAuthenticated = token => !!token;

export const saveSites = token => localStorage.setItem('user_sites', token);

export const getSites = () => localStorage.getItem('user_sites');

export const removeSites = () => localStorage.removeItem('user_sites');


export const saveSiteConfig = config => setItemWithCheck('site_config', config);

export const getSiteConfig = () => {
  const config = localStorage.getItem('site_config');
  
  // Check for invalid config values
  if (!config || config === 'null' || config === 'undefined' || config === '') {
    localStorage.clear();
    window.location.href = '/#/auth/signin';
    return null;
  }

  try {
    // Validate if config is valid JSON
    const parsedConfig = JSON.parse(config);
    if (!parsedConfig) {
      localStorage.clear();
      window.location.href = '/#/auth/signin';
      return null;
    }
    return config;
  } catch (error) {
    localStorage.clear();
    window.location.href = '/#/auth/signin';
    return null;
  }
};

export function setItemWithCheck(key, value) {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
}

export const saveCustomerSelected = customer => setItemWithCheck('customer_selected', customer);

export const getCustomerSelected = () => localStorage.getItem('customer_selected');

export const transformarFecha = (fechaString) => {
  const fecha = new Date(fechaString);
  
  const opciones = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };

  const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(fecha);

  return fechaFormateada;
}

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('es-CL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

export const formatCurrency = (amount) => {
return new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP'
}).format(amount);
};


// utils/site.js
export const StatusBadge = ({ status }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  
  const statusConfig = {
    completed: { color: 'green', text: 'Pagado' },
    pending: { color: 'yellow', text: 'Pendiente' },
    failed: { color: 'red', text: 'Fallido' },
    used: { color: 'blue', text: 'Escaneado' },
    default: { color: 'gray', text: 'Desconocido' }
  };

  const config = statusConfig[status] || statusConfig.default;

  return (
    <Badge 
      colorScheme={config.color}
      size={isMobile ? 'sm' : 'lg'}
      px={isMobile ? 3 : 4}
      py={isMobile ? 2 : 2}
      borderRadius="full"
    >
      {config.text}
    </Badge>
  );
};

export function getStatusColor(status) {
  let color;

  switch (status) {
    case "pending":
      color = "yellow";
      break;
    case "completed":
      color = "green";
      break;
    case "failed":
      color = "red";
      break;
    case "used":
      color = "blue";
      break;
    default:
      color = "gray";
      break;
  }

  return color;
}

// Esta función formatea los errores en un mensaje adecuado para mostrar en la interfaz de usuario
export const formatErrors = (message, errors) => {
  const errorMessages = [];

  if (errors && typeof errors === 'object') {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(...errors[key]);
      }
    }
  }

  return {
    message,
    errorMessages, // Array de mensajes de error
  };
};



// Verifica si un módulo está activo para el sitio actual
export const isModuleEnabled = (siteConfig, moduleSlug) => {
  if (!siteConfig || !siteConfig.modules) return false;
  return siteConfig.modules.some(module => module.slug === moduleSlug);
};

// Verifica si una encuesta está disponible para el sitio
export const isSurveyEnabled = (siteConfig, surveySlug) => {
  if (!siteConfig || !siteConfig.surveys) return false;
  return siteConfig.surveys.some(survey => survey.slug === surveySlug);
};

// Obtiene el valor de una configuración específica
export const getConfigValue = (siteConfig, key) => {
  if (!siteConfig || !siteConfig.configs) return null;
  const config = siteConfig.configs.find(config => config.key === key);
  return config ? config.value : null;
};

// Componente de alto orden para renderizado condicional basado en módulo
export const withModuleCheck = (WrappedComponent, moduleSlug) => {
  return function WithModuleCheck(props) {
    const siteConfig = JSON.parse(getSiteConfig() || '{}');
    if (!isModuleEnabled(siteConfig, moduleSlug)) {
      return null;
    }
    return <WrappedComponent {...props} />;
  };
};

// Hook personalizado para verificar módulos
export const useModuleCheck = (moduleSlug) => {
  const siteConfig = JSON.parse(getSiteConfig() || '{}');
  return isModuleEnabled(siteConfig, moduleSlug);
};

// Definición de módulos disponibles - constante fuera del componente
export const MODULES = {
  PAYMENTS: 'payments',
  TICKETS: 'tickets',
  SURVEYS: 'surveys',
  FILES: 'files',
  SUBSCRIPTIONS: 'subscriptions',
};
