import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Select,
    Text,
    Image,
    useColorModeValue,
    Badge,
    useToast,
    Progress,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Stack,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    HStack,
    VStack,
    Divider,
  } from "@chakra-ui/react";
  import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
  import React, { useState, useEffect } from "react";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import { getCustomer, getTicketsByCategories, createBatchTicketOrders } from "../../../../services/woo";
  import { formatCurrency, formatDate } from "../../../../utils/site";
  
  function BatchEventOrderCreate({ title, customers, onOrderCreated, categoryId }) {
    const toast = useToast();
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("gray.200", "gray.900");
    const statBgColor = useColorModeValue('white', 'gray.700');
    const statBorderColor = useColorModeValue('gray.200', 'gray.600');
  
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [failedOrders, setFailedOrders] = useState([]);
    const [ordersCreated, setOrdersCreated] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [eventDate, setEventDate] = useState('');
    const [variationStats, setVariationStats] = useState([]);
    const [orderQuantity, setOrderQuantity] = useState(1);
  
    const [selectedEventDetails, setSelectedEventDetails] = useState(null);
    
    useEffect(() => {
      const loadProducts = async () => {
        try {
          console.log('Loading products for category:', categoryId);
          const productsData = await getTicketsByCategories(categoryId);
          console.log('Products loaded:', productsData);
          if (Array.isArray(productsData)) {
            setProducts(productsData);
          } else {
            console.error('Products data is not an array:', productsData);
          }
        } catch (error) {
          console.error("Error loading products:", error);
          toast({
            title: "Error",
            description: "No se pudieron cargar los eventos",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      };
    
      if (categoryId) {
        loadProducts();
      } else {
        console.warn('No categoryId provided');
      }
    }, [categoryId, toast]);

    useEffect(() => {
      if (selectedProduct) {
        setSelectedEventDetails({
          date: selectedProduct.event?.date || '',
          location: selectedProduct.event?.location || '',
          image: selectedProduct.image || '',
          title: selectedProduct.event?.title || selectedProduct.name
        });
  
        const stats = selectedProduct.variations.map(variation => ({
          id: variation.id,
          name: variation.name,
          price: variation.price,
          regular_price: variation.regular_price,
          sale_price: variation.sale_price,
          stock: variation.stock_quantity,
          status: variation.stock_status,
          image: variation.image
        }));
        setVariationStats(stats);
      } else {
        setSelectedEventDetails(null);
        setVariationStats([]);
      }
    }, [selectedProduct]);
  
    const handleProductSelect = (productId) => {
      const product = products.find(p => p.id.toString() === productId);
      setSelectedProduct(product);
      setSelectedVariation(null);
      if (product?.meta_data) {
        const eventDateMeta = product.meta_data.find(meta => meta.key === 'event_date');
        setEventDate(eventDateMeta ? eventDateMeta.value : '');
      }
    };
  
    const createEventOrders = async () => {
      if (!selectedProduct || !selectedVariation) {
        toast({
          title: "Error",
          description: "Por favor selecciona un evento y tipo de entrada",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const variation = selectedProduct.variations.find(v => v.id.toString() === selectedVariation);
      if (!variation) {
        toast({
          title: "Error",
          description: "Tipo de entrada no encontrado",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const totalTickets = orderQuantity * customers.length;
      const availableStock = variation.stock_quantity;
  
      if (totalTickets > availableStock) {
        toast({
          title: "Error",
          description: `No hay suficiente stock. Necesitas ${totalTickets} tickets pero solo hay ${availableStock} disponibles`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      setLoading(true);
      setProgress(0);
      setFailedOrders([]);
  
      try {
        const meta_data_order = [
          { key: "event_date", value: eventDate },
          { key: "event_location", value: selectedProduct.meta_data.find(m => m.key === 'event_location')?.value || '' },
          { key: "ticket_type", value: variation.name },
          { key: 'order_type', value: 'event_ticket' },
          { key: 'order_from', value: 'event_client' },
        ];
  
        const result = await createBatchTicketOrders(
          customers,
          selectedProduct,
          selectedVariation,
          orderQuantity,
          meta_data_order
        );
  
        if (result.success) {
          toast({
            title: "Éxito",
            description: `Se crearon ${result.successCount} órdenes de tickets exitosamente`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
  
          setOrdersCreated(result.results.success);
  
          if (result.failedCount > 0) {
            setFailedOrders(result.results.failed);
          }
  
          if (typeof onOrderCreated === 'function') {
            onOrderCreated();
          }
        } else {
          toast({
            title: "Error",
            description: "No se pudieron crear las órdenes",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error creating orders:", error);
        toast({
          title: "Error",
          description: "Hubo un problema al crear las órdenes",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
  
    const getSelectedVariationStats = () => {
      if (!selectedVariation) return null;
      return variationStats.find(stat => stat.id.toString() === selectedVariation);
    };
  
    const calculateTotalOrders = () => {
        return orderQuantity * customers.length;
    };

    const calculateTotalAmount = () => {
      const stats = getSelectedVariationStats();
      if (!stats) return 0;
      return stats.price * calculateTotalOrders();
    };
  
    return (
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} mb="6" bg={bgColor}>
          <CardHeader p='6px 0px 22px 0px'>
            <Text fontSize='xl' color={textColor} fontWeight='bold' textAlign='center' mb='22px'>
              {title || 'Enviar entradas de cortesía'}
            </Text>
          </CardHeader>
          <CardBody>
            <Box w={'100%'}>
              {customers.length === 1 ? (
                <Box width="100%" mb={6}>
                  <Text fontSize={'2xl'}>{customers[0]?.first_name} {customers[0]?.last_name}</Text>
                  <Text fontSize={'xl'}>{customers[0]?.email}</Text>
                </Box>
              ) : (
              <Alert status="info" mb={4} borderRadius="md">
                
                <Box>
                  <AlertTitle>Información Importante</AlertTitle>
                  <AlertDescription>
                    <Stack spacing={2}>
                      <Text>
                        Las entradas serán enviadas automáticamente por correo electrónico a los {customers.length} usuarios seleccionados.
                      </Text>
                      <Text>
                        Los usuarios recibirán sus entradas en los siguientes correos:
                      </Text>
                      <Box maxH="100px" overflowY="auto" pl={4}>
                        {customers.map((customer, index) => (
                          <Text key={index} fontSize="sm" color="gray.600">
                            • {customer.email}
                          </Text>
                        ))}
                      </Box>
                    </Stack>
                  </AlertDescription>
                </Box>
              </Alert>
              )}
    
              <FormControl mb={6}>
                <Select
                  placeholder="Selecciona un evento"
                  onChange={(e) => handleProductSelect(e.target.value)}
                  mb={4}
                >
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.event?.title || product.name}
                    </option>
                  ))}
                </Select>
    
                {selectedEventDetails && (
                  <Box 
                    p={4} 
                    bg={statBgColor} 
                    borderRadius="lg" 
                    border="1px"
                    borderColor={statBorderColor}
                    mb={4}
                  >
                    <HStack spacing={4} align="start">
                      {selectedEventDetails.image && (
                        <Image
                          src={selectedEventDetails.image}
                          alt={selectedEventDetails.title}
                          borderRadius="md"
                          boxSize="100px"
                          objectFit="cover"
                        />
                      )}
                      <VStack align="start" spacing={2} flex={1}>
                        <Text fontSize="xl" fontWeight="bold">
                          {selectedEventDetails.title}
                        </Text>
                        <HStack color="gray.600">
                          <CalendarIcon />
                          <Text>{formatDate(selectedEventDetails.date)}</Text>
                        </HStack>
                        <HStack color="gray.600">
                          <TimeIcon />
                          <Text>{selectedEventDetails.location}</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Box>
                )}
    
                {selectedProduct && (
                  <Select
                    placeholder="Selecciona tipo de entrada"
                    onChange={(e) => setSelectedVariation(e.target.value)}
                    mb={4}
                  >
                    {selectedProduct.variations.map((variation) => {
                      const isSale = variation.sale_price && variation.regular_price !== variation.sale_price;
                      return (
                        <option key={variation.id} value={variation.id}>
                          {variation.name} - {formatCurrency(variation.price)}
                          {isSale && ` (Normal: ${formatCurrency(variation.regular_price)})`}
                        </option>
                      );
                    })}
                  </Select>
                )}
    
                {selectedVariation && (
                    <FormControl>
                    <FormLabel>Cantidad de órdenes por usuario</FormLabel>
                    <NumberInput
                        min={1}
                        max={100}
                        value={orderQuantity}
                        onChange={(valueString) => setOrderQuantity(parseInt(valueString))}
                        mb={4}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    </FormControl>
                )}
              </FormControl>
    
              {selectedProduct && selectedVariation && (
                <Box mb={6}>
                  <Text fontWeight="bold" mb={4}>Resumen de la compra:</Text>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Stat
                      p={4}
                      bg={statBgColor}
                      borderRadius="lg"
                      border="1px"
                      borderColor={statBorderColor}
                    >
                    <StatLabel>Total Órdenes</StatLabel>
                    <StatNumber>{calculateTotalOrders()}</StatNumber>
                    <Text fontSize="sm" color="gray.500">
                    {orderQuantity} órdenes por usuario × {customers.length} usuarios
                    </Text>
                    </Stat>
    
                    <Stat
                      p={4}
                      bg={statBgColor}
                      borderRadius="lg"
                      border="1px"
                      borderColor={statBorderColor}
                    >
                      <StatLabel>Total</StatLabel>
                      <StatNumber>{formatCurrency(calculateTotalAmount())}</StatNumber>
                    </Stat>
                  </SimpleGrid>
                </Box>
              )}
              
            {loading && (
              <Box mb="4">
                <Progress hasStripe value={progress} colorScheme="teal" mb="2" />
                <Text textAlign="center" fontSize="sm">
                  Procesando {Math.round(progress)}%
                </Text>
              </Box>
            )}
  
            {failedOrders.length > 0 && (
              <Alert status="error" mb="4" borderRadius="md">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Errores en la generación</AlertTitle>
                  <AlertDescription display="block">
                    {failedOrders.map((fail, index) => (
                      <Text key={index} fontSize="sm">
                        {fail.customer.first_name} {fail.customer.last_name}: {fail.error}
                      </Text>
                    ))}
                  </AlertDescription>
                </Box>
                <CloseButton 
                  position="absolute" 
                  right="8px" 
                  top="8px"
                  onClick={() => setFailedOrders([])} 
                />
              </Alert>
            )}
  
            <Button
              type='submit'
              bg='teal.300'
              fontSize='14px'
              color='white'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={createEventOrders}
              isLoading={loading}
              isDisabled={!selectedProduct || !selectedVariation}
            >
              ENVÍAR ENTRADAS ({calculateTotalOrders()})
            </Button>
          </Box>
        </CardBody>
      </Card>
    );
  }
  
  export default BatchEventOrderCreate;