import axios from 'axios';

export const makeAPIRequest = (url, method = 'get', data = null, params = {}) => {
  const accessToken = localStorage.getItem('token');

  const headers = {
    headers: {
      Authorization: accessToken ? 'Bearer ' + accessToken : '',
      'Content-Type': 'application/json'
    },
  };

  return axios({
    method,
    url: process.env.REACT_APP_SUSCRIPCIONES_API_URL + url,
    data,
    params,
    ...headers
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 401) {
        // localStorage.removeItem('token');
      }
      throw error;
    });
};

export const getSites = async () => {
    return makeAPIRequest('/api/sites')
    .then((data) => {
      console.log(data);
      return data
    })
    .catch((error) => {
      console.error(error);
    });
}

export const getProductsBySite = async (siteId) => {
    return makeAPIRequest(`/api/sites/${siteId}/products`)
    .then((data) => {
      console.log(data);
      return data
    })
    .catch((error) => {
      console.error(error);
    });
}

export const getUserAuth = async () => {
    return makeAPIRequest('/api/user')
    .then((data) => {
      console.log(data);
      return data
    })
    .catch((error) => {
      console.error(error);
    });
}

export const createOrderCheckout = async (product, meta_data, siteId) => {
  const filtered_meta_data = meta_data.filter(item => item.key === "mes" || item.key === "concepto" || item.key === "monto" || item.key === "fecha_vencimiento" || item.key === "usuario" || item.key === "email" || item.key === "quantity");
  const total = filtered_meta_data.find( ({ key }) => key === "monto" )?.value
  const quantity = filtered_meta_data.find( ({ key }) => key === "quantity" )?.value

  const data = [
    {
      line_items: [{ 
        product_id: product.id, 
        quantity: quantity,
        price: total,
        total: total,
        meta_data: filtered_meta_data
      }],
      meta_data: meta_data
    },
  ];
  
  const ordersCreated = await createMultipleOrdersSuscriptionAPI(data, siteId);
  console.log(ordersCreated)
  return ordersCreated.data;
}

// MARK: ORDERS BATCH
const createMultipleOrdersSuscriptionAPI = async (orders, siteId) => {
  console.log('Creating Multiple Orders', orders);

  const payload = { orders: orders };

  return makeAPIRequest(`/api/sites/${siteId}/orders-batch`, 'post', payload)
    .then((data) => {
      console.log('Created Multiple Orders', data);
      return data;
    })
    .catch((error) => {
      console.error('Error creating orders:', error.response?.data || error.message);
      throw error;
    });
};
