import React, { useState, useEffect } from "react";
import axios from "axios";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/BgSignUp.png";
import { saveToken } from '../../utils/auth';
import { saveSites, saveSiteConfig } from '../../utils/site';
import { Redirect } from 'react-router-dom';
import { useAppContext } from "../../context/appContext";

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useAppContext();
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const source = axios.CancelToken.source();
    setLoading(true)

    try {
      const response = await axios.post(process.env.REACT_APP_SURVEY_API_URL + '/api/login', {
        'username': username,
        password
      });

      const token = response.data.token;

      // Obtener el primer sitio del usuario autenticado
      axios.get(process.env.REACT_APP_SURVEY_API_URL + '/api/sites', {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      })
      .then(response => {
        const defaultSite = response.data[0]
        console.log(response.data)
        // localStorage.setItem('site_config', JSON.stringify(defaultSite))
        saveSiteConfig(JSON.stringify(defaultSite))
        saveSites(JSON.stringify(response.data))
        setIsAuthenticated(token ? true : false)
        setLoading(false)
        if (token) {
          setRedirectToReferrer(true)
          saveToken(token)
        }

      })

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  if (redirectToReferrer) {
    return <Redirect to="/admin" />;
  }

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Bienvenido
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Ingrese su correo y contraseña para iniciar sesión
            </Text>

            <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Usuario
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Usuario'
                size='lg'
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='36px'
                fontSize='sm'
                type='password'
                placeholder='Contraseña'
                size='lg'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {/* <FormControl display='flex' alignItems='center'>
                <Switch id='remember-login' colorScheme='teal' me='10px' />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  ms='1'
                  fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl> */}
              <Button
                isLoading={loading}
                fontSize='10px'
                type='submit'
                bg='teal.300'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                INICIAR SESIÓN
              </Button>
            </FormControl>
            </form>


            {/* <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold'>
                  Sign Up
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
