import {
  Avatar,
  AvatarGroup,
  Flex,
  Icon,
  Progress,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { formatCurrency } from "utils/site";
import { StatusBadge } from "utils/site";

// DashboardTableRow.js
const DashboardTableRow = (props) => {
  const { logo, name, status, username, mes, date_modified, id, onClick, total } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <Tr onClick={onClick}>
        <Td>
          <Flex direction="column" gap={2}>
            {/* Información Principal */}
            <Box>
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {name}
              </Text>
              <Flex align="center" mt={1}>
                <Icon as={logo} h="20px" w="20px" mr={2} />
                <Text fontSize="sm" color={textColor}>
                  {username}
                </Text>
              </Flex>
            </Box>

            {/* Detalles del Pedido */}
            <Box>
              <Text fontSize="sm" color="gray.500">
                #{id} {props?.main_product?.name || mes}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {date_modified}
              </Text>
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {formatCurrency(total)}
              </Text>
            </Box>

            {/* Estado */}
            <Box>
              <StatusBadge status={status} />
            </Box>
          </Flex>
        </Td>
      </Tr>
    );
  }

  // Vista Desktop
  return (
    <Tr onClick={onClick}>
      <Td minWidth={{ sm: "250px" }}>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {name}
        </Text>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Icon as={logo} h={"24px"} w={"24px"} pe="5px" />
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {username}
          </Text>
        </Flex>
      </Td>

      <Td minWidth={{ sm: "200px" }}>
        <Text fontSize="md" color={textColor} pb=".5rem">
          #{id} {props?.main_product?.name || mes}
        </Text>
        <Text fontSize="sm" color="gray.500" pb=".5rem">
          {date_modified}
        </Text>
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {formatCurrency(total)}
        </Text>
      </Td>

      <Td>
        <StatusBadge status={status} />
      </Td>
    </Tr>
  );
};

export default DashboardTableRow;
