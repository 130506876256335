// Chakra imports
import { Box, Flex, Grid, Icon, useColorModeValue, Button } from "@chakra-ui/react";
import { useParams } from 'react-router-dom'; 
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { PersonIcon, GlobeIcon, DocumentIcon } from "components/Icons/Icons";
import React, { useState, useCallback, useEffect } from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";
import CustomerList from "./components/CustomerList";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import GroupSelector from "./components/GroupSelector";
import PaymentStatistics from "./components/PaymentStatistics";
import Transactions from "./components/Transactions";
import Pending from "./components/Pending";
import { Eye, EyeOff, Settings } from "lucide-react";
import { getProductsByCategories, getOrdersWithStats, useTicketStats, getProduct, getMetaValue } from "services/woo";
import TicketTypes, { calculateTicketTypeStats } from "./components/TicketTypes";
import Projects from "../Dashboard/components/Projects";
import DynamicPaginator from "../../../components/Tables/DynamicPaginator";
import { getOrders } from "services/woo";
import SalesStats, { useSalesStats } from './components/SalesStats';

function Event() {
  const { id } = useParams();
  const bgBox = useColorModeValue('white', 'gray.800');
  const secondaryText = useColorModeValue('gray.600', 'gray.400');
  const dividerColor = useColorModeValue('gray.200', 'gray.600');
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState(null);
  const [categoryId, setCategoryId] = React.useState('tickets');
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [variationsStock, setVariationsStock] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [eventData, setEventData] = React.useState(null);
  const [orderTableData, setOrderTableData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isPendingVisible, setIsPendingVisible] = useState(false);
  const { totalAmount, totalTickets, formattedAveragePrice } = useSalesStats(orders);

  // En el estado inicial de filters
  const [filters, setFilters] = useState([
    {
      name: 'status',
      type: 'select',
      value: 'all',
      placeholder: false,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'completed', label: 'Pagado' },
        { value: 'used', label: 'Escaneado' },
        { value: 'pending', label: 'Pendiente' },
        { value: 'failed', label: 'Fallido' },
      ]
    }
   ]);

  // ORDENES DEL EVENTO
  const fetchOrders = useCallback(async (params = {}) => {
    if (!id) return; // Asegurarse de que tenemos un ID de producto
    
    setIsLoading(true);
    try {
      const filterParams = {
        product: id, // Filtrar por el ID del producto
        per_page: params.per_page || itemsPerPage,
        page: params.page || currentPage,
      };
  
      // Agregar filtros adicionales
      const statusFilter = filters.find(f => f.name === 'status')?.value;
      if (statusFilter && statusFilter !== 'all') {
        filterParams.status = statusFilter;
      }
      
      // Obtener órdenes con los parámetros
      const response = await getOrders(filterParams);
      console.log('RESPONSE', response)
      
      // Formatear las órdenes
      const orderFormated = response.data.map(order => ({
        logo: DocumentIcon,
        username: order?.customer_info?.username,
        group: order?.customer_info.meta_data.find(m => m.key === 'group_name')?.value,
        name: `${order?.customer_info?.first_name} ${order?.customer_info?.last_name}`,
        ...order
      }));

      
      setOrderTableData(orderFormated);
      setTotalOrders(response.total);
      
    } catch (error) {
      console.error('Error fetching orders:', error);
      // Manejar el error apropiadamente
    } finally {
      setIsLoading(false);
    }
  }, [id, currentPage, filters, itemsPerPage]);


  // Manejadores de paginación
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    
    fetchOrders({
      per_page: newItemsPerPage,
      page: 1
    });
  };

  // Manejador de filtros
  const handleFilterChange = (name, value) => {
    setFilters(prev => 
      prev.map(f => f.name === name ? {...f, value} : f)
    );
    setCurrentPage(1);
  };

  
  // EVENTO Y TICKETS
  React.useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        // TODO: Corregir para obtener las variaciones de un producto y no todos los productos de la categoría
        const productsData = await getProductsByCategories(categoryId);
        const getProductData = await getProduct(id);
        const featuredImage = getProductData.images?.[0]?.src || '/placeholder-image.jpg';
        const event_location = getMetaValue(getProductData.meta_data, 'event_location');
        const event_date = getMetaValue(getProductData.meta_data, 'event_date');
        const event_title = getProductData.name;
        
        setEventData({
          featuredImage,
          event_location,
          event_date,
          event_title
        });

        console.log('productsData', productsData)
        if (productsData && productsData.length > 0) {
          setProducts(productsData);
          const findProduct = productsData.find((product) => product.id == id);
          console.log(id)
          console.log(findProduct)

          setSelectedProductId(findProduct.id);
          setProduct(findProduct);

          const stockMap = findProduct.variations.reduce((acc, variation) => ({
            ...acc,
            [variation.id]: {
              stock_quantity: variation.stock_quantity,
              stock_status: variation.stock_status,
              name: variation.name,
              price: variation.price,
              ...variation
            }
          }), {});

          setVariationsStock(stockMap);

          const productOrders = await getOrdersWithStats({}, findProduct.id);
          console.log(productOrders)
          setOrders(productOrders);

          const orderFormated = productOrders.map(order => ({
            logo: DocumentIcon,
            username: order?.customer_info?.username,
            group: order?.customer_info.meta_data.find(m => m.key === 'group_name')?.value,
            name: `${order?.customer_info?.first_name} ${order?.customer_info?.last_name}`,
            ...order
          }));
          
          // setOrderTableData(orderFormated);
          // console.log('setOrderTableData', orderFormated)
          // setTotalOrders(productOrders.total);
        }
      } catch (error) {
        console.error("Error cargando datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [categoryId]);

  // Effect para cargar órdenes cuando cambian los filtros o la página
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders, currentPage, filters]);

  const stats = useTicketStats(orders, product?.id);
  const ticketTypeStats = calculateTicketTypeStats(orders, variationsStock, stats);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {/* Floating toggle button */}
      <Button
        h="52px"
        w="52px"
        position="fixed"
        right="35px"
        bottom="110px"
        zIndex="10"
        onClick={() => setIsPendingVisible(!isPendingVisible)}
        bg={bgBox}
        borderRadius="50px"
        boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
      >
        <Icon
          as={isPendingVisible ? EyeOff : Eye}
          w="20px"
          h="20px"
          color="gray.600"
        />
      </Button>

      <Grid 
        templateColumns={{ 
          sm: "1fr", 
          lg: isPendingVisible ? "3fr 1.2fr" : "1fr"
        }} 
        templateRows='1fr'
        transition="all 0.3s ease"
      >
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
              xl: "1fr 1fr 1fr 1fr",
            }}
            templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
            gap='26px'>
            <CreditCard
              backgroundImage={eventData?.featuredImage || BackgroundCard1}
              title={"Compartir"}
              number={eventData?.event_title}
              validity={{
                name: eventData?.event_date,
                data: eventData?.event_location,
              }}
              cvv={{
                name: eventData?.event_location,
                // code: "09x",
              }}
              icon={
                <Icon
                  as={Settings}
                  w='28px'
                  h='auto'
                  color='gray.400'
                />
              }
            />
            <PaymentStatistics
              icon={<Icon h={"24px"} w={"24px"} color='white' as={FaWallet} />}
              title={"Total"}
              description={"Recaudado"}
              amount={totalAmount}
            />
            <PaymentStatistics
              icon={<Icon h={"24px"} w={"24px"} color='white' as={FaWallet} />}
              title={"Tickets"}
              description={"Vendidos"}
              amount={totalTickets}
            />
          </Grid>

          <GroupSelector
            title={""}
            mastercard={{
              icon: <PersonIcon w='100%' h='100%' />,
              number: "16.760.200-K",
            }}
            visa={{
              icon: <GlobeIcon w='100%' h='100%' />,
              number: "Seleccionar grupo",
            }}
            productId={id}
            allOrders={orders}
          />

          <SalesStats orders={orders} />
        </Box>
        
        {/* Pending column with animation */}
        {isPendingVisible && (
          <Box
            opacity={isPendingVisible ? 1 : 0}
            transform={isPendingVisible ? "translateX(0)" : "translateX(-20px)"}
            transition="all 0.3s ease"
          >
            <Pending title={"Últimos escaneados"} data={invoicesData} />
          </Box>
        )}
      </Grid>
      <Grid templateColumns={{ sm: "1fr", lg: "2fr 1.2fr" }}>

        {/* <Transactions
          title={"Ventas recientes"}
          date={"23 - 30 Marzo"}
          newestTransactions={newestTransactions}
          olderTransactions={olderTransactions}
        /> */}

          <DynamicPaginator
            filters={filters}
            onFilterChange={handleFilterChange}
            totalItems={totalOrders}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            isLoading={isLoading}
          >
            <Projects
              title={"Resumen de pagos"}
              captions={["Nombre", "Pago", "Estado"]}
              data={orderTableData}
              isLoading={isLoading}
            />
          </DynamicPaginator>
        
        {/* <CustomerList title={"Gestión de tickets"} data={billingData} /> */}

        <TicketTypes 
          ticketTypeStats={ticketTypeStats}
          bgBox={bgBox}
          secondaryText={secondaryText}
          dividerColor={dividerColor}
          my={{ lg: "24px" }} ms={{ lg: "24px" }}
        />

      </Grid>
    </Flex>
  );
}

export default Event;
