import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, useColorModeValue, Heading, Center, Text, Link, Spinner, SimpleGrid, Collapse, Button, RadioGroup, Textarea, Grid, GridItem, Image, List, ListItem, ListIcon, Flex, Radio, Stack,Menu, MenuButton, MenuList, MenuItem, Icon } from '@chakra-ui/react';
import { ExternalLinkIcon, TimeIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { MdLocalPhone, MdLocationCity } from 'react-icons/md';
import { API_URL_BASE, API_URL, API_TOKEN, deleteSurvey } from 'services/surveyService';

const ModalSurveyResponse = ({ isOpen, onClose, customer, data, onSurveyDeleted }) => {
    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);
    const textColor = useColorModeValue("gray.400", "gray.500");
    const bgColor = useColorModeValue("gray.100", "gray.600");
 
    const SurveyResponseContent = () => {
        if (!data) {
            return (
                <Center h="30vh">
                    <Box>
                        <Spinner size="xl" />
                    </Box>
                </Center>
            );
        }

        // Agrupar preguntas por sección
        const sections = data?.questions?.reduce((acc, question) => {
            const sectionId = question.section.id;
            if (!acc[sectionId]) {
            acc[sectionId] = {
                name: question.section.name,
                questions: [],
            };
            }
            acc[sectionId].questions.push(question);
            return acc;
        }, {});

        const handleDeleteSurvey = async () => {
            const wasDeleted = await deleteSurvey(data?.surveyResponseId);
            if (wasDeleted) {
                onClose(); // Cierra el modal
                if (onSurveyDeleted) {
                    onSurveyDeleted(); // Notifica al componente padre para actualizar la lista
                }
            } else {
                console.error('La encuesta no se pudo eliminar o la acción fue cancelada.');
            }
        };
        

        return (
            <>
            <ModalHeader px={'3rem'} pt={'3rem'}>
                
            <SimpleGrid columns={2} gap={0} mb={8} templateColumns="50% 50%" pb={'6'} borderBottom={'1px solid'} borderColor={'gray.200'}>
                <Flex alignItems="center">
                <Image src="https://blaspascal.cl/wp-content/uploads/2022/10/logo_relieve-150x150-1-150x150.png" boxSize="80px" mr={4} />
                <Box>
                    <Text fontSize="xl" fontWeight="bold">Blas Pascal School</Text>
                    <Text fontSize="lg" color="gray.600">Colegio Osorno</Text>
                </Box>
                </Flex>
                <Box>
                    <List spacing={2} textAlign={'right'} fontSize={'md'} color={'gray.500'}>
                    <ListItem>
                        <ListIcon as={MdLocalPhone} color="gray.400" />
                        +56 9 6762 2809
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdLocationCity} color="gray.400" />
                        Av. Manuel Rodriguez 895, 5310785 Osorno, Los Lagos
                    </ListItem>
                    </List>
                </Box>
            </SimpleGrid>

            <SimpleGrid columns={2} gap={0} mb={8} templateColumns="50% 50%" pb={'6'} borderBottom={'1px solid'} borderColor={'gray.200'}>
                <Flex alignItems="center">
                <Box>
                    <Text fontSize="xl" fontWeight="bold">{customer.first_name} {customer.last_name}</Text>
                    <Text fontSize="lg" color="gray.500">{customer.username}</Text>
                    <Text fontSize="xs" color="gray.500">{customer.email}</Text>
                </Box>
                </Flex>
                <Box>
                    <List spacing={0} textAlign={'right'} fontSize={'md'}>
                    <ListItem>
                        <Text>Registro <Box as='span' color="teal.500">N° {data?.surveyResponseId}</Box></Text> 
                    </ListItem>
                    <ListItem>
                        Fecha de ingreso: <Box as='span' color="teal.500">{new Date(data?.created_at).toLocaleDateString()}</Box>
                    </ListItem>
                    <ListItem>
                        <Menu>
                        <MenuButton as={Button} p="0px" bg="transparent" variant="no-hover">
                            <Flex alignItems="center" p="12px">
                            <Text fontSize="md" color={textColor} fontWeight="bold">
                                Opciones
                            </Text>
                            <ChevronDownIcon ml="2px" />
                            </Flex>
                        </MenuButton>
                        <MenuList>
                            {/* <MenuItem onClick={() => actions.openModal(order, ["notify", "print"])}>Enviar Recordatorio</MenuItem> */}
                            <MenuItem onClick={ handleDeleteSurvey }>Eliminar formulario</MenuItem>
                        </MenuList>
                        </Menu>
                    </ListItem>
                    </List>
                </Box>
            </SimpleGrid>
                
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody px={'3rem'} pt={'1rem'} pb={'3rem'}>
            <Heading size="2xl" mb={'3rem'} textAlign={'center'}>{data?.survey?.name}</Heading>

            {sections && Object.values(sections).map((section, index) => (
                <Box key={index} mb={6}>
                    <Heading size="lg" mb={4} color={'gray.600'}>{section.name}</Heading>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        {section.questions
                            .filter(question => !question.text.includes('meta_data'))
                            .map((question, questionIndex) => (
                                <GridItem colSpan={question.column_width ?? 12} key={questionIndex} mb={6}>
                                    <Text fontSize="md" fontWeight="bold">{question.text}</Text>
                                    {/* Aquí se manejaría la lógica para mostrar la respuesta según el tipo de pregunta */}
                                    {question.type.name === 'text' ? (
                                        <Text fontSize="lg">{question?.response?.answer}</Text>
                                    ) : question.type.name === 'textarea' ? (
                                        <Text fontSize='lg'>
                                            {question?.response?.answer}
                                        </Text>
                                    ) : question.type.name === 'signature' ? (
                                        <>
                                            {question?.response?.answer && (
                                                <Image
                                                    src={API_URL_BASE + question?.response?.answer}
                                                    alt="signature"
                                                    style={{
                                                        display: "block",
                                                        margin: "0 auto",
                                                        width: "200px",
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : question.type.name === 'radio' ? (
                                        <>
                                            <RadioGroup defaultValue={question?.response?.answer || 'No answer'}>
                                                <Stack direction="row">
                                                    {question.response_choices && question.response_choices.length > 0 ? (
                                                        question.response_choices.map((option, optionIndex) => (
                                                            <Radio key={optionIndex} value={option.text} isReadOnly>
                                                                {option.text}
                                                            </Radio>
                                                        ))
                                                    ) : (
                                                        <Radio value="No answer">No options available</Radio>
                                                    )}
                                                </Stack>
                                            </RadioGroup>
                                        </>
                                    ) : question.type.name === 'file' ? (
                                        <Link href={`${API_URL_BASE}${question?.response?.answer}`} target='_blank'>{question?.response?.answer}</Link>
                                    ) : (
                                        <Text fontSize="lg">{question?.response?.answer}</Text>
                                    )}
                                </GridItem>
                            ))}
                    </Grid>
                </Box>
            ))}
            
            {/* Manejo de preguntas con 'meta_data' */}
            {data?.questions?.some(question => question.text.includes('meta_data')) && (
                <>
                    <Button mb={6} size="sm" onClick={handleToggle} mt="1rem">Mostrar {show ? "menos" : "más"}</Button>
                    <Collapse in={show}>
                        <Box mb={6} color={'gray.400'}>
                            {data.questions.filter(question => question.text.includes('meta_data')).map((question, index) => (
                                <Box mb={6} key={index}>
                                    <Text fontSize="md" fontWeight="bold">{question.text}</Text>
                                    {/* Mostrar respuesta para 'meta_data' */}
                                    <Text fontSize="lg">{question?.response?.answer}</Text>
                                </Box>
                            ))}
                        </Box>
                    </Collapse>
                </>
            )}

            <Button
              float="right"
              color="teal"
                variant="outline"
              onClick={() => window.open(`${API_URL}/generate-pdf/${data?.surveyResponseId}/survey-default`, '_blank')}
            >
              VER PDF
            </Button>

            </ModalBody>
            </>
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
            <ModalOverlay />
            <ModalContent>
                <SurveyResponseContent />
            </ModalContent>
        </Modal>
    );
};

export default ModalSurveyResponse;