// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Image,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState  } from "react";
import { API_URL } from 'services/surveyService';

const SurveyCard = ({ image, name, category, avatars, description, surveyResponse, onClick }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.500", "gray.300");
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const [profesor, setProfesor] = useState({});

  // Función para buscar el objeto de respuesta por el texto de la pregunta
  function findResponseByQuestionText(questionText) {
    return surveyResponse.responses.find(response => response.question.text === questionText);
  }

  useEffect(() => {
    console.log('surveyResponse ', surveyResponse);
    const response = findResponseByQuestionText("Profesor");
    console.log('response ', response);
    if (response) {
      setProfesor(response);
    }
  }, [surveyResponse]);

  return (
    <Flex direction='column' onClick={ onClick }>
      <Box mb='20px' position='relative' borderRadius='15px'>
        <Box pos={'relative'} style={{ zIndex: 1}} p="12px">
            <Tag fontSize='md' bg={'teal'} color={bgColor} fontWeight='600' my='12px'>#{surveyResponse.id}</Tag>
            <Text fontSize='md' color={textColor} fontWeight='600' my='12px' borderBottom={`1px solid`} borderColor={`${borderColor}`}>{name}</Text>
            {profesor?.answer && (
            <Text mb="22px" color={textColor} fontWeight='400' fontSize={'sm'}>{profesor?.question?.text}: {profesor?.answer}</Text>
            )}
            <Text fontSize='md' color={'gray.500'} fontWeight='400' my='12px'>{description}</Text>
        </Box>
        <Box
          w='100%'
          h='100%'
          position='absolute'
          top='0'
          borderRadius='15px'
          bg={bgColor}>
          </Box>
      </Box>
    </Flex>
  );
};

export default SurveyCard;
