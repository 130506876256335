import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { FaBell, FaCreditCard } from "react-icons/fa";
import LineChart from "components/Charts/LineChart";
import peopleImage from "assets/img/people-image.png";
import { useAppContext } from "../../../context/appContext";
import { withModuleCheck, useModuleCheck, MODULES } from "utils/site";
import {
  getProducts,
  getReportCustomers,
  getReportOrders,
  getOrderByStatus,
  getOrders,
  getTicketsSold,
} from "services/woo";
import { getAllSurveyResponses, getAllFiles } from "services/surveyService";
import EventDashboard from "./components/EventDashboard";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import SurveysOverview from "./components/SurveysOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import { getSites } from "../../../services/userService";
import { getSiteConfig, saveSiteConfig } from "../../../utils/site";
import DynamicPaginator from "../../../components/Tables/DynamicPaginator";

const ITEMS_PER_PAGE = 10;

// Funciones auxiliares - memoizadas fuera del componente
const iconSelector = (status) => ({
  "pending": FaBell,
  "completed": FaCreditCard,
  "failed": FaCreditCard,
}[status] || FaBell);

const colorSelector = (status) => ({
  "pending": "orange",
  "completed": "teal.300",
  "failed": "purple",
}[status] || "teal.300");

const titleSelector = (status, number) => ({
  "pending": `Nueva orden #${number}`,
  "completed": `Pago completado #${number}`,
  "failed": `Pago fallido #${number}`,
  "survey-response": `${number}`,
}[status] || "Nueva actividad");

function Dashboard() {
  // Estados
  const iconBoxInside = useColorModeValue("white", "white");
  const [reportSales, setReportSales] = useState({total_sales: ''});
  const [reportCustomers, setReportCustomers] = useState({});
  const [reportOrders, setReportOrders] = useState({});
  const [getTimeLine, setGetTimeLine] = useState([]);
  const [getDashboardTableData, setDashboardTableData] = useState([]);
  const [allSurveys, setAllSurveys] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [allTickets, setAllTickets] = useState({ total: 0 });
  const [error, setError] = useState(null);
  const { ordersGetParams } = useAppContext();

  // Estados y funciones para paginación y filtros
  const [totalOrders, setTotalOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalTickets, setTotalTickets] = useState(0);

  // En el estado inicial de filters
  const [filters, setFilters] = useState([
    {
      name: 'startDate',
      type: 'date',
      value: '',
      placeholder: 'Fecha desde'
    },
    {
      name: 'endDate',
      type: 'date', 
      value: '',
      placeholder: 'Fecha hasta'
    },
    {
      name: 'status',
      type: 'select',
      value: 'all',
      placeholder: false,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'completed', label: 'Pagado' },
        { value: 'used', label: 'Escaneado' },
        { value: 'pending', label: 'Pendiente' },
        { value: 'failed', label: 'Fallido' },
      ]
    }
   ]);

  const handleFilterChange = (name, value) => {
    setFilters(prev => 
      prev.map(f => f.name === name ? {...f, value} : f)
    );
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // En el componente Dashboard, añadir el estado
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page
    
    // Directly fetch with new parameters
    fetchOrders({
      per_page: newItemsPerPage,
      page: 1
    });
  }, [fetchOrders]);

  // Estado de módulos - memoizado
  const moduleStatus = useMemo(() => ({
    [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
    [MODULES.TICKETS]: useModuleCheck(MODULES.TICKETS),
    [MODULES.SURVEYS]: useModuleCheck(MODULES.SURVEYS),
    [MODULES.FILES]: useModuleCheck(MODULES.FILES)
  }), []);

  // Componentes con verificación de módulos - memoizados
  const TicketsComponent = useMemo(() => 
    withModuleCheck(EventDashboard, MODULES.TICKETS), 
    []
  );
  const SurveyComponent = useMemo(() => 
    withModuleCheck(ActiveUsers, MODULES.SURVEYS), 
    []
  );
  const SurveysOverviewComponent = useMemo(() => 
    withModuleCheck(SurveysOverview, MODULES.SURVEYS), 
    []
  );
  const ProjectsComponent = useMemo(() => 
    withModuleCheck(Projects, MODULES.PAYMENTS), 
    []
  );

  // Configuración de estadísticas por módulo - memoizada
  const moduleStats = useMemo(() => [
    {
      module: MODULES.PAYMENTS,
      stats: [
        {
          title: "Usuarios activos",
          amount: reportCustomers.customersTotal,
          icon: GlobeIcon,
          percentage: 0
        },
        {
          title: "Pagos completados",
          amount: reportOrders.ordersCompleted?.total + reportOrders.ordersUsed?.total || '',
          icon: CartIcon,
          percentage: 8
        },
        {
          title: "Pagos no completados",
          amount: reportOrders.ordersPending?.total,
          icon: DocumentIcon,
          percentage: -14
        }
      ]
    },
    {
      module: MODULES.SURVEYS,
      stats: [{
        title: "Encuestas",
        amount: allSurveys?.total,
        icon: DocumentIcon,
        percentage: 0
      }]
    },
    {
      module: MODULES.TICKETS,
      stats: [{
        title: "Total de Tickets",
        amount: totalTickets || '',
        icon: CartIcon,
        percentage: 0
      }]
    },
    {
      module: MODULES.FILES,
      stats: [{
        title: "Archivos",
        amount: allFiles?.data?.total,
        icon: DocumentIcon,
        percentage: 0
      }]
    }
  ], [reportCustomers.customersTotal, reportOrders.ordersCompleted?.total, 
      reportOrders.ordersPending?.total, allSurveys?.total, allTickets?.total, 
      allFiles?.data?.total]);

  // Funciones memoizadas
// In the fetchReports function, we'll modify it to only fetch data for active modules
const fetchReports = useCallback(async () => {
  const promises = [];
  const promiseResults = {};

  // Only add promises for active modules
  if (moduleStatus[MODULES.PAYMENTS]) {
    promises.push(
      getReportCustomers().then(data => {
        promiseResults.customers = data;
      }),
      getOrderByStatus().then(data => {
        promiseResults.orderStatus = data;
      }),
      getReportOrders().then(data => {
        promiseResults.orders = data;
      })
    );
  }

  if (moduleStatus[MODULES.TICKETS]) {
    promises.push(
      getTicketsSold().then(data => {
        promiseResults.tickets = data;
      })
    );
  }

  try {
    await Promise.all(promises);

    // Update states only if the corresponding data was fetched
    if (promiseResults.customers) {
      const customersPaying = promiseResults.customers.find(c => c.slug === 'paying');
      const customersNotPaying = promiseResults.customers.find(c => c.slug === 'non_paying');
      setReportCustomers({
        customersPaying,
        customersNotPaying,
        customersTotal: (customersPaying?.total || 0) + (customersNotPaying?.total || 0)
      });
    }

    if (promiseResults.orderStatus) {
      setReportSales({
        ...promiseResults.orderStatus,
        total_sales: '$' + (promiseResults.orderStatus['wc-completed']?.total || 0)
      });
    }

    if (promiseResults.orders) {
      setReportOrders({
        ordersPending: promiseResults.orders.find(o => o.slug === 'pending'),
        ordersCompleted: promiseResults.orders.find(o => o.slug === 'completed'),
        ordersUsed: promiseResults.orders.find(o => o.slug === 'used'),
      });
    }

    if (promiseResults.tickets) {
      setTotalTickets(promiseResults.tickets.totals.sold);
    }

  } catch (error) {
    console.error('Error fetching reports:', error);
    setError('Error al cargar los reportes');
  }
}, [moduleStatus]);

// Similarly, update the other fetch functions to check module status
const fetchSurveys = useCallback(async () => {
  if (!moduleStatus[MODULES.SURVEYS]) return;

  try {
    const surveyResponses = await getAllSurveyResponses();
    if (surveyResponses?.data?.data) {
      const surveyFormatedTimeLine = surveyResponses.data.data.map(response => ({
        logo: iconSelector(response.status),
        title: titleSelector('survey-response', `#${response.id} ${response.survey.name}`),
        date: response.created_at,
        color: colorSelector(response.status),
        type: 'survey',
        ...response
      }));
      setAllSurveys(surveyResponses.data);
      setGetTimeLine(surveyFormatedTimeLine);
    }
  } catch (error) {
    console.error('Error fetching surveys:', error);
    setError('Error al cargar las encuestas');
  }
}, [moduleStatus]);

// Group all initial data fetching in a single useEffect
useEffect(() => {
  const initializeDashboard = async () => {
    const initPromises = [];
    
    // Only add configuration promise if needed
    initPromises.push(setConfiguration());

    // Add other fetch promises based on module status
    if (moduleStatus[MODULES.PAYMENTS]) {
      initPromises.push(fetchReports());
      initPromises.push(fetchOrders(ordersGetParams));
    }
    
    if (moduleStatus[MODULES.SURVEYS]) {
      initPromises.push(fetchSurveys());
    }
    
    if (moduleStatus[MODULES.FILES]) {
      initPromises.push(fetchFiles());
    }

    try {
      await Promise.all(initPromises);
    } catch (error) {
      console.error('Error initializing dashboard:', error);
      setError('Error al inicializar el dashboard');
    }
  };

  initializeDashboard();
}, [moduleStatus, fetchReports, fetchOrders, fetchSurveys, fetchFiles, setConfiguration, ordersGetParams]);

// Remove individual useEffects since they're now handled in the combined initializeDashboard

  // Al inicio del componente, después de los estados
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchOrders = useCallback(async (params = {}) => {
    if (!moduleStatus[MODULES.PAYMENTS]) return;
    
    setIsLoading(true);
    try {
      const filterParams = {
        ...ordersGetParams, // Base params
        ...params, // Override with any passed params
        per_page: params.per_page || itemsPerPage, // Use passed per_page or current state
        page: params.page || currentPage,
      };
  
      // Add filters
      const statusFilter = filters.find(f => f.name === 'status')?.value;
      if (statusFilter && statusFilter !== 'all') {
        filterParams.status = statusFilter;
      }
      
      const startDate = filters.find(f => f.name === 'startDate')?.value;
      const endDate = filters.find(f => f.name === 'endDate')?.value;
      
      if (startDate) filterParams.after = startDate;
      if (endDate) filterParams.before = endDate;
      
      const valueRUT = filters.find(f => f.name === 'search')?.value;
      if (valueRUT) filterParams.search = valueRUT;
  
      const response = await getOrders(filterParams);
      const orderFormated = response.data.map(order => ({
        logo: DocumentIcon,
        username: order?.customer_info?.username,
        group: order?.customer_info.meta_data.find(m => m.key === 'group_name')?.value,
        name: `${order?.customer_info?.first_name} ${order?.customer_info?.last_name}`,
        mes: order?.meta_data.find(m => m.key === 'mes')?.value,
        ...order
      }));
  
      setDashboardTableData(orderFormated);
      setTotalOrders(response.total);
      setIsInitialLoad(false);
      
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Error al cargar las órdenes');
    } finally {
      setIsLoading(false);
    }
  }, [moduleStatus, currentPage, filters, itemsPerPage, ordersGetParams]);


  const fetchFiles = useCallback(async () => {
    if (!moduleStatus[MODULES.FILES]) return;

    try {
      const files = await getAllFiles();
      setAllFiles(files);
    } catch (error) {
      console.error('Error fetching files:', error);
      setError('Error al cargar los archivos');
    }
  }, [moduleStatus]);

  const setConfiguration = useCallback(async () => {
    try {
      const getConfig = getSiteConfig();
      const config = JSON.parse(getConfig);
      
      const fetchSites = await getSites();

      if (!fetchSites.success) {
        throw new Error(fetchSites.message);
      }

      if (!Array.isArray(fetchSites.data)) {
        throw new Error('La respuesta no contiene un array de sitios');
      }

      const currentSiteId = config?.id;
      const getCurrentSiteUpdated = fetchSites.data.find(item => item.id === currentSiteId);
      
      if (!getCurrentSiteUpdated) {
        throw new Error('No se encontró el sitio actual');
      }

      saveSiteConfig(JSON.stringify(getCurrentSiteUpdated));
    } catch (error) {
      console.error('Error en setConfiguration:', error);
      setError('Error al configurar el sitio');
    }
  }, []);

  // Efectos separados por responsabilidad
  useEffect(() => {
    setConfiguration();
  }, [setConfiguration]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  useEffect(() => {
    fetchOrders(ordersGetParams);
  }, [fetchOrders, ordersGetParams, currentPage, filters]);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

    // Modify the useEffect for orders to prevent double fetching
    useEffect(() => {
      if (!isInitialLoad) { // Only fetch if it's not the initial load
        fetchOrders();
      }
    }, [fetchOrders, currentPage, filters, isInitialLoad]);

  // Si hay error, mostrar mensaje
  if (error) {
    return (
      <Box p={4} bg="red.50" color="red.500" borderRadius="md">
        {error}
      </Box>
    );
  }

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      {/* MiniStats */}
      <Box mb={6}>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing='24px'>
          {moduleStats.map(({ module, stats }) => 
            moduleStatus[module] && stats.map((stat, index) => (
              <MiniStatistics
                key={`${module}-${index}`}
                title={stat.title}
                amount={stat.amount}
                percentage={stat.percentage}
                icon={<stat.icon h={"24px"} w={"24px"} color={iconBoxInside} />}
              />
            ))
          )}
        </SimpleGrid>
      </Box>
  
      {/* Banner Section */}
      {moduleStatus[MODULES.TICKETS] && (
        <Grid
          templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          mb='24px'
          gap='24px'>
          <BuiltByDevelopers
            title={"ticketa."}
            name={"ÚLTIMOS EVENTOS"}
            description={""}
            image={
              <Image
                src={'https://ticketa.cl/wp-content/uploads/2024/11/ticketa-logo-white.png'}
                alt='Imagen'
                minWidth={{ md: "300px", lg: "auto" }}
              />
            }
          />
          <WorkWithTheRockets
            backgroundImage={peopleImage}
            title={"Vende sin complicaciones"}
            description={"ticketa. - Vende entradas para tus próximos eventos, festivales y fiestas con tickets."}
          />
        </Grid>
      )}
  
      {/* Primera fila: Tickets y Recaudación */}
      <Grid
        templateColumns={{ 
          sm: "1fr", 
          lg: moduleStatus[MODULES.TICKETS] ? "1.5fr 1fr" : "1fr"
        }}
        gap='24px'
        mb='24px'>
        {moduleStatus[MODULES.TICKETS] ? (
          <>
            <Flex direction="column" gap='24px'>
              <TicketsComponent categoryId={'tickets'} />
            </Flex>
            <Flex direction="column" gap='24px'>
              {moduleStatus[MODULES.PAYMENTS] && (
                <SalesOverview
                  title={"Recaudación"}
                  percentage={5}
                  chart={<LineChart />}
                />
              )}
              {moduleStatus[MODULES.SURVEYS] && (
                <SurveyComponent 
                  title={"Estadisticas de los formularios"} 
                  percentage={23} 
                />
              )}
            </Flex>
          </>
        ) : (
          <Flex direction="column" gap='24px'>
            {moduleStatus[MODULES.PAYMENTS] && (
              <SalesOverview
                title={"Recaudación"}
                percentage={5}
                chart={<LineChart />}
              />
            )}
            {moduleStatus[MODULES.SURVEYS] && (
              <SurveyComponent 
                title={"Estadisticas de los formularios"} 
                percentage={23} 
              />
            )}
          </Flex>
        )}
      </Grid>
  
      {/* Segunda fila: Resumen de pagos y Últimos formularios */}
      <Grid
        templateColumns={{ 
          sm: "1fr", 
          lg: (moduleStatus[MODULES.PAYMENTS] && moduleStatus[MODULES.SURVEYS]) ? "2fr 1fr" : "1fr" 
        }}
        gap='24px'
        mb='24px'>
        {moduleStatus[MODULES.PAYMENTS] && (
          <DynamicPaginator
            filters={filters}
            onFilterChange={handleFilterChange}
            totalItems={totalOrders}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            isLoading={isLoading}
          >
            <ProjectsComponent
              title={"Resumen de pagos"}
              captions={["Nombre", "Pago", "Estado"]}
              data={getDashboardTableData}
              isLoading={isLoading}
            />
          </DynamicPaginator>
        )}
        {moduleStatus[MODULES.SURVEYS] && (
          <SurveysOverviewComponent
            title={"Últimos formularios"}
            amount={allSurveys?.total || 0}
            data={getTimeLine}
          />
        )}
      </Grid>
    </Flex>
  );
}

// Envolvemos el componente en React.memo para evitar re-renders innecesarios
export default React.memo(Dashboard, (prevProps, nextProps) => {
  // Aquí podríamos añadir lógica de comparación personalizada si fuera necesario
  return true; // Por defecto, solo se actualizará si las props cambian
});