import {
  Box,
  Button,
  FormControl,
  Input,
  Text,
  useColorModeValue,
  Badge,
  useToast,
  Progress,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { getCustomer, getProducts, createOrderCuota  } from "../../../../services/woo";

function BatchUsersOrderCreate({ title, customers, onOrderCreated }) {
  const toast = useToast();
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const [month, setMonth] = useState("2023-03");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failedOrders, setFailedOrders] = useState([]);
  const [ordersCreated, setOrdersCreated] = useState([]);

  const fechaVencimiento = new Date(`${month}-01`);
  fechaVencimiento.setDate(fechaVencimiento.getDate() + 15);
  const fechaVencimientoString = fechaVencimiento.toISOString().split('T')[0];

  const createOrderSuscription = async () => {
    setLoading(true);
    setProgress(0);
    setFailedOrders([]);
    const products = await getProducts();
    const totalCustomers = customers.length;

    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      try {
        const getCustomerInfo = await getCustomer(customer.id);
        const { meta_data } = getCustomerInfo.data;
        const customer_suscription = _.find(meta_data, { key: 'suscription_name' })?.value;
        const product_found = _.find(products, { name: customer_suscription });

        if (!customer_suscription || !product_found) {
          setFailedOrders(prev => [...prev, {
            customer: customer,
            error: !customer_suscription ? "No tiene suscripción" : "Plan no existe"
          }]);
          continue;
        }

        const meta_data_order = [
          { key: "mes", value: month },
          { key: 'concepto', value: customer_suscription },
          { key: 'monto', value: product_found?.price },
          { key: "fecha_vencimiento", value: fechaVencimientoString },
          { key: 'usuario', value: customer.username},
          { key: 'email', value: customer.email},
          { key: 'order_type', value: 'subscription_payment' },
          { key: 'order_for', value: 'single'},
          { key: 'order_from', value: 'pagoexpress_client'},
        ];

        const orderCreated = await createOrderCuota(customer, product_found, meta_data_order);

        if (orderCreated) {
          setOrdersCreated(prev => [...prev, orderCreated.create]);
        }
      } catch (error) {
        setFailedOrders(prev => [...prev, {
          customer: customer,
          error: "Error al crear orden"
        }]);
      }
      
      setProgress(((i + 1) / totalCustomers) * 100);
    }
    
    setLoading(false);
    // onOrderCreated();
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} border={{ xl: "1px"}} mb="6" bg={bgColor}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold' textAlign='center' mb='22px'>
          {title || 'Selecciona una mensualidad para cobrar'}
        </Text>
      </CardHeader>
      <CardBody>
        <Box w={'100%'}>
          {customers.length === 1 ? (
            <Box width="100%" mb={6}>
              <Text fontSize={'2xl'}>{customers[0]?.first_name} {customers[0]?.last_name}</Text>
              <Text fontSize={'xl'}>{customers[0]?.email}</Text>
              <Text><strong>Tipo:</strong> Mensualidad</Text>
              <Text><strong>Concepto:</strong> {_.find(customers[0].meta_data, { key: 'suscription_name' })?.value}</Text>
            </Box>
          ) : (
            <Text fontSize={'2xl'} width="100%">
              <Badge fontSize='0.8em' colorScheme='teal'>
                {customers.length} usuarios seleccionados
              </Badge>
            </Text>
          )}

          <Box w={'100%'}>
            <Text><strong>Mes:</strong> {month}</Text>
            <Text><strong>Fecha de vencimiento:</strong> {fechaVencimientoString}</Text>
          </Box>

          <FormControl style={{marginTop: '1rem'}}>
            <Input
              fontSize='sm'
              borderRadius='15px'
              type='month'
              placeholder='month'
              mb='24px'
              size='lg'
              onChange={(e) => setMonth(e.target.value)}
            />
            
            {loading && (
              <Box mb="4">
                <Progress hasStripe value={progress} colorScheme="teal" mb="2" />
                <Text textAlign="center" fontSize="sm">
                  Procesando {Math.round(progress)}%
                </Text>
              </Box>
            )}

            {failedOrders.length > 0 && (
              <Alert status="error" mb="4" borderRadius="md">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Errores en la generación</AlertTitle>
                  <AlertDescription display="block">
                    {failedOrders.map((fail, index) => (
                      <Text key={index} fontSize="sm">
                        {fail.customer.first_name} {fail.customer.last_name}: {fail.error}
                      </Text>
                    ))}
                  </AlertDescription>
                </Box>
                <CloseButton 
                  position="absolute" 
                  right="8px" 
                  top="8px"
                  onClick={() => setFailedOrders([])} 
                />
              </Alert>
            )}

            <Button
              type='submit'
              bg='teal.300'
              fontSize='14px'
              color='white'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => createOrderSuscription()}
              isLoading={loading}
            >
              GENERAR COBRO
            </Button>
          </FormControl>
        </Box>
      </CardBody>
    </Card>
  );
}

export default BatchUsersOrderCreate;