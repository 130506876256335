import React from 'react';
import Dashboard from "views/Dashboard/Dashboard";
import Event from "views/Dashboard/Event";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import EditOrder from "views/Dashboard/EditOrder";
import Suscriptions from "views/Dashboard/Suscriptions";
import CheckoutCustomOrder from "views/Dashboard/Suscriptions/components/CheckoutCustomOrder";
import CheckoutCustomOrderProduct from "views/Dashboard/Suscriptions/components/CheckoutCustomOrderProduct";
import PointOfSale from "views/Dashboard/Suscriptions/components/PointOfSale";
import Events from "views/Dashboard/Events";
import { useModuleCheck, MODULES } from 'utils/site';
import {
  HomeIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
} from "components/Icons/Icons";

// Devolver directamente el array de rutas sin usar un componente
const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  ...(useModuleCheck(MODULES.TICKETS) ? [
    {
      path: "/eventos",
      name: "Eventos",
      rtlName: "لوحة القيادة",
      icon: <CreditIcon color="inherit" />,
      component: Events,
      layout: "/admin",
    },
    {
      path: "/evento/:id",
      name: "Evento",
      rtlName: "لوحة القيادة",
      icon: <CreditIcon color="inherit" />,
      secondaryNavbar: true,
      component: Event,
      layout: "/admin",
      redirect: true,
    }
  ] : []),
  {
    path: "/usuarios",
    name: "Usuarios",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    component: Suscriptions,
    layout: "/admin",
  },
  {
    path: "/usuario/:id",
    name: "Perfil",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/import",
    name: "Importar",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: EditOrder,
    layout: "/admin",
  },
  {
    name: "Cliente",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    redirect: true,
    views: [
      {
        path: "/signin",
        name: "Iniciar Sesión",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Registrarse",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
      {
        path: "/checkout",
        name: "Inicializar Pago",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        secondaryNavbar: true,
        component: CheckoutCustomOrder,
        layout: "/checkout",
      },
      {
        path: "/:siteId/:productId",
        name: "Plan de Suscripción",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: CheckoutCustomOrderProduct,
        layout: "/checkout",
        redirect: true,
      },
      {
        path: "/:siteId",
        name: "Plan de Suscripción",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: PointOfSale,
        layout: "/checkout",
        redirect: true,
      },
    ],
  },
];

export default dashRoutes;