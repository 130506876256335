import React, { useState } from 'react';
import {
  Box,
  Flex,
  Select,
  Input,
  Button,
  Stack,
  Text,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  IconButton,
  useBreakpointValue,
  VStack,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  useColorModeValue,
  Icon,
  Badge,
  Divider
} from '@chakra-ui/react';
import { 
  ChevronLeftIcon, 
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  CheckIcon, 
  FilterIcon
} from '@chakra-ui/icons';
import { FaFilter } from 'react-icons/fa';
import { format } from 'rut.js';

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

const DynamicPaginator = ({
  children,
  filters,
  onFilterChange,
  totalItems,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
  onItemsPerPageChange,
  isLoading,
  onSort,
  sortConfig,
  onSelectionChange
}) => {
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [tempValue, setTempValue] = useState('');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Tamaños personalizados para inputs y controles
  const inputSize = useBreakpointValue({ base: "lg", md: "md" });
  const buttonSize = useBreakpointValue({ base: "md", md: "sm" });
  const spacing = useBreakpointValue({ base: 4, md: 2 });
  
  // Estilos para inputs y selects
  const inputStyles = {
    bg: useColorModeValue('white', 'gray.700'),
    borderWidth: "1px",
    borderColor: useColorModeValue('gray.200', 'gray.600'),
    _hover: {
      borderColor: useColorModeValue('gray.300', 'gray.500'),
    },
    _focus: {
      borderColor: "teal.500",
      boxShadow: "0 0 0 1px var(--chakra-colors-teal-500)",
    },
    fontSize: { base: "md", md: "sm" },
    width: { base: "full", md: "auto" },
    minW: { md: "200px" }
  };

  const renderFilters = () => {
    return filters.map((filter) => {
      switch (filter.type) {
        case 'select':
          return (
            <Box key={filter.name} flex={isMobile ? "1" : "0 1 auto"} minW={{ md: "200px" }}>
              <Select
                size={inputSize}
                placeholder={filter.placeholder}
                value={filter.value}
                onChange={(e) => onFilterChange(filter.name, e.target.value)}
                {...inputStyles}
              >
                {filter.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          );
        case 'searchRaw':
          return (
            <Box key={filter.name} flex={isMobile ? "1" : "0 1 auto"} minW={{ md: "250px" }}>
              <Input
                size={inputSize}
                type="search" 
                placeholder={filter.placeholder}
                value={tempValue || filter.value}
                onChange={(e) => setTempValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onFilterChange(filter.name, tempValue);
                    setTempValue('');
                  }
                }}
                {...inputStyles}
              />
            </Box>
          );
          case 'checkbox':
            return (
              <Box key={filter.name} flex={isMobile ? "1" : "0 1 auto"} minW={{ md: "200px" }}>
                <Menu closeOnSelect={false}>
                  <MenuButton 
                    as={Button} 
                    rightIcon={<ChevronDownIcon />}
                    leftIcon={<Icon as={FaFilter} />}
                    variant="outline"
                    size={inputSize}
                    {...inputStyles}
                  >
                    {filter.placeholder}
                    {filter.value.length > 0 && (
                      <Badge ml={2} colorScheme="teal" borderRadius="full">
                        {filter.value.length}
                      </Badge>
                    )}
                  </MenuButton>
                  <MenuList p={2} maxH="300px" overflowY="auto">
                    <Text fontWeight="medium" mb={2} px={2}>
                      {filter.placeholder}
                    </Text>
                    <Divider mb={2} />
                    <VStack align="start" spacing={1}>
                      {filter.options.map((option) => (
                        <Checkbox
                          key={option.value}
                          isChecked={filter.value.includes(option.value)}
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? [...filter.value, option.value]
                              : filter.value.filter(v => v !== option.value);
                            onFilterChange(filter.name, newValue);
                          }}
                          size="sm"
                          width="100%"
                          px={2}
                          py={1}
                          _hover={{ bg: hoverBg }}
                          borderRadius="md"
                        >
                          <Flex justify="space-between" width="100%" align="center">
                            <Text fontSize="sm">
                              {option.label}
                            </Text>
                            {filter.value.includes(option.value) && (
                              <Icon as={CheckIcon} color="teal.500" boxSize={3} />
                            )}
                          </Flex>
                        </Checkbox>
                      ))}
                      {filter.options.length === 0 && (
                        <Text fontSize="sm" color="gray.500" p={2}>
                          No hay opciones disponibles
                        </Text>
                      )}
                    </VStack>
                    {filter.value.length > 0 && (
                      <>
                        <Divider my={2} />
                        <Button 
                          size="xs" 
                          variant="outline" 
                          width="100%" 
                          onClick={() => onFilterChange(filter.name, [])}
                        >
                          Limpiar selección
                        </Button>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            );
      }
    });
  };

  const PaginationControls = () => {
    return (
      <Flex 
        direction={{ base: "column", md: "row" }}
        justify="space-between" 
        align="stretch"
        gap={4}
        mt={6}
        mb={4}
      >
        {/* Selector y contador */}
        <HStack 
          spacing={4} 
          align="center"
          justify={{ base: "space-between", md: "flex-start" }}
          w="full"
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="outline"
              size="sm"
              minW={{ base: "120px", md: "110px" }}
              textAlign="left"
            >
              {itemsPerPage} / página
            </MenuButton>
            <MenuList>
              {ITEMS_PER_PAGE_OPTIONS.map(option => (
                <MenuItem 
                  key={option}
                  onClick={() => onItemsPerPageChange(option)}
                >
                  {option} / página
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          
          <Text fontSize="sm" color="gray.500">
            {(currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, totalItems)} de {totalItems}
          </Text>
        </HStack>
          
        {/* Navegación */}
        <HStack spacing={1} justify="center">
          <IconButton
            size="sm"
            variant="ghost"
            icon={<ArrowLeftIcon h={3} w={3} />}
            onClick={() => onPageChange(1)}
            isDisabled={currentPage === 1 || isLoading}
            aria-label="Primera página"
          />
          <IconButton
            size="sm"
            variant="ghost"
            icon={<ChevronLeftIcon h={4} w={4} />}
            onClick={() => onPageChange(currentPage - 1)}
            isDisabled={currentPage === 1 || isLoading}
            aria-label="Anterior"
          />
          
          <Text fontSize="sm" px={2}>
            {currentPage}/{totalPages}
          </Text>
          
          <IconButton
            size="sm"
            variant="ghost"
            icon={<ChevronRightIcon h={4} w={4} />}
            onClick={() => onPageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages || isLoading}
            aria-label="Siguiente"
          />
          <IconButton
            size="sm"
            variant="ghost"
            icon={<ArrowRightIcon h={3} w={3} />}
            onClick={() => onPageChange(totalPages)}
            isDisabled={currentPage === totalPages || isLoading}
            aria-label="Última página"
          />
        </HStack>
      </Flex>
    );
  };

  // Renderizado principal
  return (
    <Box>
      <Stack spacing={2}>
        <Flex 
          direction={{ base: "column", md: "row" }}
          gap={spacing}
          wrap="wrap"
          mb={2}
        >
          {filters && filters.length > 0 && renderFilters()}
        </Flex>
        
        {children}

        {totalItems > 0 && <PaginationControls />}
      </Stack>
    </Box>
  );
};

export default DynamicPaginator;