import * as React from 'react';
import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';
import axios from 'axios';
import { getToken } from "../utils/auth"

// Función para obtener y guardar las credenciales en localStorage
// TODO: Obtener credenciales actualizadas con fetch
export const getCredentials = () => {
  let credentials = {};
  
  // Recuperar credenciales desde localStorage
  const siteConfig = localStorage.getItem('site_config');
  const token = getToken()
  
  // Guardar credenciales en objeto
  credentials.siteConfig = siteConfig && JSON.parse(siteConfig).configs;
  credentials.token = token;
  
  return credentials;
};

export const getConfig = (credentials, keyToFind) => {
  const founded = credentials?.siteConfig?.filter(item => item.key === keyToFind)
  return founded && founded.length > 0 ? founded[0].value : null;
}

function createWooCommerceInstance() {
  const credentials = getCredentials();

  return new WooCommerceRestApi({
    url: getConfig(credentials, "WOO_API_URL") || "https://capitalweb.cl",
    consumerKey: getConfig(credentials, "WOO_API_CONSUMER_KEY") || 'ck_624f8dc4ee963d1b22ee25f4c0c0d7af969f50e2',
    consumerSecret: getConfig(credentials, "WOO_API_CONSUMER_SECRET") || 'cs_f3380852908501a7bc820e789ca12c433430069f',
    version: 'wc/v3',
    queryStringAuth: true,
    axiosConfig: {
      transformRequest: [(data, headers) => {
        // Eliminar el User-Agent de los headers
        delete headers['User-Agent'];
        return data;
      }]
    }
  });
}


function createWordPressAPIInstance() {
  const credentials = getCredentials();

  const instance = axios.create({
    baseURL: getConfig(credentials, "WP_API_URL") || "https://pagos.blaspascal.cl/wp-json",
    headers: {
      // 'Authorization': `Basic ${getConfig(credentials, "WP_API_AUTH_BASIC") || 'alee:acuario0287'}`
    }
  });

  return instance;
}

export async function getUser(userId) {
  // Crea una nueva instancia de la API de WordPress
  const api = createWordPressAPIInstance();

  try {
    // Haz una solicitud GET al endpoint de usuarios
    const response = await api.get(`/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getUsers() {
  // Crea una nueva instancia de la API de WordPress
  const api = createWordPressAPIInstance();

  try {
    // Haz una solicitud GET al endpoint de usuarios
    const response = await api.get('/users');
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

// MARK: ORDER + QR
export const createCustomPayment = async (data) => {
  const credentials = getCredentials();
  const url = getConfig(credentials, "WOO_API_CUSTOM_PAYMENT_URL") || "https://pagos.capitalweb.cl/wp-json/custom-woocommerce-payment/v1/generar-enlace-pago"
  try {
    const response = await axios.get(url, { 
      params: {
        monto: data.monto,
        detalle: data.detalle,
        cliente: data.cliente,
        email: data.email,
        username: data.username,
      }
    });
    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Pago personalizado creado exitosamente",
    };
  } catch (error) {
    const status = error.response?.status || 500;
    const data = error.response?.data || null;
    const message = error.response?.data?.message || error.message || "Error al crear el pago personalizado";
    console.log(status, data, message);
    return {
      success: false,
      status,
      data,
      message,
    };
  }
}

export const getCustomer = async (customer_id) => {
  const api = createWooCommerceInstance()
  try {
    const response = api.put('customers/' + customer_id);
    return response;
  } catch (error) {
    console.log(error);
  }
}

// export const findCustomerByEmail = async (email) => {
//   const api = createWooCommerceInstance()
//   return api.get('customers', {
//     email: email,
//   }).then((response) => {
//     return response.data[0];
//   })
// }

// export const updateCustomer = async (customer_id, data) => {
//   const api = createWooCommerceInstance()
//   try {
//     const response = api.put('customers/' + customer_id, data);
//     return response;
//   } catch (error) {
//     console.log(error);
//   }
// }

// export const createCustomer = async (data) => {
//   const api = createWooCommerceInstance()
//   try {
//     const response = await api.post("customers", data);
//     return {
//       success: true,
//       status: response.status,
//       data: response.data,
//       message: "Usuario creado exitosamente",
//     };
//   } catch (error) {
//     const status = error.response?.status || 500;
//     const data = error.response?.data || null;
//     const message = error.response?.data?.message || error.message || "Error al crear el cliente";
//     console.log(status, data, message);
//     return {
//       success: false,
//       status,
//       data,
//       message,
//     };
//   }
// };

export const listOrdersCustomer = (customer) => {
  const api = createWooCommerceInstance()
  return api
    .get('orders', {
      per_page: 20, // 20 products per page
      customer: customer.id,
    })
    .then((response) => {
      // Successful request
      // console.log('Response Data:', response.data);
      // console.log('Total of pages:', response.headers['x-wp-totalpages']);
      // console.log('Total of items:', response.headers['x-wp-total']);
      return response.data;
    })
    .catch((error) => {
      // Invalid request, for 4xx and 5xx statuses
      console.log('Response Data:', error.response.data);
    })
};

export const getOrdersCustomer = async (customer) => {
  const api = createWooCommerceInstance()
  return api.get('orders', {
    customer: customer.id
  });
}

// MARK: ORDERS BATCH
const createMultipleOrders = async (deudas) => {
  const api = createWooCommerceInstance()
  console.log('Creating Multiple Orders', deudas);

  const orders = { create: deudas };

  return api
    .post('orders/batch', orders)
    .then((response) => {
      console.log('Created Multiple Orders', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error.response.data);
    });
};

// Para un cliente seleccionado, asignar PLAN por pagar
export const createOrderCuota = async (customer, product, meta_data) => {
  const filtered_meta_data = meta_data.filter(item => item.key === "mes" || item.key === "concepto" || item.key === "monto" || item.key === "fecha_vencimiento" || item.key === "usuario" || item.key === "email");

  // Revisando estado del cliente, si entre sus roles tiene el de 'customer' continuamos con la creación de la orden
  //const customer_roles = await getUser(customer.id).roles; // From WordPress API
  // const isCustomer = customer_roles.includes('customer');
  console.log('Customer Role:', customer);
  const customer_role = customer.role; // From WooCommerce API
  const isCustomer = customer_role === 'customer';
  console.log('Customer Role:', customer_role);
  if (!isCustomer) {
    console.log('El usuario no es cliente');
    return;
  }
  const data = [
    {
      line_items: [{ product_id: product.id, quantity: 1, meta_data: filtered_meta_data }],
      customer_id: customer.id,
      meta_data: meta_data
    },
  ];

  const ordersCreated = await createMultipleOrders(data);
  console.log(ordersCreated)
  return ordersCreated;
}

export const deleteOrder = async (order_id) => {
  const api = createWooCommerceInstance()
  return api
    .delete('orders/' + order_id)
    .then((response) => {
      console.log('Remove Order ' + order_id, response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error.response.data);
    });
}


export const getProducts = () => {

  const api = createWooCommerceInstance()
  return api
    .get('products', {
      per_page: 20, // 20 products per page
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.response.data);
      return error.response.data;
    });
};

export const getProduct = (productId) => {
  const api = createWooCommerceInstance()
  return api
    .get('products/' + productId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.response.data);
    });
}

export const getMetaValue = (meta_data, key) => {
  const meta = meta_data?.find(item => item.key === key);
  return meta?.value || null;
};


export const getCustomers = async (
  suscription_name, 
  group_name, 
  search, 
  startDate, 
  endDate, 
  role, 
  page = 1, perPage = 5,
  surveys_responded = "",    // NUEVO - Añade este parámetro
  include_surveys           // NUEVO - Añade este parámetro
) => {
  const api = createWordPressAPIInstance();
  
  console.log('Service called with role:', role);
  
  const params = {
    per_page: perPage,
    page: page,
  };

  // Añadir filtros solo si tienen valor
  if (suscription_name) params.suscription_name = suscription_name;
  if (group_name) params.group_name = group_name;
  if (search) params.search = search;
  if (startDate) params.after = `${startDate}T00:00:00`;
  if (endDate) params.before = `${endDate}T23:59:59`;
  
  // NUEVO - Añade estas dos líneas
  if (surveys_responded) params.surveys_responded = surveys_responded;
  if (include_surveys) params.include_surveys = include_surveys;
  
  // Manejar el rol específicamente
  if (role && role !== '') {
    params.role = role;
  } else {
    console.log('Requesting all roles (customer and subscriber)');
  }

  console.log('Request params:', params);

  try {
    const response = await api.get('/datofy/v1/users', { params });
    console.log('Service response:', response);
    
    return {
      data: response.data,
      headers: {
        'x-wp-total': response.headers?.['x-wp-total'],
        'x-wp-totalpages': response.headers?.['x-wp-totalpages']
      }
    };
  } catch (error) {
    console.error('Service error:', error);
    throw error;
  }
};




export const getIncludeCustomers = (customerIds) => {
  const api = createWooCommerceInstance()
  return api.get('customers', {
    include: customerIds,
  }).then((response) => {
    console.log(response)
    return response.data
  })
}

// Reportes
export const getReportSales = (params) => {
  const api = createWooCommerceInstance()
  return api.get('reports/sales', params).then((response) => {
    // console.log('reports/sales', response.data[0]);
    return response.data[0];
  })
}

export const getReportCustomers = (params) => {
  const api = createWooCommerceInstance()
  return api.get('reports/customers/totals', params).then((response) => {
    // console.log('reports/customers/totals', response.data);
    return response.data;
  })
}

export const getReportOrders = () => {
  const api = createWooCommerceInstance()
  return api.get('reports/orders/totals').then((response) => {
    // console.log('reports/orders/totals', response.data);
    return response.data;
  })
}

export const getOrders = (params) => {
  const api = createWooCommerceInstance();
  
  // Asegurarse de que per_page esté presente y sea un número
  const sanitizedParams = {
    ...params,
    per_page: parseInt(params.per_page) || 10
  };

  return api.get('orders', sanitizedParams)
    .then((response) => {
      const total = parseInt(response.headers['x-wp-total'] || response.headers['X-WP-Total'] || '0');
      const totalPages = parseInt(response.headers['x-wp-totalpages'] || response.headers['X-WP-TotalPages'] || '1');
      
      return {
        data: Array.isArray(response.data) ? response.data : [],
        total: total || 0,
        totalPages: totalPages || 1
      };
    })
    .catch(error => {
      console.error('Error in getOrders:', error);
      throw error;
    });
};

export const getSummaryOrders = (status, start_date, end_date) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/orders/summary?status=${status}&start_date=${start_date}&end_date=${end_date}`).then((response) => {
    return response.data
  })
}

export const getSummaryOrdersByMonth = (status, start_date, end_date) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/orders-stats-by-month?status=${status}&start_date=${start_date}&end_date=${end_date}`).then((response) => {
    return response.data
  })
}

export const getLastOrders = () => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/last-orders`).then((response) => {
    return response.data
  })
}

export const getLastCustomers = (number_of_results) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/customers/${number_of_results}`).then((response) => {
    return response.data
  })
}

export const getProductsByCategories = (categories) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/products?categories=${categories}`).then((response) => {
    return response.data
  })
}

export const getTicketsByCategories = (categories) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/tickets?categories=${categories}&show_all_variants=true`).then((response) => {
    return response.data
  })
}


export const getOrderByStatus = () => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/orders/status`).then((response) => {
    return response.data
  })
}

export const getLastOrdersByDay = (lastDays) => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/paid-orders-by-day/${lastDays}`).then((response) => {
    return response.data
  })
}

export const getOrderDetails = async (order_id) => {
  const api = createWooCommerceInstance();
  try {
    const response = await api.get(`orders/${order_id}`);
    const orderDetails = response.data;

    return {
      success: true,
      data: orderDetails
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error retrieving order details",
    };
  }
};

export const modifyOrder = async (order_id, metadataArray, orderData) => {
  const api = createWooCommerceInstance();
  try {
    // Update the metadata
    const updatedMetadata = metadataArray.map((metadata) => {
      const { key, value } = metadata;
      return { key, value };
    });

    // Update other order data if provided
    const updatedOrderData = orderData ? { ...orderData } : {};

    // Save the modified order
    await api.put(`orders/${order_id}`, { meta_data: updatedMetadata, ...updatedOrderData });

    return {
      success: true,
      message: "Order modified successfully",
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error modifying order",
    };
  }
};

export const sendReminder = async (order_id) => {
  const credentials = getCredentials();
  const url = getConfig(credentials, "WOO_API_URL") + `/wp-json/wc/v3/send_reminder`;
  try {
    const response = await axios.get(url, {
      params: {
        order_id: order_id
      }
    });
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error sending reminder"
    };
  }
};

export const paymentTransbank = async (order_id, site_url, redirect_url) => {
  const url = `${site_url}/wp-json/datofy/v1/pago_transbank/${order_id}?redirect_url=${encodeURIComponent(redirect_url)}`;
  console.log(redirect_url)
  try {
    const response = await axios.get(url);
    console.log(response);
    return {
      success: response.data.result === 'success',
      data: { url: response.data.redirect_url }
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error with payment Transbank"
    };
  }
};

// Servicio para obtener las notas de una orden
export const getOrderNotes = async (order_id) => {
  const api = createWooCommerceInstance();
  try {
    const response = await api.get(`orders/${order_id}/notes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order notes:', error);
    throw error;
  }
};

/*
  Explicación:
  - `createWooCommerceInstance()`: Se asume que esta función crea una instancia de la API de WooCommerce, configurada con las credenciales y headers adecuados.
  - `api.get('orders/${order_id}/notes')`: Llamada a la API de WooCommerce para obtener las notas asociadas al pedido especificado por `order_id`.
  - Se devuelve `response.data` que debe contener la lista de notas de la orden.
*/



// Constantes para configuración
const CONFIG = {
  PAGINATION: {
    PER_PAGE: 100,
    INITIAL_PAGE: 1
  },
  ORDER_STATUS: {
    COMPLETED: 'completed',
    PROCESSING: 'processing',
    CANCELLED: 'cancelled'
  }
};

// Función para obtener órdenes
export const getOrdersWithStats = async (params, productId) => {
  let allOrders = [];
  let page = CONFIG.PAGINATION.INITIAL_PAGE;

  try {
    while (true) {
      const updatedParams = {
        ...params,
        status: params.status || ['completed', 'used'],
        per_page: CONFIG.PAGINATION.PER_PAGE,
        page
      };

      const orders = await getOrders(updatedParams);
      
      if (!orders.data?.length) break;
      
      allOrders = [...allOrders, ...orders.data];
      page++;
    }

    return allOrders.filter(order =>
      order.line_items?.some(item => item.product_id === productId)
    );
  } catch (error) {
    console.error("Error fetching orders:", error);
    return [];
  }
};

// Hook personalizado para las estadísticas
export const useTicketStats = (orders, productId) => {
  const [stats, setStats] = React.useState({
    totalTicketsSold: 0,
    totalIncome: 0,
    ticketsByType: {},
    uniqueCustomers: new Set(),
    salesByDay: {},
    salesByMethod: {},
    salesBySource: {},
    customerSegments: {
      new: 0,
      returning: 0
    }
  });

  React.useEffect(() => {
    const calculateStats = () => {
      const newStats = {
        totalTicketsSold: 0,
        totalIncome: 0,
        ticketsByType: {},
        uniqueCustomers: new Set(),
        salesByDay: {},
        salesByMethod: {},
        salesBySource: {},
        customerSegments: {
          new: 0,
          returning: 0
        }
      };

      orders.forEach(order => {
        order.line_items?.forEach(item => {
          if (item.product_id === productId) {
            // Estadísticas básicas
            newStats.totalTicketsSold += item.quantity;
            newStats.totalIncome += parseFloat(item.total);

            // Tickets por tipo
            const variation = item.variation_id || "General";
            newStats.ticketsByType[variation] = 
              (newStats.ticketsByType[variation] || 0) + item.quantity;

            // Clientes únicos
            if (order.customer_id) {
              newStats.uniqueCustomers.add(order.customer_id);
            }

            // Ventas por día
            const date = new Date(order.date_created).toISOString().split('T')[0];
            newStats.salesByDay[date] = 
              (newStats.salesByDay[date] || 0) + item.quantity;

            // Método de pago
            const paymentMethod = order.payment_method_title || 'unknown';
            newStats.salesByMethod[paymentMethod] = 
              (newStats.salesByMethod[paymentMethod] || 0) + item.quantity;

            // Fuente de la venta
            const source = order.created_via || 'unknown';
            newStats.salesBySource[source] = 
              (newStats.salesBySource[source] || 0) + item.quantity;

            // Segmentación de clientes
            const isNew = order.customer_id === 0 || !order.customer_id;
            if (isNew) {
              newStats.customerSegments.new++;
            } else {
              newStats.customerSegments.returning++;
            }
          }
        });
      });

      // Calcular estadísticas derivadas
      newStats.averageTicketPrice = newStats.totalIncome / newStats.totalTicketsSold || 0;
      newStats.totalUniqueCustomers = newStats.uniqueCustomers.size;
      
      const sortedDays = Object.entries(newStats.salesByDay)
        .sort((a, b) => b[1] - a[1]);
      newStats.salesDayWithMax = sortedDays[0];

      setStats(newStats);
    };

    calculateStats();
  }, [orders, productId]);

  return stats;
};


// Función para exportar datos a CSV
export const generateStatsCSV = (stats) => {
  const csvData = [
    ['Métrica', 'Valor'],
    ['Total Tickets Vendidos', stats.totalTicketsSold],
    ['Ingresos Totales', stats.totalIncome],
    ['Precio Medio', stats.averageTicketPrice],
    ['Clientes Únicos', stats.totalUniqueCustomers],
    ['Clientes Nuevos', stats.customerSegments.new],
    ['Clientes Recurrentes', stats.customerSegments.returning]
  ];

  // Agregar fuentes de venta
  Object.entries(stats.salesBySource).forEach(([source, count]) => {
    csvData.push([`Ventas por ${source}`, count]);
  });

  // Agregar métodos de pago
  Object.entries(stats.salesByMethod).forEach(([method, count]) => {
    csvData.push([`Pagos por ${method}`, count]);
  });

  return csvData.map(row => row.join(',')).join('\n');
}

// Hook para obtener datos formateados para gráficos
export const useChartData = (stats) => {
  return React.useMemo(() => ({
    salesByDay: Object.entries(stats.salesByDay).map(([date, count]) => ({
      date,
      tickets: count
    })),
    salesByMethod: Object.entries(stats.salesByMethod).map(([method, count]) => ({
      method,
      tickets: count
    })),
    salesBySource: Object.entries(stats.salesBySource).map(([source, count]) => ({
      source,
      tickets: count
    }))
  }), [stats]);
};



export const getProductVariationStock = async (productId, variationId) => {
  const api = createWooCommerceInstance();
  try {
    const response = await api.get(`products/${productId}/variations/${variationId}`);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.log("Error obteniendo stock:", error);
    return {
      success: false,
      stock_quantity: 0,
      stock_status: 'outofstock'
    };
  }
};



export const getTicketsSold = () => {
  const credentials = getCredentials();
  return axios.get(getConfig(credentials, "WOO_API_URL") + `/wp-json/datofy/v1/tickets/sold`)
    .then((response) => {
      return response.data;
    });
};




// Servicio específico para crear órdenes de tickets
export const createTicketOrder = async (customer, product, variation_id, orderQuantity = 1, ticketsPerOrder = 1, meta_data) => {
  const api = createWooCommerceInstance();

  try {
    const filtered_meta_data = meta_data.filter(item => 
      ["ticket_type", "event_date", "event_location", "usuario", "email", "order_type", "order_from", "customer_name", "customer_email"].includes(item.key)
    );

    // Encontrar la variación específica
    const variation = product.variations.find(v => v.id.toString() === variation_id.toString());
    
    if (!variation) {
      throw new Error("Variación de producto no encontrada");
    }

    // Verificar stock disponible para todas las órdenes
    const totalTicketsNeeded = orderQuantity * ticketsPerOrder;
    if (variation.stock_quantity < totalTicketsNeeded) {
      throw new Error(`Stock insuficiente. Necesita ${totalTicketsNeeded} tickets pero solo hay ${variation.stock_quantity} disponibles`);
    }

    // Crear array de órdenes basado en orderQuantity
    const orders = Array(orderQuantity).fill().map(() => ({
      customer_id: customer.id,
      status: 'completed',
      line_items: [{
        product_id: product.id,
        variation_id: variation_id,
        quantity: ticketsPerOrder,
        meta_data: [
          ...filtered_meta_data,
          { key: 'tickets_per_order', value: ticketsPerOrder.toString() }
        ]
      }],
      meta_data: [
        ...filtered_meta_data,
        { key: 'total_tickets_in_order', value: ticketsPerOrder.toString() }
      ]
    }));

    const response = await api.post('orders/batch', { create: orders });

    return {
      success: true,
      data: response.data,
      ordersCreated: orderQuantity,
      ticketsPerOrder: ticketsPerOrder,
      totalTickets: orderQuantity * ticketsPerOrder,
      message: `${orderQuantity} orden(es) creada(s) con ${ticketsPerOrder} ticket(s) cada una para ${customer.first_name} ${customer.last_name}`
    };

  } catch (error) {
    console.error("Error creating ticket orders:", error);
    return {
      success: false,
      data: null,
      message: error.message || "Error al crear las órdenes de tickets"
    };
  }
};

// Servicio para crear órdenes de tickets en lote
export const createBatchTicketOrders = async (customers, product, variation_id, orderQuantity, meta_data) => {
  const results = {
    success: [],
    failed: [],
    totalOrdersCreated: 0,
    totalTicketsCreated: 0
  };
  let successCount = 0;
  let failedCount = 0;

  // Por defecto, crear 1 ticket por orden a menos que se especifique lo contrario
  const ticketsPerOrder = 1;

  // Verificar stock total necesario
  const variation = product.variations.find(v => v.id.toString() === variation_id.toString());
  const totalTicketsNeeded = customers.length * orderQuantity * ticketsPerOrder;
  
  if (variation && variation.stock_quantity < totalTicketsNeeded) {
    return {
      success: false,
      message: `Stock insuficiente. Se necesitan ${totalTicketsNeeded} tickets en total pero solo hay ${variation.stock_quantity} disponibles`,
      results: {
        success: [],
        failed: customers.map(customer => ({
          customer,
          error: "Stock insuficiente para la cantidad total requerida"
        }))
      }
    };
  }

  for (const customer of customers) {
    try {
      const result = await createTicketOrder(
        customer, 
        product, 
        variation_id, 
        orderQuantity, // Cantidad de órdenes a crear
        ticketsPerOrder, // Cantidad de tickets por orden
        [
          ...meta_data,
          { key: 'customer_name', value: `${customer.first_name} ${customer.last_name}` },
          { key: 'customer_email', value: customer.email }
        ]
      );

      if (result.success) {
        successCount++;
        results.success.push({
          customer,
          orders: result.data,
          ordersCreated: orderQuantity,
          ticketsPerOrder: ticketsPerOrder
        });
        results.totalOrdersCreated += orderQuantity;
        results.totalTicketsCreated += (orderQuantity * ticketsPerOrder);
      } else {
        failedCount++;
        results.failed.push({
          customer,
          error: result.message
        });
      }
    } catch (error) {
      failedCount++;
      results.failed.push({
        customer,
        error: error.message
      });
    }
  }

  return {
    success: successCount > 0,
    successCount,
    failedCount,
    totalOrdersCreated: results.totalOrdersCreated,
    totalTicketsCreated: results.totalTicketsCreated,
    message: `Se crearon ${results.totalOrdersCreated} órdenes en total (${results.totalTicketsCreated} tickets) para ${successCount} usuarios`,
    results
  };
};








// Servicio para crear un evento
export const createEvent = async (eventData) => {
  const credentials = getCredentials();
  try {
    // Crear FormData para enviar la imagen
    const formData = new FormData();
    
    // Agregar los campos del evento
    Object.keys(eventData).forEach(key => {
      if (key !== 'image') {
        formData.append(key, eventData[key]);
      }
    });
    
    // Agregar la imagen si existe
    if (eventData.image instanceof File) {
      formData.append('image', eventData.image);
    }

    const response = await axios.post(
      `${getConfig(credentials, "WOO_API_URL")}/wp-json/datofy/v1/crear-evento`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Evento creado exitosamente"
    };
  } catch (error) {
    console.error("Error creating event:", error);
    return {
      success: false,
      status: error.response?.status || 500,
      data: error.response?.data || null,
      message: error.response?.data?.message || "Error al crear el evento"
    };
  }
};

// Servicio para crear tickets para un evento
export const createTickets = async (eventId, ticketsData) => {
  const credentials = getCredentials();
  try {
    const response = await axios.post(
      `${getConfig(credentials, "WOO_API_URL")}/wp-json/datofy/v1/crear-tickets/${eventId}`,
      ticketsData,
      {}
    );

    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Tickets creados exitosamente"
    };
  } catch (error) {
    console.error("Error creating tickets:", error);
    return {
      success: false,
      status: error.response?.status || 500,
      data: error.response?.data || null,
      message: error.response?.data?.message || "Error al crear los tickets"
    };
  }
};

// Función para crear un evento completo con sus tickets
export const createEventWithTickets = async (eventData, ticketsData) => {
  try {
    // Primer paso: crear el evento
    const eventResult = await createEvent(eventData);
    if (!eventResult.success) {
      throw new Error(eventResult.message);
    }

    // Segundo paso: crear los tickets para el evento
    const ticketsResult = await createTickets(eventResult.data.event_id, {
      tickets: ticketsData
    });

    if (!ticketsResult.success) {
      throw new Error(ticketsResult.message);
    }

    return {
      success: true,
      data: {
        event: eventResult.data,
        tickets: ticketsResult.data
      },
      message: "Evento y tickets creados exitosamente"
    };

  } catch (error) {
    console.error("Error in createEventWithTickets:", error);
    return {
      success: false,
      message: error.message || "Error al crear el evento y los tickets"
    };
  }
};

// Ejemplo de uso:
/*
const createNewEvent = async () => {
  const eventData = {
    title: "Concierto de Rock",
    event_date: "2024-05-01",
    event_location: "Teatro Municipal",
    description: "Gran concierto de rock en vivo",
    image_url: "https://ejemplo.com/imagen.jpg"
  };

  const ticketsData = [
    {
      name: "VIP",
      price: "100000",
      stock: 100,
      description: "Acceso VIP al evento"
    },
    {
      name: "General",
      price: "50000",
      stock: 200,
      description: "Acceso general al evento"
    }
  ];

  const result = await createEventWithTickets(eventData, ticketsData);
  if (result.success) {
    console.log("Evento creado:", result.data);
  } else {
    console.error("Error:", result.message);
  }
};
*/

export const editEvent = async (eventId, eventData) => {
  const credentials = getCredentials();
  try {
    const formData = new FormData();
    
    Object.keys(eventData).forEach(key => {
      if (key !== 'image') {
        formData.append(key, eventData[key]);
      }
    });
    
    if (eventData.image instanceof File) {
      formData.append('image', eventData.image);
    }

    const response = await axios.post(
      `${getConfig(credentials, "WOO_API_URL")}/wp-json/datofy/v1/editar-evento/${eventId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return {
      success: true,
      data: response.data,
      message: "Evento actualizado exitosamente"
    };
  } catch (error) {
    console.error("Error updating event:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Error al actualizar el evento"
    };
  }
};

export const editTicket = async (ticketId, ticketData) => {
  const credentials = getCredentials();
  try {
    const response = await axios.post(
      `${getConfig(credentials, "WOO_API_URL")}/wp-json/datofy/v1/editar-ticket/${ticketId}`,
      ticketData // Enviar los datos directamente, sin anidarlos
    );

    return {
      success: true,
      data: response.data,
      message: "Ticket actualizado exitosamente"
    };
  } catch (error) {
    console.error("Error updating ticket:", error);
    return {
      success: false,
      message: error.response?.data?.message || "Error al actualizar el ticket"
    };
  }
};



/**
 * Busca un cliente por su nombre de usuario (username)
 * @param {string} username Username del cliente a buscar
 * @returns {Promise<Object|null>} Cliente encontrado o null
 */
export const getCustomerByUsername = async (username) => {
  const api = createWooCommerceInstance();
  try {
    // WooCommerce realiza búsquedas parciales, por lo que obtenemos todos los posibles resultados
    const response = await api.get("customers", { 
      search: username,  // Usar search para tener mejores resultados
      per_page: 100      // Aumentar para obtener más resultados posibles
    });
    
    // Buscar la coincidencia exacta (insensible a mayúsculas/minúsculas)
    if (response.data && response.data.length > 0) {
      const normalizedUsername = username.toLowerCase().trim();
      
      // Buscar coincidencia exacta ignorando mayúsculas/minúsculas
      const exactMatch = response.data.find(customer => 
        customer.username && customer.username.toLowerCase().trim() === normalizedUsername
      );
      
      if (exactMatch) {
        return exactMatch;
      }
    }
    
    // Si no se encontró coincidencia exacta, devuelve null
    return null;
  } catch (error) {
    console.error("Error al buscar cliente por username:", error);
    throw error;
  }
};

/**
 * Crea un nuevo cliente en WooCommerce
 * @param {Object} data Datos del cliente a crear
 * @returns {Promise<Object>} Resultado de la creación
 */
export const createCustomer = async (data) => {
  const api = createWooCommerceInstance();
  try {
    const response = await api.post("customers", data);
    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Usuario creado exitosamente",
    };
  } catch (error) {
    const status = error.response?.status || 500;
    const data = error.response?.data || null;
    const message = error.response?.data?.message || error.message || "Error al crear el cliente";
    console.log(status, data, message);
    return {
      success: false,
      status,
      data,
      message,
    };
  }
};

/**
 * Actualiza un cliente existente en WooCommerce
 * @param {number} customer_id ID del cliente a actualizar
 * @param {Object} data Datos actualizados del cliente
 * @returns {Promise<Object>} Resultado de la actualización
 */
export const updateCustomer = async (customer_id, data) => {
  const api = createWooCommerceInstance();
  try {
    // Excluir los campos que no se pueden editar
    const { username, ...editableData } = data;
    
    // Obtener el usuario actual primero
    const currentCustomer = await api.get(`customers/${customer_id}`);
    
    // Si el email es el mismo (ignorando mayúsculas), quitarlo para evitar errores de validación
    if (currentCustomer.data && 
        editableData.email && 
        currentCustomer.data.email.toLowerCase() === editableData.email.toLowerCase()) {
      delete editableData.email;
    }
    
    // Actualizar solo con los campos editables
    const response = await api.put(`customers/${customer_id}`, editableData);
    
    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Cliente actualizado exitosamente",
    };
  } catch (error) {
    console.error("Error al actualizar cliente:", error);
    return {
      success: false,
      status: error.response?.status || 500,
      data: error.response?.data || null,
      message: error.response?.data?.message || error.message || "Error al actualizar el cliente",
    };
  }
};

/**
 * Busca un cliente por su dirección de email
 * @param {string} email Email del cliente a buscar
 * @returns {Promise<Object|null>} Cliente encontrado o null
 */
export const getCustomerByEmail = async (email) => {
  const api = createWooCommerceInstance();
  try {
    // WooCommerce permite buscar clientes por email
    const response = await api.get("customers", { email });
    
    // Si se encontró algún cliente, devuelve el primero
    if (response.data && response.data.length > 0) {
      return response.data[0];
    }
    
    // Si no se encontró, devuelve null
    return null;
  } catch (error) {
    console.error("Error al buscar cliente por email:", error);
    throw error;
  }
};

// Aliases que ya existían
export const findCustomerByEmail = getCustomerByEmail;
export const findCustomerByUsername = getCustomerByUsername;




// 1. Primero, añadimos funciones para gestionar roles en WooCommerce
// Agregar estas funciones a services/woo.js

/**
 * Obtiene clientes con un rol específico con paginación
 * @param {string} role Rol a buscar (ej: "customer", "subscriber")
 * @param {number} page Número de página a obtener
 * @param {number} perPage Elementos por página
 * @returns {Promise<Array>} Lista de clientes con el rol especificado
 */
export const getCustomersWithRole = async (role = "customer", page = 1, perPage = 100) => {
  const api = createWooCommerceInstance();
  try {
    // WooCommerce usa 'role' como parámetro de consulta
    const response = await api.get("customers", { 
      role,
      page,
      per_page: perPage
    });
    
    return response.data || [];
  } catch (error) {
    console.error(`Error al obtener clientes con rol ${role}:`, error);
    throw error;
  }
};

/**
 * Actualiza el rol de un cliente en WooCommerce
 * @param {number} customerId ID del cliente a actualizar
 * @param {string} newRole Nuevo rol (ej: "customer", "subscriber")
 * @returns {Promise<Object>} Resultado de la actualización
 */
export const updateCustomerRole = async (customerId, newRole) => {
  const api = createWooCommerceInstance();
  try {
    // Solo actualizamos el rol
    const response = await api.put(`customers/${customerId}`, {
      role: newRole
    });
    
    return {
      success: true,
      status: response.status,
      data: response.data,
      message: "Rol actualizado exitosamente"
    };
  } catch (error) {
    console.error(`Error al actualizar rol del cliente ${customerId}:`, error);
    return {
      success: false,
      status: error.response?.status || 500,
      data: error.response?.data || null,
      message: error.response?.data?.message || error.message || "Error al actualizar rol"
    };
  }
};

