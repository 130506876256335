import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardEventRow from "./TablesEventRow";
import EventForm from "./EventForm";
import React from "react";

const Eventos = ({ title, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [showModal, setShowModal] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const handleSuccess = async () => {
    setIsRefreshing(true);
    // Aquí puedes agregar la lógica para refrescar la lista de eventos
    setIsRefreshing(false);
  };

  return (
    <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <Button
            colorScheme='teal'
            size='sm'
            mb='1rem'
            onClick={() => setShowModal(true)}
            isLoading={isRefreshing}
          >
            Agregar Evento
          </Button>          
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <DashboardEventRow
                  key={row.name}
                  name={row.name}
                  logo={row.logo}
                  status={row.status}
                  budget={row.budget}
                  progression={row.progression}
                  eventID={row.id}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size="6xl"
        isCentered
      >
        <ModalOverlay 
          bg="blackAlpha.300"
          backdropFilter="blur(10px)"
        />
        <ModalContent 
          mx={4}
          maxH="90vh"
          borderRadius="xl"
          boxShadow="xl"
        >
          <ModalHeader 
            borderBottomWidth="1px" 
            bg={useColorModeValue('gray.50', 'gray.800')}
            borderTopRadius="xl"
            py={4}
          >
            <Text fontSize="xl">Crear Nuevo Evento</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody 
            p={0} 
            overflow="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: useColorModeValue('gray.300', 'gray.600'),
                borderRadius: '24px',
              },
            }}
          >
            <EventForm
              onClose={() => setShowModal(false)}
              onSuccess={handleSuccess}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Eventos;