import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToast, Box, Progress, Button, Text, VStack, Icon } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { MdCloudUpload } from 'react-icons/md';
import axios from 'axios';
import { modifyOrder, getOrderMeta, getOrderDetails } from 'services/woo';
import { getSiteConfig } from 'utils/site';
import { saveSiteConfig } from 'utils/site';

const FileUpload = ({ orderId, onSuccess }) => { // Añadimos la prop onSuccess
  const { handleSubmit } = useForm();
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'image/*,application/pdf',
  });

  const onSubmit = async () => {
    setUploading(true);
  
    const config = JSON.parse(getSiteConfig());
    const wpApiConsumerKeyConfig = config.configs.find(item => item.key === 'WP_API_CONSUMER_KEY');
    const wooApiUrlConfig = config.configs.find(item => item.key === 'WOO_API_URL');
  
    try {
      const uploadedFiles = [];

      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`${wooApiUrlConfig?.value}/wp-json/wp/v2/media`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${wpApiConsumerKeyConfig?.value}`,
            'Accept': 'application/json',
          },
          onUploadProgress: (event) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            setUploadProgress((prevProgress) => ({ ...prevProgress, [file.name]: progress }));
          },
        });
  
        const fileUrl = response.data.source_url;
        uploadedFiles.push(fileUrl);
      }

      // Obtener archivos previamente subidos
      let existingFiles = [];
      const orderDetails = await getOrderDetails(orderId);
      if (orderDetails.success && orderDetails.data.meta_data) {
        const existingMeta = orderDetails.data.meta_data.find(meta => meta.key === 'uploaded_file_url');
        if (existingMeta && existingMeta.value) {
          try {
            existingFiles = Array.isArray(existingMeta.value) ? existingMeta.value : JSON.parse(existingMeta.value);
          } catch (parseError) {
            console.error('Error parsing existing files metadata', parseError);
          }
        }
      }

      // Combinar archivos previos con los nuevos
      const updatedFiles = [...existingFiles, ...uploadedFiles];
  
      await modifyOrder(orderId, [{ key: 'uploaded_file_url', value: updatedFiles }]);
      toast({
        title: 'Archivos subidos con éxito.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      setFiles([]);

      // Llamamos a onSuccess si está definido
      if (onSuccess) {
        console.log('onSucces desde FileUpload')
        onSuccess(); // Notificamos al componente padre que la subida fue exitosa
      }

    } catch (error) {
      console.error('Error uploading files', error);
      toast({
        title: 'Error al subir los archivos.',
        description: error.message || 'Ocurrió un error inesperado.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };  

  return (
    <Box p={4} borderWidth="1px" borderRadius="md">
      <VStack {...getRootProps()} p={5} border="2px dashed" borderColor={isDragActive ? 'teal.500' : 'gray.300'} borderRadius="md" cursor="pointer">
        <input {...getInputProps()} />
        <Icon as={MdCloudUpload} w={12} h={12} color="gray.500" />
        <Text>{isDragActive ? 'Suelta los archivos aquí...' : 'Arrastra y suelta archivos aquí o haz clic para seleccionar'}</Text>
      </VStack>

      {files.length > 0 && (
        <Box mt={4}>
          {files.map((file) => (
            <Box key={file.name} mb={4}>
              <Text fontSize="sm">{file.name}</Text>
              {uploading && (
                <Progress value={uploadProgress[file.name] || 0} size="xs" colorScheme="teal" />
              )}
            </Box>
          ))}
        </Box>
      )}

      <Button mt={4} colorScheme="teal" onClick={handleSubmit(onSubmit)} isLoading={uploading} isDisabled={files.length === 0}>
        Subir Archivos
      </Button>
    </Box>
  );
};

export default FileUpload;
