import React, { useState, useEffect, useMemo } from 'react';
import { 
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, 
    Button, Table, Text, Tbody, Tr, Td, Box, List, ListItem, Divider, Stack, useBreakpointValue, useToast, Tag, Badge, HStack, IconButton, Link, Image, SimpleGrid, Icon, Spinner, Tooltip, Badge as NotificationBadge
} from '@chakra-ui/react';
import { CheckIcon, WarningIcon, BellIcon, AttachmentIcon } from '@chakra-ui/icons';
import { modifyOrder, getOrderDetails, sendReminder, getOrderNotes } from '../../services/woo';
import FileUpload from 'components/FileUpload';
import { formatCurrency, useModuleCheck, MODULES } from 'utils/site';

const ModalOrderEdit = ({ order, isOpen, onClose, action, customer }) => {
    const [updatedOrder, setUpdatedOrder] = useState(order);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [orderNotes, setOrderNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingStatusChange, setLoadingStatusChange] = useState(false);
    const toast = useToast();

    const moduleStatus = useMemo(() => ({
        [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
        [MODULES.FILES]: useModuleCheck(MODULES.FILES),
      }), []);

    // Breakpoint para cambiar la distribución en dispositivos móviles
    const direction = useBreakpointValue({ base: 'column', md: 'row' });

    const selectedMetadataKeys = [
        'mes', 
        'concepto', 
        'monto', 
        'fecha_vencimiento', 
        'order_count_reminder',
        'billing_rut',
        'transactionDate',
        'paymentType',
    ];

    useEffect(() => {
        const fetchOrderDetails = async () => {
            if (isOpen) {
                setLoading(true);
                try {
                    const response = await getOrderDetails(order.id);
                    setUpdatedOrder(response.data);
                    const notes = await getOrderNotes(order.id);
                    setOrderNotes(notes);
                } catch (error) {
                    console.error('Error fetching updated order details:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        if (isOpen) {
            fetchOrderDetails();
        }
    }, [isOpen, order.id]);

    useEffect(() => {
        const fetchUploadedFiles = () => {
            // Buscar en los metadatos de la orden si hay archivos subidos y convertirlo en un array
            const filesMeta = updatedOrder.meta_data?.find(item => item.key === 'uploaded_file_url');
            if (filesMeta && Array.isArray(filesMeta.value)) {
                setUploadedFiles(filesMeta.value);
            } else {
                setUploadedFiles([]);
            }
        };
        fetchUploadedFiles();
    }, [updatedOrder]);

    const refreshOrderDetails = async () => {
        setLoading(true);
        try {
            const response = await getOrderDetails(order.id);
            setUpdatedOrder(response.data);
            const notes = await getOrderNotes(order.id);
            setOrderNotes(notes);
            toast({
                title: 'Pedido Actualizado',
                description: `El pedido #${order.id} se ha actualizado con éxito.`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error fetching updated order details:', error);
            toast({
                title: 'Error',
                description: 'No se pudo actualizar el estado del pedido. Inténtalo nuevamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleOrderStatusChange = async (newStatus, successMessage, errorMessage) => {
        const confirmed = window.confirm(`¿Estás seguro de que deseas marcar este pedido como ${newStatus}?`);
        if (!confirmed) return;

        setLoadingStatusChange(true);
        try {
            await modifyOrder(order.id, [], { status: newStatus });
            await refreshOrderDetails();
        } catch (error) {
            console.error(`Error al cambiar el estado del pedido a ${newStatus}:`, error);
            toast({
                title: 'Error',
                description: errorMessage,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoadingStatusChange(false);
        }
    };

    const handleOrderSendNotification = async () => {
        const confirmed = window.confirm('¿Estás seguro de que deseas enviar un recordatorio para este pedido?');
        if (!confirmed) return;

        setLoading(true);
        try {
            const orderDetails = await getOrderDetails(order.id);
            const orderCountReminder = orderDetails.data.meta_data && orderDetails.data.meta_data.find(item => item.key === 'order_count_reminder');
            if (orderCountReminder) {
                const updatedValue = parseInt(orderCountReminder.value) + 1;
                await sendReminder(order.id);
                await modifyOrder(order.id, [{ key: 'order_count_reminder', value: updatedValue }]);
            } else {
                await sendReminder(order.id);
                await modifyOrder(order.id, [{ key: 'order_count_reminder', value: 1 }]);
            }
            await refreshOrderDetails();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleFilesChange = async (files) => {
        setUploadedFiles(files || []);
        await refreshOrderDetails();
    };

    // TODO: Ya existe una función similar en src/utils/site.js, considerar reutilizarla
    const getOrderStatusLabel = (status) => {
        switch (status) {
            case 'pending':
                return { label: 'Pendiente', colorScheme: 'yellow' };
            case 'processing':
                return { label: 'En Proceso', colorScheme: 'blue' };
            case 'on-hold':
                return { label: 'En Espera', colorScheme: 'orange' };
            case 'completed':
                return { label: 'Pagado', colorScheme: 'green' };
            case 'cancelled':
                return { label: 'Cancelado', colorScheme: 'red' };
            case 'refunded':
                return { label: 'Reembolsado', colorScheme: 'purple' };
            case 'failed':
                return { label: 'Fallido', colorScheme: 'gray' };
            case 'used':
                return { label: 'Escaneado', colorScheme: 'blue' };
            default:
                return { label: 'Desconocido', colorScheme: 'gray' };
        }
    };

    const { label: statusLabel, colorScheme: statusColor } = getOrderStatusLabel(updatedOrder.status);
    const filteredMetadata = updatedOrder.meta_data?.filter(item => selectedMetadataKeys.includes(item.key)) || [];

    const orderCountReminder = updatedOrder.meta_data?.find(item => item.key === 'order_count_reminder')?.value || 0;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {loading && <Spinner size="sm" mr={3} />}Detalles del Pago #{updatedOrder.id}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction={direction} spacing={6}>
                        {/* Información del Pedido */}
                        <Box flex={1}>
                            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" mb={4}>
                                <Text fontSize="xl" fontWeight="bold" mb={2}>Estado</Text>
                                <Badge colorScheme={statusColor} fontSize="lg" px={4} py={1} borderRadius="md">
                                    {statusLabel}
                                </Badge>
                            </Stack>
                            <HStack spacing={3} mt={2} mb={4}>
                                <Tooltip label="Marcar como pagado">
                                    <IconButton
                                        icon={loadingStatusChange ? <Spinner size="sm" /> : <CheckIcon />}
                                        colorScheme="green"
                                        aria-label="Marcar Completado"
                                        isDisabled={updatedOrder.status !== 'processing' && updatedOrder.status !== 'pending'}
                                        onClick={() => handleOrderStatusChange('completed', 'Pedido Completado', 'No se pudo completar el pedido. Inténtalo nuevamente.')}
                                    />
                                </Tooltip>
                                <Tooltip label="Archivar Pago">
                                    <IconButton
                                        icon={loadingStatusChange ? <Spinner size="sm" /> : <WarningIcon />}
                                        colorScheme="red"
                                        aria-label="Archivar Pago"
                                        isDisabled={updatedOrder.status === 'cancelled' || updatedOrder.status === 'completed'}
                                        onClick={() => handleOrderStatusChange('cancelled', 'Pago Archivado', 'No se pudo archivar el pago. Inténtalo nuevamente.')}
                                    />
                                </Tooltip>
                                {updatedOrder.status === 'pending' && (
                                <Tooltip label="Enviar Recordatorio">
                                    <Box position="relative">
                                        <IconButton
                                            icon={loading ? <Spinner size="sm" /> : <BellIcon />}
                                            colorScheme="blue"
                                            aria-label="Enviar Recordatorio"
                                            onClick={handleOrderSendNotification}
                                        />
                                        {orderCountReminder > 0 && (
                                            <NotificationBadge
                                                position="absolute"
                                                top="-1"
                                                right="-1"
                                                fontSize="xs"
                                                colorScheme="red"
                                                borderRadius="full"
                                                px={1}
                                                py={0.5}
                                                backgroundColor="red.500"
                                                color="white"
                                                boxShadow="0 0 0 1px white"
                                            >
                                                {orderCountReminder}
                                            </NotificationBadge>
                                        )}
                                    </Box>
                                </Tooltip>
                                )}
                            </HStack>
                            
                            <Divider my={4} />
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Items</Text>
                            <Table size="sm" variant="striped">
                                <Tbody>
                                    {updatedOrder?.line_items?.map((item, index) => (
                                        <Tr key={index}>
                                            <Td fontWeight="bold">
                                                {item.quantity} x {item.name}
                                            </Td>
                                            <Td textAlign={'right'}>{formatCurrency(item.total)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            
                            {updatedOrder?.tax_lines?.length > 0 && (
                            <>
                            <Divider my={4} />
                            <Table size="sm" variant="striped">
                                <Tbody>
                                    {updatedOrder?.tax_lines?.map((item, index) => (
                                        <Tr key={index}>
                                            <Td fontWeight="bold">
                                                {item.label}
                                            </Td>
                                            <Td textAlign={'right'}>{formatCurrency(item.tax_total)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            </>
                            )}

                            <Table size="sm" variant="striped">
                                <Tbody>
                                     <Tr>
                                        <Td fontWeight="bold">Total:</Td>
                                        <Td textAlign={'right'}>{formatCurrency(updatedOrder.total)}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            <Divider my={4} />
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Información</Text>
                            <Table size="sm" variant="striped">
                                <Tbody>
                                    {filteredMetadata.map((item, index) => (
                                        <Tr key={index}>
                                            <Td fontWeight="bold">{item.key}:</Td>
                                            <Td>{item.value}</Td>
                                        </Tr>
                                    ))}

                                </Tbody>
                            </Table>

                            <Divider my={4} />
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Contacto</Text>
                            <Table size="sm" variant="striped">
                                <Tbody>
                                        <Tr key={'index'}>
                                            <Td fontWeight="bold">Nombre</Td>
                                            <Td>{updatedOrder?.customer_info?.first_name}</Td>
                                        </Tr>
                                        <Tr key={'email'}>
                                            <Td fontWeight="bold">Email</Td>
                                            <Td>{updatedOrder?.customer_info?.email}</Td>
                                        </Tr>
                                </Tbody>
                            </Table>
                        </Box>

                        {/* Archivos Subidos y Subida */}
                        {moduleStatus[MODULES.FILES] && (
                        <Box w={{ base: '100%', md: '40%' }}>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Archivos del Pago</Text>
                            <FileUpload orderId={order.id} onSuccess={handleFilesChange} />
                            <Box mt={4}>
                                <Text fontSize="md" fontWeight="bold">Imágenes Subidas:</Text>
                                {uploadedFiles?.filter(file => file.match(/\.(jpeg|jpg|gif|png)$/)).length > 0 ? (
                                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
                                        {uploadedFiles.filter(file => file.match(/\.(jpeg|jpg|gif|png)$/)).map((file, index) => (
                                            <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                <Image src={file} alt={`Imagen ${index + 1}`} />
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                ) : (
                                    <Text>No hay imágenes subidas.</Text>
                                )}
                            </Box>
                            <Box mt={4}>
                                <Text fontSize="md" fontWeight="bold">Archivos Subidos:</Text>
                                {uploadedFiles?.filter(file => !file.match(/\.(jpeg|jpg|gif|png)$/)).length > 0 ? (
                                    <List spacing={3} mt={4}>
                                        {uploadedFiles.filter(file => !file.match(/\.(jpeg|jpg|gif|png)$/)).map((file, index) => {
                                            const fileName = file.split('/').pop();
                                            const fileExtension = fileName.split('.').pop();
                                            return (
                                                <ListItem key={index} display="flex" alignItems="center">
                                                    <Icon as={AttachmentIcon} color="gray.500" mr={2} />
                                                    <Link href={file} isExternal color="teal.500" fontWeight="bold">
                                                        {fileName} ({fileExtension})
                                                    </Link>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <Text>No hay archivos subidos.</Text>
                                )}
                            </Box>
                        </Box>
                        )}
                    </Stack>
                    <Divider my={4} />
                    {/* Notas del Pedido */}
                    <Box mt={6}>
                        <Text fontSize="lg" fontWeight="bold" mb={2}>Notas del Pago</Text>
                        {loading ? (
                            <Box display="flex" alignItems="center" justifyContent="center" h="50px">
                                <Spinner size="sm" />
                            </Box>
                        ) : orderNotes.length > 0 ? (
                            <Box
                                mt={4}
                                maxH="200px"
                                overflowY="auto"
                                borderLeft="4px solid"
                                borderColor="gray.200"
                                pl={4}
                                pr={4}
                                pb={4}
                                borderRadius="md"
                            >
                                <List spacing={3}>
                                    {orderNotes.map((note, index) => (
                                        <ListItem key={index}>
                                            <Text fontSize="sm" color="gray.600">
                                                <Badge colorScheme="purple" mr={2}>{note.date_created}</Badge>
                                                <Box as="span" dangerouslySetInnerHTML={{ __html: note.note }} />
                                            </Text>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        ) : (
                            <Text color="gray.500">No hay notas disponibles.</Text>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} ml={4}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalOrderEdit;