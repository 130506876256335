import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button, Table, Text, Tbody, Tr, Td } from '@chakra-ui/react';
import { modifyOrder, getOrderDetails, sendReminder } from '../../services/woo'
import { ProfileIcon } from 'components/Icons/Icons';

const ModalDefault = ({ record, isOpen, onClose, action, customer }) => {
    // Define the selected metadata keys
    const selectedQuestionTexts = ['Alumno', 'Curso', 'Profesor', 'Correo Apoderado(a)', 'Nombre Apoderado(a)', 'Alumno(a) ¿asiste?', 'Categoría entrevista'];
    
    const filteredMetadata = record?.responses?.filter(item => selectedQuestionTexts.includes(item.question.text));

    const handleAction = (action, record) => {
        return () => {
            switch (action) {
                case 'edit':
                    // manejar la acción 2
                    console.log('Editar');
                    // window.open(`${process.env.REACT_APP_WOO_API_URL}/wp-admin/post.php?post=${record?.id ?? 1}&action=edit`, '_blank');
                    break;
                case 'print':
                    // manejar la acción 3
                    console.log('Imprimir');
                    window.print();
                    break;
                // Agrega más casos según sea necesario
                default:
                    console.error(`Acción desconocida: ${action}`);
            }
        };
    };

    const actionLabels = {
        'edit': 'Editar',
        'print': 'Imprimir',
        'notify': 'Enviar recordatorio',
        // Agrega más mapeos según sea necesario
    };
    
    const renderActionButtons = (record) => {
        if (Array.isArray(action) && action.length > 0) {
            return action.map((act, index) => (
                <Button key={index} onClick={handleAction(act, record)} ml={'3'}>
                    {actionLabels[act] || act}
                </Button>
            ));
        } else if (action) {
            return (
                <Button onClick={handleAction(action, record)}>
                    {actionLabels[action] || action}
                </Button>
            );
        } else {
            return null;
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    #{record?.id} {record?.survey?.name}
                    <Text fontSize="lg" color="teal.300" fontWeight="bold">
                        <ProfileIcon color="teal.300" fontSize="xl" />
                        <Text as="span" ml="2">{record?.respondent?.username}</Text>
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    Fecha: {record?.date}
                    </Text>
                    <Table variant="simple">
                        <Tbody>
                            {filteredMetadata?.map((item, index) => (
                                <Tr key={index}>
                                    <Td><Text fontSize="sm" color="gray.400" fontWeight="bold">{item.question.text}</Text></Td>
                                    <Td><Text fontSize="sm" color="gray.400" fontWeight="bold">{item.answer}</Text></Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    {renderActionButtons(record)}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalDefault;