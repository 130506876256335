import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  useToast,
  Textarea,
  Box,
  useColorModeValue
} from '@chakra-ui/react';
import { editTicket } from 'services/woo';

const EditTicketModal = ({ isOpen, onClose, ticket, onSuccess }) => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    price: '',
    stock: '',
    description: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    if (ticket) {
      setFormData({
        id: ticket.variantId || '', // Asegúrate de usar el campo correcto
        name: ticket.name || '',
        price: ticket.price || ticket.averagePrice || '', // Usa el campo correcto
        stock: ticket.stockQuantity || '',
        description: ticket.description || ''
      });
    }
  }, [ticket]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedTicket = {
        id: formData.id,
        name: formData.name,
        price: formData.price,
        stock: parseInt(formData.stock),
        description: formData.description,
        status: 'publish'
      };

      const result = await editTicket(formData.id, updatedTicket);

      if (!result.success) {
        throw new Error(result.message);
      }

      toast({
        title: 'Ticket actualizado',
        description: 'Los cambios se guardaron exitosamente',
        status: 'success',
        duration: 3000,
      });

      if (onSuccess) {
        onSuccess();
      }
      onClose();
    } catch (error) {
      toast({
        title: 'Error al actualizar ticket',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="xl"
      isCentered
    >
      <ModalOverlay 
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader 
          borderBottomWidth="1px" 
          bg={useColorModeValue('gray.50', 'gray.800')}
          borderTopRadius="xl"
          py={4}
        >
          Editar Ticket
        </ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody py={6}>
            <Box
              p={6}
              borderWidth="1px"
              borderRadius="lg"
              borderColor={borderColor}
              bg={useColorModeValue('white', 'gray.800')}
            >
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Nombre</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    size="lg"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Precio</FormLabel>
                  <NumberInput 
                    value={formData.price} // Usa value en lugar de defaultValue
                    onChange={(valueString) => handleChange('price', valueString)}
                    min={0}
                  >
                    <NumberInputField size="lg" />
                  </NumberInput>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Stock Disponible</FormLabel>
                  <NumberInput 
                    value={formData.stock} // Usa value en lugar de defaultValue
                    onChange={(valueString) => handleChange('stock', valueString)}
                    min={0}
                  >
                    <NumberInputField size="lg" />
                  </NumberInput>
                </FormControl>

                <FormControl>
                  <FormLabel>Descripción</FormLabel>
                  <Textarea
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    placeholder="Descripción del ticket..."
                    size="lg"
                    rows={4}
                  />
                </FormControl>
              </VStack>
            </Box>
          </ModalBody>

          <ModalFooter 
            borderTopWidth="1px"
            borderColor={borderColor}
            py={4}
          >
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={isLoading}
            >
              Guardar Cambios
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditTicketModal;