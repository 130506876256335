// Chakra imports
import {
    Box,
    Button,
    FormControl,
    Progress,
    Input,
    Text,
    useColorModeValue,
    Flex,
  } from "@chakra-ui/react";
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, { useState, useEffect } from "react";
  import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import { useAppContext } from "../../../../context/appContext";
  import { createCustomPayment, createOrderCheckout, paymentTransbank  } from "../../../../services/woo";
  import { useParams } from 'react-router-dom';   
import { getProductsBySite } from "services/apiSuscriptions";

  function CheckoutCustomOrder() {
    const titleColor = useColorModeValue("teal.300", "teal.200")
    const textColor = useColorModeValue("gray.700", "white")
    const bgColor = useColorModeValue("gray.200", "gray.800")
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)")
    const [amount, setAmount] = useState(0)
    const [log, setLog] = useState([])
    const [loading, setLoading] = useState(false)
    const [ordersCreated, setOrdersCreated] = useState([])
    const [progress, setProgress] = useState(0)
    const fechaVencimiento = new Date();
    fechaVencimiento.setDate(fechaVencimiento.getDate() + 15);
    const fechaVencimientoString = fechaVencimiento.toISOString().split('T')[0];
    const [siteUrl, setSiteUrl] = useState('')
    const [details, setDetails] = useState()
    const [email, setEmail] = useState('');
    const {siteId} = useParams();
    const [products, setProducts] = useState([]);  

    const createOrderSuscription = async () => {
      if (amount > 0) {
        const valorNumerico = convertirAMontoNumerico(amount);
        setLoading(true)

      // TODO: Buscar el cliente en la base de datos o crearlo desde API Suscripciones y la orden tambien

      const response = await createCustomPayment({
        monto: valorNumerico,
        detalle: details,
        email: email,
      });

      if (response.success) {
        console.log(response)
        const getPaymentTransbank = await paymentTransbank(response?.data?.order_id, siteUrl)
        console.log('getPaymentTransbank ', getPaymentTransbank)
        // window.location.href = paymentTransbank.data.urlx
        if (getPaymentTransbank.data.url) {
          window.location.href = getPaymentTransbank.data.url;
        }
        setLoading(false)
      } else {
        console.log(response)
        alert('Error al generar el link de pago')
        setLoading(false)
      }
      }

    }

    const convertirAMontoNumerico = (montoFormateado) => {
      const numeroLimpio = montoFormateado.replace(/[^0-9.-]+/g, '');
      return parseFloat(numeroLimpio);
    }

    useEffect(() => {
      const getProducts = async () => {
        try {
          const response = await getProductsBySite(siteId)
          console.log('products ', response.products)
          setProducts(response.products)
          setSiteUrl(response.site_url)
        } catch (error) {
          console.log('ERROR: ', error)
          setProducts([])
        }
      }
      getProducts()

    }, [])

    return (
      <Flex
        direction='column' 
        alignSelf='center'
        justifySelf='center' 
        overflow='hidden'>
        <Box
          position='absolute'
          minH={{ base: "40vh", md: "30vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          borderRadius={{ md: "15px" }}
          left='0'
          right='0'
          bgRepeat='no-repeat'
          overflow='hidden'
          zIndex='-1'
          top='0'
          bgImage={BgSignUp}
          bgSize='cover'
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}></Box>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt='3.3rem'
          mb='30px'>
          <Text fontSize='4xl' color='white' fontWeight='bold'>
            Pagar en linea
          </Text>
          <Text
            fontSize='md'
            color='white'
            fontWeight='normal'
            mt='10px'
            mb='16px'
            w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
            Puedes pagar en linea de forma segura y rapida con tu tarjeta de credito o debito.
          </Text>
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "30px" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Detalles {amount} - {progress}
            </Text>

            <Card overflowX={{ sm: "scroll", xl: "hidden" }} border={{ xl: "1px"}} mb="6" bg={bgColor} color={textColor}>
              <CardBody>
                <Box w={'100%'}>
                  <FormControl style={{marginTop: '1rem'}}>
                    <Input
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email"
                      border='1px solid'
                      borderColor={titleColor}
                      _placeholder={{ color: 'gray' }}
                    />
                    <Input mt={3} mb={6} value={details} onChange={e => setDetails(e.target.value)} placeholder="Detalle (opcional)"
                      border='1px solid'
                      borderColor={titleColor}
                      _placeholder={{ color: 'gray' }}
                     />

                    <Input
                      fontSize='sm'
                      borderRadius='15px'
                      type='text'
                      placeholder='$'
                      mb='24px'
                      size='lg'
                      bg="white"
                      onChange={(e) => setAmount(e.target.value)}
                      _placeholder={{ color: 'gray' }}
                    />
                    <Button
                      type='submit'
                      bg='teal.300'
                      fontSize='14px'
                      color='white'
                      fontWeight='bold'
                      w='100%'
                      h='45'
                      mb='24px'
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      onClick={() => createOrderSuscription()}
                      isLoading={loading}
                    >
                      PAGAR
                    </Button>
                  </FormControl>
                </Box>
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  
export default CheckoutCustomOrder;
  