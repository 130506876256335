import {
    FormControl,
    FormLabel,
    Input,
    VStack,
    Button,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useToast,
    Box,
    IconButton,
    Grid,
    GridItem,
    Flex,
    useColorModeValue,
    Textarea,
    Center,
  } from "@chakra-ui/react";
  import { AddIcon, DeleteIcon, SmallAddIcon } from "@chakra-ui/icons";
  import { FiUpload } from "react-icons/fi";
  import React, { useState } from "react";
  import { createEventWithTickets } from "services/woo";
  
  const EventForm = ({ onClose, onSuccess }) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [eventData, setEventData] = useState({
      title: "",
      event_date: "",
      event_location: "",
      description: "",
      image: null
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [tickets, setTickets] = useState([
      { name: "", price: "", stock: "", description: "" }
    ]);
  
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const sectionBg = useColorModeValue('gray.50', 'gray.700');
    const cardBg = useColorModeValue('white', 'gray.800');
  
    const handleEventChange = (e) => {
      const { name, value } = e.target;
      setEventData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handleTicketChange = (index, field, value) => {
      setTickets(prev => {
        const newTickets = [...prev];
        newTickets[index] = {
          ...newTickets[index],
          [field]: value
        };
        return newTickets;
      });
    };
  
    const addTicket = () => {
      setTickets(prev => [...prev, { name: "", price: "", stock: "", description: "" }]);
    };
  
    const removeTicket = (index) => {
      setTickets(prev => prev.filter((_, i) => i !== index));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
  
      try {
        const result = await createEventWithTickets(eventData, tickets);
  
        if (result.success) {
          toast({
            title: "¡Éxito!",
            description: "El evento y sus tickets han sido creados exitosamente",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
  
          onSuccess?.();
          onClose?.();
          
          // Redirección
          const eventId = result.data.event.event_id;
          window.location.href = `#/admin/evento/${eventId}`;
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <Grid templateColumns="repeat(12, 1fr)" gap={6} p={6}>
          {/* Columna Izquierda - Datos del Evento */}
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <VStack spacing={6} align="stretch">
              {/* Información Básica */}
              <Box 
                p={6} 
                bg={cardBg} 
                borderRadius="lg" 
                borderWidth="1px"
                borderColor={borderColor}
              >
                <Text fontSize="lg" fontWeight="bold" mb={4}>Información del Evento</Text>
                <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                  <GridItem colSpan={{ base: 12, md: 8 }}>
                    <FormControl isRequired>
                      <FormLabel>Título del Evento</FormLabel>
                      <Input
                        name="title"
                        value={eventData.title}
                        onChange={handleEventChange}
                        placeholder="Ej: Concierto de Rock"
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
  
                  <GridItem colSpan={{ base: 12, md: 4 }}>
                    <FormControl isRequired>
                      <FormLabel>Fecha</FormLabel>
                      <Input
                        name="event_date"
                        type="date"
                        value={eventData.event_date}
                        onChange={handleEventChange}
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
  
                  <GridItem colSpan={12}>
                    <FormControl isRequired>
                      <FormLabel>Ubicación</FormLabel>
                      <Input
                        name="event_location"
                        value={eventData.event_location}
                        onChange={handleEventChange}
                        placeholder="Ej: Teatro Municipal"
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
  
                  <GridItem colSpan={12}>
                    <FormControl>
                      <FormLabel>Descripción</FormLabel>
                      <Textarea
                        name="description"
                        value={eventData.description}
                        onChange={handleEventChange}
                        placeholder="Describe tu evento..."
                        size="lg"
                        rows={4}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
  
              {/* Tickets */}
              <Box>
                <Flex justify="space-between" align="center" mb={4}>
                  <Text fontSize="lg" fontWeight="bold">Tickets</Text>
                  <Button
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    size="sm"
                    onClick={addTicket}
                  >
                    Agregar Ticket
                  </Button>
                </Flex>
  
                <VStack spacing={4}>
                  {tickets.map((ticket, index) => (
                    <Box
                      key={index}
                      p={6}
                      bg={cardBg}
                      borderRadius="lg"
                      borderWidth="1px"
                      borderColor={borderColor}
                      w="100%"
                    >
                      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        <GridItem colSpan={12}>
                          <Flex justify="space-between" align="center" mb={4}>
                            <Text fontWeight="medium">Ticket {index + 1}</Text>
                            {tickets.length > 1 && (
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => removeTicket(index)}
                                colorScheme="red"
                                size="sm"
                                variant="ghost"
                              />
                            )}
                          </Flex>
                        </GridItem>
  
                        <GridItem colSpan={{ base: 12, md: 6 }}>
                          <FormControl isRequired>
                            <FormLabel>Nombre</FormLabel>
                            <Input
                              value={ticket.name}
                              onChange={(e) => handleTicketChange(index, "name", e.target.value)}
                              placeholder="Ej: VIP, General"
                            />
                          </FormControl>
                        </GridItem>
  
                        <GridItem colSpan={{ base: 6, md: 3 }}>
                          <FormControl isRequired>
                            <FormLabel>Precio</FormLabel>
                            <NumberInput
                              value={ticket.price}
                              onChange={(value) => handleTicketChange(index, "price", value)}
                              min={0}
                            >
                              <NumberInputField placeholder="$" />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
  
                        <GridItem colSpan={{ base: 6, md: 3 }}>
                          <FormControl isRequired>
                            <FormLabel>Stock</FormLabel>
                            <NumberInput
                              value={ticket.stock}
                              onChange={(value) => handleTicketChange(index, "stock", value)}
                              min={0}
                            >
                              <NumberInputField placeholder="Cantidad" />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </FormControl>
                        </GridItem>
  
                        <GridItem colSpan={12}>
                          <FormControl>
                            <FormLabel>Descripción</FormLabel>
                            <Input
                              value={ticket.description}
                              onChange={(e) => handleTicketChange(index, "description", e.target.value)}
                              placeholder="Descripción del ticket"
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Box>
                  ))}
                </VStack>
              </Box>
            </VStack>
          </GridItem>
  
          {/* Columna Derecha - Imagen y Acciones */}
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <Box
              position="sticky"
              top={4}
            >
              <Box 
                p={6} 
                bg={cardBg}
                borderRadius="lg"
                borderWidth="1px"
                borderColor={borderColor}
                mb={4}
              >
                <Text fontSize="lg" fontWeight="bold" mb={4}>Imagen del Evento</Text>
                <FormControl>
                  <Box
                    borderWidth={2}
                    borderRadius="lg"
                    borderStyle="dashed"
                    borderColor={borderColor}
                    p={6}
                    textAlign="center"
                    bg={sectionBg}
                    cursor="pointer"
                    onClick={() => document.getElementById('event-image').click()}
                  >
                    <Input
                      id="event-image"
                      type="file"
                      accept="image/*"
                      display="none"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setEventData(prev => ({ ...prev, image: file }));
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setImagePreview(reader.result);
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                    />
                    
                    {imagePreview ? (
                      <Box position="relative">
                        <img 
                          src={imagePreview} 
                          alt="Preview" 
                          style={{ 
                            width: '100%', 
                            borderRadius: '8px',
                            maxHeight: '200px',
                            objectFit: 'contain' 
                          }}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          position="absolute"
                          top={2}
                          right={2}
                          size="sm"
                          colorScheme="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEventData(prev => ({ ...prev, image: null }));
                            setImagePreview(null);
                          }}
                        />
                      </Box>
                    ) : (
                      <Center py={6} flexDirection="column">
                        <Box as={FiUpload} w={10} h={10} color="gray.400" mb={2} />
                        <Text color="gray.500">
                          Haz clic o arrastra una imagen aquí
                        </Text>
                      </Center>
                    )}
                  </Box>
                </FormControl>
              </Box>
  
              {/* Botón de Guardar */}
              <Button
                colorScheme="teal"
                size="lg"
                w="100%"
                type="submit"
                isLoading={isLoading}
              >
                Crear Evento
              </Button>
            </Box>
          </GridItem>
        </Grid>
      </form>
    );
  };
  
  export default EventForm;