import React from "react";
import ReactApexChart from "react-apexcharts";
import { lineChartData, lineChartOptions } from "variables/charts";
import { getProducts, getReportSales, getReportCustomers, getReportOrders, getSummaryOrders } from "../../services/woo";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const status = 'completed'
    const formattedDate = new Date().toISOString().slice(0,10) // Fecha actual
    getSummaryOrders(['wc-completed', 'wc-used'], '2024-12-20', formattedDate).then(data => {
      const tempLineChartData = []
      this.setState({ loading: false });
      const newCategories = Object.keys(data)
      const totalOrders = Object.values(data).map(item => item.total_orders);
      
      lineChartOptions.xaxis.categories = newCategories;
      tempLineChartData.push({
        name: status,
        data: totalOrders
      });
  
      this.setState({
        chartData: tempLineChartData,
        chartOptions: lineChartOptions,
      });
    });
  }

  
  render() {
    if (this.state.loading) return <></>
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="area"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;
