export const cursosList = [
    { "name": "Sin grupo" },
    { "name": "10:Educación Parvularia - 4 - A" },
    { "name": "10:Educación Parvularia - 4 - B" },
    { "name": "10:Educación Parvularia - 5 - A" },
    { "name": "110:Enseñanza Básica - 1 - A" },
    { "name": "110:Enseñanza Básica - 1 - B" },
    { "name": "110:Enseñanza Básica - 2 - A" },
    { "name": "110:Enseñanza Básica - 2 - B" },
    { "name": "110:Enseñanza Básica - 3 - A" },
    { "name": "110:Enseñanza Básica - 3 - B" },
    { "name": "110:Enseñanza Básica - 4 - A" },
    { "name": "110:Enseñanza Básica - 4 - B" },
    { "name": "110:Enseñanza Básica - 5 - A" },
    { "name": "110:Enseñanza Básica - 5 - B" },
    { "name": "110:Enseñanza Básica - 6 - A" },
    { "name": "110:Enseñanza Básica - 6 - B" },
    { "name": "110:Enseñanza Básica - 7 - A" },
    { "name": "110:Enseñanza Básica - 7 - B" },
    { "name": "110:Enseñanza Básica - 8 - A" },
    { "name": "110:Enseñanza Básica - 8 - B" },
    { "name": "310:Enseñanza Media Humanístico Científica - 1 - A" },
    { "name": "310:Enseñanza Media Humanístico Científica - 2 - A" },
    { "name": "310:Enseñanza Media Humanístico Científica - 2 - B" },
    { "name": "310:Enseñanza Media Humanístico Científica - 3 - A" },
    { "name": "310:Enseñanza Media Humanístico Científica - 3 - B" },
    { "name": "310:Enseñanza Media Humanístico Científica - 4 - A" },
    { "name": "310:Enseñanza Media Humanístico Científica - 4 - B" },
]

export const arancelesList = [
    { id: "01", name: 'Enero', month: '2024-01' },
    { id: "02", name: 'Febrero', month: '2024-02' },
    { id: 1, name: 'Marzo', month: '2024-03' },
    { id: 2, name: 'Abril', month: '2024-04' },
    { id: 3, name: 'Mayo', month: '2024-05' },
    { id: 4, name: 'Junio', month: '2024-06' },
    { id: 5, name: 'Julio', month: '2024-07' },
    { id: 6, name: 'Agosto', month: '2024-08' },
    { id: 7, name: 'Septiembre', month: '2024-09' },
    { id: 8, name: 'Octubre', month: '2024-10'},
    { id: 9, name: 'Noviembre', month: '2024-11'},
    { id: 10, name: 'Diciembre', month: '2024-12'},
]
