import React from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Avatar,
  Badge,
  SimpleGrid,
  IconButton,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import { AiOutlineTable } from 'react-icons/ai';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export default function TableManagement({ orders, tables, onSelectTable, onClose }) {
  const customersList = orders.map((order) => {
    const table = tables.find((t) => t.id === order.tableId);
    return {
      customer: order.customer,
      table: table ? table.name : 'Desconocida',
      status: order.status,
    };
  });

  const tableStatuses = tables.map((table) => {
    const tableOrders = orders.filter((order) => order.tableId === table.id);
    let status = table.status;
    if (tableOrders.length > 0) {
      status = 'occupied';
    }
    return { ...table, status };
  });

  const tableColor = (status) => {
    switch (status) {
      case 'available':
        return 'green.200';
      case 'occupied':
        return 'red.200';
      case 'reserved':
        return 'yellow.200';
      default:
        return 'gray.200';
    }
  };

  return (
    <Flex direction="column" h="100%" w="100%">
      <Flex p={4} justify="space-between" align="center" borderBottom="1px solid" borderColor="gray.200">
        <IconButton icon={<ChevronLeftIcon />} aria-label="Volver" onClick={onClose} />
        <Heading size="md">Gestión de Mesas</Heading>
        <Box />
      </Flex>

      <Box p={4} borderBottom="1px solid" borderColor="gray.200" maxH="200px" overflowY="auto">
        <VStack align="start" spacing={4}>
          {customersList.map((customer, index) => (
            <HStack key={index} w="full" justifyContent="space-between">
              <HStack>
                <Avatar name={customer.customer} size="sm" />
                <VStack align="start" spacing={0}>
                  <Text fontWeight="medium">{customer.customer}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {customer.table}
                  </Text>
                </VStack>
              </HStack>
              <Badge
                colorScheme={
                  customer.status === 'ready'
                    ? 'green'
                    : customer.status === 'pending'
                    ? 'orange'
                    : customer.status === 'cooking'
                    ? 'yellow'
                    : 'gray'
                }
              >
                {customer.status}
              </Badge>
            </HStack>
          ))}
        </VStack>
      </Box>

      <Flex flex="1" p={4} overflowY="auto">
        <SimpleGrid columns={[2, 3]} spacing={4} w="full">
          {tableStatuses.map((table) => (
            <Box
              key={table.id}
              bg={tableColor(table.status)}
              p={4}
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              onClick={() => onSelectTable(table.id)}
            >
              <Avatar
                size="lg"
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.800', 'white')}
                icon={<AiOutlineTable />}
                mb={2}
              />
              <Text fontWeight="bold">{table.name}</Text>
              <Text>{table.seats} personas</Text>
              <Badge
                mt={2}
                colorScheme={
                  table.status === 'available'
                    ? 'green'
                    : table.status === 'occupied'
                    ? 'red'
                    : table.status === 'reserved'
                    ? 'yellow'
                    : 'gray'
                }
              >
                {table.status === 'available'
                  ? 'Disponible'
                  : table.status === 'occupied'
                  ? 'Ocupada'
                  : table.status === 'reserved'
                  ? 'Reservada'
                  : 'Desconocido'}
              </Badge>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}