import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  HStack,
  Text,
  Flex,
  ButtonGroup,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Download, Filter } from 'lucide-react';
import ReactApexChart from 'react-apexcharts';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

const SalesDetailsModal = ({ isOpen, onClose, productId, allOrders }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // State for filters visibility
  const [statusFilter, setStatusFilter] = useState('all');
  const [showFilters, setShowFilters] = useState(false);

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  // Función para manejar el ordenamiento
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Ordenar las órdenes filtradas
  const getSortedOrders = (orders) => {
    if (!sortConfig.key) return orders;

    return [...orders].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'date':
          aValue = new Date(a.date_created).getTime();
          bValue = new Date(b.date_created).getTime();
          break;
        case 'total':
          aValue = parseFloat(a.total);
          bValue = parseFloat(b.total);
          break;
        case 'quantity':
          aValue = a.line_items[0]?.quantity || 0;
          bValue = b.line_items[0]?.quantity || 0;
          break;
        case 'type':
          aValue = a.line_items[0]?.name?.toLowerCase() || '';
          bValue = b.line_items[0]?.name?.toLowerCase() || '';
          break;
        case 'customer':
          aValue = `${a.customer_info?.first_name || ''} ${a.customer_info?.last_name || ''}`.toLowerCase();
          bValue = `${b.customer_info?.first_name || ''} ${b.customer_info?.last_name || ''}`.toLowerCase();
          break;
        default:
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
      }

      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  // Filter and paginate orders
  const filteredOrders = allOrders.filter(order => 
    statusFilter === 'all' ? true : order.status === statusFilter
  );

  const sortedOrders = getSortedOrders(filteredOrders);

  const totalOrders = sortedOrders.length;
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Process orders for chart data
  const processChartData = (orders) => {
    const groupedSales = orders.reduce((acc, order) => {
      try {
        const date = new Date(order.date_created);
        const formattedDate = date.toLocaleDateString('es', { 
          day: '2-digit',
          month: 'short'
        });
        
        if (!acc[formattedDate]) {
          acc[formattedDate] = 0;
        }
        acc[formattedDate] += parseFloat(order.total);
      } catch (error) {
        console.error('Error processing order:', error);
      }
      return acc;
    }, {});

    return Object.entries(groupedSales)
      .map(([date, sales]) => ({
        date,
        sales: Math.round(sales)
      }))
      .sort((a, b) => {
        const dateA = parseToDate(a.date);
        const dateB = parseToDate(b.date);
        return dateA - dateB;
      });
  };

  // Helper function to parse formatted date back to Date object for sorting
  // Función para exportar a CSV
  const handleExport = () => {
    // Agregar BOM para UTF-8
    const BOM = "\uFEFF";
    
    // Definir las cabeceras
    const headers = [
      'ID',
      'Fecha',
      'Cliente',
      'Email',
      'Teléfono',
      'Tipo de Ticket',
      'Cantidad',
      'Total',
      'Estado',
      'Método de Pago',
      'RUT',
      'Código de Autorización',
      'Número de Tarjeta',
      'Tipo de Tarjeta'
    ];

    // Preparar los datos para exportar
    const exportData = filteredOrders.map(order => [
      order.id,
      new Date(order.date_created).toLocaleString('es-CL'),
      `${order.customer_info?.first_name || ''} ${order.customer_info?.last_name || ''}`.trim(),
      order.customer_info?.email || '',
      order.billing?.phone || '',
      order.line_items[0]?.name || 'N/A',
      order.line_items[0]?.quantity || 0,
      // Removemos el formato de moneda para evitar problemas con los separadores
      parseFloat(order.total).toString().replace('.', ','),
      order.status === 'completed' ? 'Pagado' : 
      order.status === 'used' ? 'Escaneado' :
      order.status === 'pending' ? 'Pendiente' : 'Fallido',
      order.payment_method || '',
      order.meta_data?.find(m => m.key === 'billing_rut')?.value || '',
      order.meta_data?.find(m => m.key === 'authorizationCode')?.value || '',
      order.meta_data?.find(m => m.key === 'cardNumber')?.value || '',
      order.meta_data?.find(m => m.key === 'paymentType')?.value || ''
    ]);

    // Convertir a CSV
    const csvContent = BOM + [
      headers.join(';'),
      ...exportData.map(row => row.map(cell => 
        // Escapar punto y coma en los valores
        typeof cell === 'string' && cell.includes(';') 
          ? `"${cell.replace(/"/g, '""')}"` 
          : cell
      ).join(';'))
    ].join('\n');

    // Crear el blob y el enlace de descarga
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `ventas_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const parseToDate = (formattedDate) => {
    const [day, month] = formattedDate.split(' ');
    const monthMap = {
      'ene': 0, 'feb': 1, 'mar': 2, 'abr': 3, 'may': 4, 'jun': 5,
      'jul': 6, 'ago': 7, 'sep': 8, 'oct': 9, 'nov': 10, 'dic': 11
    };
    return new Date(new Date().getFullYear(), monthMap[month.toLowerCase()], parseInt(day));
  };

  const salesData = processChartData(filteredOrders);

  const chartData = salesData.length > 0 ? {
    options: {
      chart: {
        id: "sales-chart",
        toolbar: { show: false }
      },
      xaxis: {
        categories: salesData.map(item => item.date)
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '50%',
        }
      },
      colors: ['#38A169'],
      dataLabels: { enabled: false },
      grid: {
        show: true,
        borderColor: '#E2E8F0',
        strokeDashArray: 3
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (value) => `$${value.toLocaleString()}`
        }
      }
    },
    series: [{
      name: "Ventas",
      data: salesData.map(item => item.sales)
    }]
  } : {
    options: {
      chart: { id: "sales-chart", toolbar: { show: false } },
      xaxis: { categories: [] },
      plotOptions: { bar: { borderRadius: 4, columnWidth: '50%' } },
      colors: ['#38A169'],
      dataLabels: { enabled: false },
      grid: { show: true, borderColor: '#E2E8F0', strokeDashArray: 3 },
      tooltip: {
        theme: 'dark',
        y: { formatter: (value) => `$${value.toLocaleString()}` }
      }
    },
    series: [{ name: "Ventas", data: [] }]
  };

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalOrders / itemsPerPage)));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "full" : "6xl"}>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflow="auto">
        <ModalHeader borderBottomWidth="1px">Detalle de Ventas</ModalHeader>
        <ModalCloseButton />
        
        <ModalBody pb={6}>
          <Box mb={6}>
            <Flex 
              direction={isMobile ? "column" : "row"} 
              justify="space-between"
              gap={4}
            >
              <HStack spacing={4} wrap="wrap">
                <Button 
                  leftIcon={<Filter size={16} />} 
                  variant={showFilters ? "solid" : "outline"}
                  w={isMobile ? "full" : "auto"}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filtros
                </Button>
              </HStack>
              <Button 
                leftIcon={<Download size={16} />} 
                colorScheme="green"
                w={isMobile ? "full" : "auto"}
                onClick={handleExport}
              >
                Exportar CSV
              </Button>
            </Flex>
          </Box>

          {showFilters && (
            <Box mb={6} p={4} bg="gray.50" borderRadius="md">
              <Text mb={3} fontWeight="medium">Filtros</Text>
              <Select 
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="all">Todos los estados</option>
                <option value="completed">Pagado</option>
                <option value="used">Escaneado</option>
              </Select>
            </Box>
          )}

          <Card mb={6}>
            <CardHeader>
              <Text fontWeight="bold">Evolución de Ventas</Text>
            </CardHeader>
            <CardBody px={0}>
              <Box h={isMobile ? "250px" : "300px"} w="100%">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  width="100%"
                  height="100%"
                />
              </Box>
            </CardBody>
          </Card>

          <Card mb={6}>
            <CardBody>
              <Box overflowX="auto">
                <Table size={isMobile ? "sm" : "md"}>
                  <Thead>
                    <Tr>
                      <Th 
                        cursor="pointer" 
                        onClick={() => handleSort('id')}
                        _hover={{ color: 'green.500' }}
                      >
                        ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                      </Th>
                      <Th 
                        cursor="pointer"
                        onClick={() => handleSort('date')}
                        _hover={{ color: 'green.500' }}
                      >
                        Fecha {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                      </Th>
                      {!isMobile && (
                        <Th 
                          cursor="pointer"
                          onClick={() => handleSort('customer')}
                          _hover={{ color: 'green.500' }}
                        >
                          Cliente {sortConfig.key === 'customer' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </Th>
                      )}
                      <Th 
                        cursor="pointer"
                        onClick={() => handleSort('type')}
                        _hover={{ color: 'green.500' }}
                      >
                        Tipo {sortConfig.key === 'type' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                      </Th>
                      <Th 
                        isNumeric
                        cursor="pointer"
                        onClick={() => handleSort('quantity')}
                        _hover={{ color: 'green.500' }}
                      >
                        Cant. {sortConfig.key === 'quantity' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                      </Th>
                      <Th 
                        isNumeric
                        cursor="pointer"
                        onClick={() => handleSort('total')}
                        _hover={{ color: 'green.500' }}
                      >
                        Total {sortConfig.key === 'total' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                      </Th>
                      <Th>Estado</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentOrders.map((order) => (
                      <Tr key={order.id}>
                        <Td>#{order.id}</Td>
                        <Td>{new Date(order.date_created).toLocaleDateString()}</Td>
                        {!isMobile && <Td>{`${order.customer_info?.first_name || ''} ${order.customer_info?.last_name || ''}`}</Td>}
                        <Td>{order.line_items[0]?.name || 'N/A'}</Td>
                        <Td isNumeric>{order.line_items[0]?.quantity || 0}</Td>
                        <Td isNumeric>${parseFloat(order.total).toLocaleString()}</Td>
                        <Td>
                          <Badge
                            colorScheme={order.status === 'completed' ? 'green' : 
                                       order.status === 'used' ? 'blue' :
                                       order.status === 'pending' ? 'yellow' : 'red'}
                            rounded="full"
                            px={2}
                            py={1}
                          >
                            {order.status === 'completed' ? 'Pagado' : 
                             order.status === 'used' ? 'Escaneado' :
                             order.status === 'pending' ? 'Pendiente' : 'Fallido'}
                          </Badge>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </CardBody>
          </Card>

          <Flex 
            justify="space-between" 
            align="center"
            direction={isMobile ? "column" : "row"}
            gap={4}
          >
            <Text fontSize="sm" color="gray.500">
              Mostrando {indexOfFirstOrder + 1}-{Math.min(indexOfLastOrder, totalOrders)} de {totalOrders} ventas
            </Text>
            <ButtonGroup>
              <Button 
                onClick={handlePreviousPage}
                isDisabled={currentPage === 1}
              >
                Anterior
              </Button>
              <Button 
                onClick={handleNextPage}
                isDisabled={currentPage >= Math.ceil(totalOrders / itemsPerPage)}
              >
                Siguiente
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SalesDetailsModal;