// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Authors from "./components/Authors";
import Eventos from "./components/Eventos";
import { tablesTableData, dashboardTableData } from "variables/general";
import { getProductsByCategories } from "services/woo";

function Events() {
  const [events, setEvents] = React.useState([]);
  const fetchEvents = async () => {
    const response = await getProductsByCategories('tickets');
    console.log(response);
    setEvents(response);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Eventos
        title={"Eventos"}
        captions={["Nombre", "Estado", "Stock", "Vendido", ""]}
        data={events}
      />
    </Flex>
  );
}

export default Events;
