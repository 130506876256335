// Chakra imports
import {
  Box,
  Button,
  FormControl,
  Progress,
  Input,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, { useState, useEffect } from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useAppContext } from "../../../../context/appContext";
import { getCustomer, paymentTransbank } from "../../../../services/woo";
import { useParams } from 'react-router-dom';   
import { getProductsBySite, createOrderCheckout } from "services/apiSuscriptions";

function CheckoutCustomOrderProduct() {
  const titleColor = useColorModeValue("teal.300", "teal.200")
  const textColor = useColorModeValue("gray.700", "white")
  const bgColor = useColorModeValue("gray.200", "gray.800")
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)")
  const primaryColor = useColorModeValue("#83c44c", "#83c44c")
  const [month, setMonth] = useState("2024-06")
  const [log, setLog] = useState([])
  const [loading, setLoading] = useState(false)
  const [ordersCreated, setOrdersCreated] = useState([])
  const [progress, setProgress] = useState(0)
  const fechaVencimiento = new Date(`${month}-01`);
  fechaVencimiento.setDate(fechaVencimiento.getDate() + 15);
  const fechaVencimientoString = fechaVencimiento.toISOString().split('T')[0];
  const { productId, siteId } = useParams();
  const [products, setProducts] = useState([]);
  const [productFound, setProductFound] = useState(null)
  const [siteUrl, setSiteUrl] = useState('')
  const [ticketCount, setTicketCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [contactInfo, setContactInfo] = useState({ name: '', rut: '', email: '' });

  const createOrderSuscription = async () => {
      setLoading(true)

      // Crear meta_data para nuevo cobro del cliente
      const totalAmount = (ticketCount * productFound?.price) + (ticketCount * 1300);
      const meta_data_order = [
          { key: "mes", value: month },
          { key: 'concepto', value: productFound?.name },
          { key: 'monto', value: totalAmount },
          { key: "fecha_vencimiento", value: fechaVencimientoString },
          { key: 'usuario', value: contactInfo.rut },
          { key: 'email', value: contactInfo.email },
          { key: 'order_type', value: 'subscription_payment' },
          { key: 'order_for', value: 'single'},
          { key: 'order_from', value: 'pagoexpress_checkout-client' },
          { key: 'quantity', value: ticketCount }
      ];

      const getOrderCreated = await createOrderCheckout(productFound, meta_data_order, siteId)
      const orderCreated = getOrderCreated.create[0]
      console.log(orderCreated)

      if (orderCreated) {
        setOrdersCreated(ordersCreated => [...ordersCreated, orderCreated])
        console.log(orderCreated?.id)
        // Despues de creada la orden peticion axios wp-json/tu_namespace/v1/pago_transbank/862
        // se redirige al cliente a la url de pago de transbank
        const getPaymentTransbank = await paymentTransbank(orderCreated?.id, siteUrl, 'https://datofy-suscripciones.vercel.app/#/checkout/1/19')
        console.log('getPaymentTransbank ', getPaymentTransbank)
        // window.location.href = paymentTransbank.data.urlx
        if (getPaymentTransbank.data.url) {
          window.location.href = getPaymentTransbank.data.url;
        }
      }
      
      setProgress(0)
      setLoading(false)
      // handleUpdateData()
      setLog([...log, {'order_created' : ordersCreated}])
  }

  const handleAddTicket = () => {
      setTicketCount(ticketCount + 1);
  };

  const handleRemoveTicket = () => {
      if (ticketCount > 0) {
        setTicketCount(ticketCount - 1);
      }
  };

  const handleContactChange = (e) => {
      const { name, value } = e.target;
      setContactInfo({ ...contactInfo, [name]: value });
  };

  const handleNextStep = () => {
      setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
      setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    // Obtener los planes de suscripción a un endpoint de la API SUSCRIPCIONES
    // Parametros de la API: siteId
    // Debe retornar un array de productos
    const getProducts = async () => {
      try {
        const response = await getProductsBySite(siteId)
        console.log('products ', response.products)
        setProducts(response.products)
        const product_found = response.products.find(product => product.id === parseInt(productId, 10)); // Buscar si existe la suscripción
        if (product_found) {
          setProductFound(product_found);
          setSiteUrl(response.site_url);
        } else {
          console.warn('Producto no encontrado en la respuesta');
        }
      } catch (error) {
        console.log('ERROR: ', error)
        setProducts([])
      }
    }
    getProducts()

    setLoading(false)

  }, [siteId, productId])

  return (
  <Flex
    direction='column' 
    alignSelf='center'
    justifySelf='center' 
    overflow='hidden'>
    {currentStep === 1 && (
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "30px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Card border={{ xl: "1px"}} mb="6" bg={bgColor} color={textColor}>
            <CardBody>
              <Box w={'100%'}>
                <Box className="ticket-selection" mt='4'>
                  <Text fontSize='lg' color={textColor} fontWeight='bold' mb='2'>Entrada General</Text>
                  <Box className="ticket-type" display='flex' alignItems='center'>
                    <Text mr='4'>${productFound ? productFound.price : "0"}</Text>
                    <Button onClick={handleRemoveTicket} isDisabled={ticketCount === 0} size='sm' bgColor={primaryColor} color={'white'}>-</Button>
                    <Text mx='4'>{ticketCount}</Text>
                    <Button onClick={handleAddTicket} size='sm' bgColor={primaryColor} color={'white'}>+</Button>
                  </Box>
                  <Text fontSize='sm' color='gray.500' mt='2'>$1.300 de cargo por servicio</Text>
                </Box>

                {ticketCount > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Button onClick={handleNextStep} mt='6' bgColor={primaryColor} color={'white'} size='lg' w='full'>Continuar</Button>
                  </motion.div>
                )}
              </Box>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    )}

    {currentStep === 2 && (
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "30px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Text fontSize='xl' color={textColor} fontWeight='bold' textAlign='center' mb='22px'>Información de Contacto</Text>
          <FormControl mb='4'>
            <Input
              type='text'
              placeholder='Nombre'
              name='name'
              value={contactInfo.name}
              onChange={handleContactChange}
            />
          </FormControl>
          <FormControl mb='4'>
            <Input
              type='text'
              placeholder='RUT'
              name='rut'
              value={contactInfo.rut}
              onChange={handleContactChange}
            />
          </FormControl>
          <FormControl mb='4'>
            <Input
              type='email'
              placeholder='Correo Electrónico'
              name='email'
              value={contactInfo.email}
              onChange={handleContactChange}
            />
          </FormControl>
          <Flex justifyContent='space-between' mt='4'>
            <Button onClick={handlePreviousStep} colorScheme='gray' size='lg'>Volver</Button>
            <Button onClick={handleNextStep} bgColor={primaryColor} color={'white'} size='lg'>Siguiente</Button>
          </Flex>
        </Flex>
      </Flex>
    )}

    {currentStep === 3 && (
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "30px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Text fontSize='xl' color={textColor} fontWeight='bold' textAlign='center' mb='22px'>Resumen</Text>
          <Box mb='4'>
            <Text fontSize='lg' color={textColor}><strong>Evento:</strong> {productFound?.name}</Text>
            <Text fontSize='md' color={textColor}><strong>Lugar:</strong> El Honesto Mike Vitacura</Text>
            <Text fontSize='md' color={textColor}><strong>Fecha:</strong> Martes 5 nov de 08:30 PM a 11:45 PM</Text>
            <Text fontSize='lg' color={textColor} mt='4'><strong>Cantidad de Entradas:</strong> {ticketCount}</Text>
            <Text fontSize='lg' color={textColor}><strong>Total:</strong> ${(ticketCount * productFound?.price) + (ticketCount * 1300)}</Text>
          </Box>
          <Flex justifyContent='space-between' mt='4'>
            <Button onClick={handlePreviousStep} colorScheme='gray' size='lg'>Volver</Button>
            <Button onClick={createOrderSuscription} bgColor={primaryColor} color={'white'} isLoading={loading} size='lg'>Pagar</Button>
          </Flex>
        </Flex>
      </Flex>
    )}
  </Flex>
  );
}

export default CheckoutCustomOrderProduct;
