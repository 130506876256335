// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  Select
} from "@chakra-ui/react";
import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { getSites, saveSiteConfig, getSiteConfig } from '../../utils/site';

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  const [ selectedSite, setSelectedSite ] = useState("")
  const [ sites, setSites ] = useState([])

  const switchSite = (siteName) => {
    console.log("Switch:", [siteName, sites]);
    setSelectedSite(siteName)
    const filteredObjects = sites.filter(site => site.name === siteName);
    if (filteredObjects.length > 0) {
      let result = filteredObjects[0];
      result = JSON.stringify(result)
      const savedObject = saveSiteConfig(result)
      if(savedObject) {
        // Redireccionar a la página principal
        window.location.href = '/#'
      }
    } else {
      console.log("Site not found");
    }
  }

  useEffect(() => {
    const get_sites = getSites();
    const get_site_config = getSiteConfig();
    if (!get_sites || !get_site_config) {
      localStorage.clear();
      window.location.href = '/#/auth/signin';
    } else {
      const sites = JSON.parse(get_sites);
      const site_config = JSON.parse(get_site_config);
      if (sites.length > 0 && site_config) {
        setSites(sites);
        setSelectedSite(site_config.name);
      }
    }
  }, []);

  return (
    <>-
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Bienvenido
            </Text>
            <Text fontSize="md" mb="16px">
               
            </Text>
            <Separator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">

              <Box
                display={fixedDisplay}
                justifyContent="space-between "
                mb="16px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Navegación fija
                </Text>
                <Switch
                  colorScheme="teal"
                  isChecked={switched}
                  onChange={(event) => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box>

              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Dark/Light
                </Text>
                <Button onClick={toggleColorMode}>
                  Cambiar {colorMode === "light" ? "Dark" : "Light"}
                </Button>
              </Flex>


              <Separator />

              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="16px"
                mt="16px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px" mr={'3'}>
                  Cuenta
                </Text>
                <Select value={selectedSite} onChange={(e) => switchSite(e.target.value)}>
                <option value="">Seleccionar sitio</option>
                {sites?.length > 0 && sites.map((site) => (
                  <option key={site.name} value={site.name}>
                    {site.name}
                  </option>
                ))}
              </Select>
            </Flex>

            <Separator />

            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb="16px"
              mt="74px"
            >
              <Text fontSize="md" fontWeight="600" mb="4px">
                Cuenta
              </Text>
              <Button fontSize="xs" onClick={event =>  window.location.href='/#logout'}>
                Cerrar Sesión
              </Button>
            </Flex>
                
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
