import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button, 
  Grid,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Checkbox,
  Td,
  HStack,
  Icon,
} from "@chakra-ui/react";
import {   
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  DeleteIcon
} from '@chakra-ui/icons'
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState, useMemo } from "react";
import { useAppContext } from "../../../../context/appContext";
import BatchUsersOrderCreate from "./BatchUsersOrderCreate";
import BatchUsersTicketCreate from "./BatchUsersTicketCreate";
import BatchUsersOrderDelete from "./BatchUsersOrderDelete";
import BatchUsersGroup from "./BatchUsersGroup";
import { useModuleCheck, MODULES } from 'utils/site';
import { getProducts } from "services/woo";

const Customers = ({ 
  title, 
  customers,
  showActionsMenu = true,
  defaultAction = null, // 'tickets', 'group', 'subscription'
}) => {
  const [toogleOrderForm, setToogleOrderForm] = useState(false);
  const [toogleTicketForm, setToogleTicketForm] = useState(defaultAction === 'tickets');
  const [toogleOrderDeleteForm, setToogleOrderDeleteForm] = useState(false);
  const [toogleGroupForm, setToogleGroupForm] = useState(defaultAction === 'group');
  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'asc' });

  // Definir colores para modo light/dark
  const textColor = useColorModeValue("gray.700", "white");
  const bgCard = useColorModeValue("white", "gray.700");
  const bgHover = useColorModeValue("gray.50", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const headerBg = useColorModeValue("gray.50", "gray.800");
  const menuButtonBg = useColorModeValue("white", "gray.700");

  // Estado de módulos - memoizado
  const moduleStatus = useMemo(() => ({
    [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
    [MODULES.TICKETS]: useModuleCheck(MODULES.TICKETS),
    [MODULES.SURVEYS]: useModuleCheck(MODULES.SURVEYS),
    [MODULES.FILES]: useModuleCheck(MODULES.FILES),
    [MODULES.SUBSCRIPTIONS]: useModuleCheck(MODULES.SUBSCRIPTIONS),
  }), []);

  const handleSort = (column) => {
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSelectionChange = (selectedIds) => {
    setSelectedCustomers(selectedIds);
  };

  useEffect(() => {
    getProducts().then(products => { setProducts(products) });
    // Configurar grupos si es necesario
    if (defaultAction === 'group') {
      const getConfig = getSiteConfig();
      const config = JSON.parse(getConfig);
      setGroups(config?.groups || []);
    }
  }, [defaultAction]);

  const MenuResults = ({ customersTable, selectedCustomers }) => {
    if (!showActionsMenu) return null;
    if (selectedCustomers.length === 0) return null;
  
    return (
      <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        ({selectedCustomers.length}) Acciones
      </MenuButton>
      <MenuList>
        {moduleStatus[MODULES.TICKETS] &&
        <MenuItem icon={<AddIcon />} command='⌘T' onClick={() => {
          setToogleTicketForm(!toogleTicketForm)
          setToogleOrderDeleteForm(false)
          setToogleGroupForm(false)
        }}>
          Enviar entradas de cortesía
        </MenuItem>
        }
        {moduleStatus[MODULES.SUBSCRIPTIONS] &&
        <>
        <MenuItem icon={<AddIcon />} command='⌘T' onClick={() => {
          setToogleOrderForm(!toogleOrderForm)
          setToogleOrderDeleteForm(false)
          setToogleGroupForm(false)
        }}>
          Nuevo cobro por mensualidad
        </MenuItem>
        <MenuItem icon={<DeleteIcon />} command='⌘N' onClick={() => {
          setToogleOrderDeleteForm(!toogleOrderDeleteForm)
          setToogleOrderForm(false)
          setToogleGroupForm(false)
        }}>
          Eliminar Cobro
        </MenuItem>
        </>
        }
        <MenuItem icon={<RepeatIcon />} command='⌘⇧N' onClick={() => {
        setToogleGroupForm(!toogleGroupForm)
        setToogleOrderDeleteForm(false)
        setToogleOrderForm(false)
        }}> 
        Mover de grupo
        </MenuItem>
      </MenuList>
      </Menu>
    );
  };

  return (
    <Card 
      overflowX={{ sm: "scroll", xl: "hidden" }}
      bg={bgCard}
      borderColor={borderColor}
      m={0}
      p={0}
    >
      {title && (
        <CardHeader p='16px 0px 0px 16px' bg={headerBg}>
          <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr" }} gap='24px'>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>
              {title}
            </Text>
          </Grid>
        </CardHeader>
      )}

      {showActionsMenu && selectedCustomers.length > 0 && (
        <Box p={4} backgroundColor={headerBg}>
          <MenuResults 
            customersTable={customers} 
            selectedCustomers={selectedCustomers} 
          />
        </Box>
      )}

      {toogleTicketForm && (
        <BatchUsersTicketCreate 
          customers={customers.filter(c => selectedCustomers.includes(c.id))} 
          categoryId={'tickets'} 
        />
      )}
      {toogleOrderForm && (
        <BatchUsersOrderCreate 
          customers={customers.filter(c => selectedCustomers.includes(c.id))} 
          productsList={products} 
        />
      )}
      {toogleOrderDeleteForm && (
        <BatchUsersOrderDelete 
          customers={customers.filter(c => selectedCustomers.includes(c.id))} 
          productsList={products} 
          title={"Buscar la mensualidad que quieres eliminar"} 
        />
      )}
      {toogleGroupForm && (
        <BatchUsersGroup 
          customers={customers.filter(c => selectedCustomers.includes(c.id))} 
          groupList={groups} 
          title={"Mover los usuarios seleccionados a un nuevo grupo"} 
        />
      )}
      
      <Box overflowX="auto">
        <Table variant="simple">
          {/* Rest of the table code remains the same */}
          <Thead bg={headerBg}>
            <Tr>
              <Th px={4} py={4} width="40px">
                <Checkbox
                  isChecked={selectedCustomers.length === customers.length}
                  isIndeterminate={selectedCustomers.length > 0 && selectedCustomers.length < customers.length}
                  onChange={(e) => handleSelectionChange(
                    e.target.checked ? customers.map(c => c.id) : []
                  )}
                />
              </Th>
              <Th 
                cursor="pointer" 
                onClick={() => handleSort('name')}
                color={textColor}
              >
                <HStack spacing={2}>
                  <Text>Nombre</Text>
                  <Icon 
                    as={sortConfig.column === 'name' ? 
                      (sortConfig.direction === 'asc' ? ChevronUpIcon : ChevronDownIcon) : 
                      ChevronUpIcon
                    } 
                    color={textColor}
                  />
                </HStack>
              </Th>
              <Th color={textColor}>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {customers.map((customer) => (
              <Tr 
                key={customer.id}
                _hover={{ bg: bgHover }}
                cursor="pointer"
              >
                <Td px={4} width="40px">
                  <Checkbox
                    isChecked={selectedCustomers.includes(customer.id)}
                    onChange={() => {
                      const newSelected = selectedCustomers.includes(customer.id)
                        ? selectedCustomers.filter(id => id !== customer.id)
                        : [...selectedCustomers, customer.id];
                      handleSelectionChange(newSelected);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Td>
                <Td 
                  onClick={() => window.location.href = `/#/admin/usuario/${customer.id}`}
                  color={textColor}
                >
                  {customer.first_name} {customer.last_name}
                </Td>
                <Td>
                  <HStack spacing={2}>
                    <IconButton
                      size="sm"
                      icon={<EditIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        // Implementar edición
                      }}
                      bg={menuButtonBg}
                      _hover={{ bg: bgHover }}
                    />
                    <IconButton
                      size="sm"
                      icon={<ExternalLinkIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = `/#/admin/usuario/${customer.id}`;
                      }}
                      bg={menuButtonBg}
                      _hover={{ bg: bgHover }}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default Customers;