import React from 'react';
import {
  Box,
  Text,
  Heading,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  SimpleGrid,
  Progress,
  Skeleton,
  Divider,
  useColorModeValue,
  HStack,
  Badge,
  Select,
  Flex,
  useBreakpointValue
} from '@chakra-ui/react';
import { 
  getProductsByCategories,
  getOrdersWithStats, 
  useTicketStats, 
  useChartData
} from '../../../../services/woo';
import { formatCurrency, formatDate } from '../../../../utils/site';
import TicketTypes, { calculateTicketTypeStats } from '../../Event/components/TicketTypes';

const EventDashboard = ({ categoryId }) => {
  // Color mode values
  const bgBox = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');
  const statBg = useColorModeValue('white', 'gray.700');
  const secondaryText = useColorModeValue('gray.600', 'gray.400');
  const dividerColor = useColorModeValue('gray.200', 'gray.600');
  const [product, setProduct] = React.useState(null);
  const [orders, setOrders] = React.useState([]);
  const [variationsStock, setVariationsStock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  React.useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const productsData = await getProductsByCategories(categoryId);
        
        if (productsData && productsData.length > 0) {
          setProducts(productsData);
          setSelectedProductId(productsData[0].id);
          
          const firstProduct = productsData[0];
          setProduct(firstProduct);

          const stockMap = firstProduct.variations.reduce((acc, variation) => ({
            ...acc,
            [variation.id]: {
              stock_quantity: variation.stock_quantity,
              stock_status: variation.stock_status,
              name: variation.name,
              price: variation.price
            }
          }), {});

          setVariationsStock(stockMap);

          const productOrders = await getOrdersWithStats({}, firstProduct.id);
          setOrders(productOrders);
        }
      } catch (error) {
        console.error("Error cargando datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [categoryId]);

  const handleProductChange = async (productId) => {
    const selectedProduct = products.find(p => p.id.toString() === productId);
    setSelectedProductId(productId);
    setProduct(selectedProduct);
    
    const stockMap = selectedProduct.variations.reduce((acc, variation) => ({
      ...acc,
      [variation.id]: {
        stock_quantity: variation.stock_quantity,
        stock_status: variation.stock_status,
        name: variation.name,
        price: variation.price
      }
    }), {});
    
    setVariationsStock(stockMap);
    
    await loadOrders(selectedProduct.id);
  };

  const loadOrders = async (productId) => {
    try {
      setIsLoading(true);
      const productOrders = await getOrdersWithStats({}, productId);
      setOrders(productOrders);
    } catch (error) {
      console.error("Error cargando órdenes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const stats = useTicketStats(orders, product?.id);
  const chartData = useChartData(stats);

  // Calcular el cargo de Transbank (2% del total)
  const transbankFee = stats.totalIncome * 0.02;

  if (isLoading) {
    return <Stack>
      <Skeleton height="20px" startColor={useColorModeValue('gray.100', 'gray.700')} 
                endColor={useColorModeValue('gray.400', 'gray.900')} />
      <Skeleton height="20px" startColor={useColorModeValue('gray.100', 'gray.700')} 
                endColor={useColorModeValue('gray.400', 'gray.900')} />
      <Skeleton height="20px" startColor={useColorModeValue('gray.100', 'gray.700')} 
                endColor={useColorModeValue('gray.400', 'gray.900')} />
    </Stack>;
  }

  const ticketTypeStats = calculateTicketTypeStats(orders, variationsStock, stats);

  return (
    <Box color={textColor}>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading size="md">Estadísticas de Ventas</Heading>
        <Select
          size={isMobile ? "md" : "sm"}
          maxW="300px"
          value={selectedProductId || ''}
          onChange={(e) => handleProductChange(e.target.value)}
          isDisabled={isLoading}
        >
          {products.map((prod) => (
            <option key={prod.id} value={prod.id}>
              {prod.name}
            </option>
          ))}
        </Select>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 5 }} spacing={4} mb={6}>
        <Stat p={4} bg={statBg} borderRadius="lg" shadow="sm">
          <StatLabel>Total Tickets</StatLabel>
          <StatNumber>{stats.totalTicketsSold}</StatNumber>
        </Stat>
        <Stat p={4} bg={statBg} borderRadius="lg" shadow="sm">
          <StatLabel>Total Ventas</StatLabel>
          <StatNumber>{formatCurrency(stats.totalIncome)}</StatNumber>
        </Stat>
        <Stat p={4} bg={statBg} borderRadius="lg" shadow="sm">
          <StatLabel>Cargo por Servicio</StatLabel>
          <StatNumber>{formatCurrency(stats.totalIncome * 0.10)}</StatNumber>
        </Stat>
        <Stat p={4} bg={statBg} borderRadius="lg" shadow="sm">
          <StatLabel>Cargo Transbank</StatLabel>
          <StatNumber>{formatCurrency(transbankFee)}</StatNumber>
        </Stat>
        <Stat p={4} bg={statBg} borderRadius="lg" shadow="sm">
          <StatLabel>Precio Medio</StatLabel>
          <StatNumber>{formatCurrency(stats.averageTicketPrice)}</StatNumber>
        </Stat>
      </SimpleGrid>

      <TicketTypes 
        ticketTypeStats={ticketTypeStats}
        bgBox={bgBox}
        secondaryText={secondaryText}
        dividerColor={dividerColor}
      />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <Box p={4} bg={bgBox} borderRadius="lg" shadow="sm">
          <Heading size="sm" mb={4}>Clientes</Heading>
          <StatGroup>
            <Stat>
              <StatLabel color={secondaryText}>Únicos</StatLabel>
              <StatNumber>{stats.totalUniqueCustomers}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel color={secondaryText}>Nuevos</StatLabel>
              <StatNumber>{stats.customerSegments.new}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel color={secondaryText}>Recurrentes</StatLabel>
              <StatNumber>{stats.customerSegments.returning}</StatNumber>
            </Stat>
          </StatGroup>
        </Box>

        <Box p={4} bg={bgBox} borderRadius="lg" shadow="sm">
          <Heading size="sm" mb={4}>Últimos 5 días de venta</Heading>
          <Stack spacing={2}>
            {Object.entries(stats.salesByDay)
              .sort((a, b) => new Date(b[0]) - new Date(a[0]))
              .slice(0, 5)
              .map(([date, count]) => (
                <Text key={date}>
                  {formatDate(date)}: <Text as="span" fontWeight="bold">{count}</Text> tickets
                </Text>
              ))
            }
          </Stack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default EventDashboard;