// Chakra imports
import {
    Box,
    Button,
    FormControl,
    Progress,
    Input,
    Text,
    Select,
    useColorModeValue,
    Badge
  } from "@chakra-ui/react";
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, { useState, useEffect } from "react";
  import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import { useAppContext } from "../../../../context/appContext";
  import { getCustomer, getProducts, createOrderCuota, updateCustomer } from "../../../../services/woo";
  import { arancelesList } from "../../../../services/cursosList";

  function BatchUsersGroup({title, customers, groupList}) {
    const titleColor = useColorModeValue("teal.300", "teal.200")
    const textColor = useColorModeValue("gray.700", "white")
    const bgColor = useColorModeValue("white", "gray.700")
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)")
    const { handleUpdateData } = useAppContext()
    const [month, setMonth] = useState("2023-03")
    const [log, setLog] = useState([])
    const [loading, setLoading] = useState(false)
    const [ordersCreated, setOrdersCreated] = useState([])
    const [progress, setProgress] = useState(0)
    const [valueGroup, setValueGroup] = useState(false)

    const updateGroup = async () => {
        setLoading(true)
        setOrdersCreated([])

        // const ordersCreated = []
        for (const customer of customers) {
            // Obtenermos la informacion actualizada del cliente
            const customer_id = customer.id
            const getCustomerInfo = await getCustomer(customer_id)
            const { meta_data } = getCustomerInfo.data;
            const customer_suscription_name = _.find(meta_data, { key: 'suscription_name' })?.value;
            const customer_group_name = _.find(meta_data, { key: 'group_name' })?.value;
            // const product_found = _.find(products, { name: customer_suscription_name }) // Buscar si existe la suscripción
            if (!valueGroup) {
                alert('Debes seleccionar el grupo que se moveran los clientes')
                setLoading(false);
                return;
            }

            // Actualizar el estado de la suscripción y grupo del cliente
            const meta_data_customer = {
                meta_data: [ 
                { key: "group_name", value: valueGroup == 'empty' ? '' : valueGroup},
                ]
            };
            const customerUpdated = await updateCustomer(customer_id, meta_data_customer)
            console.log(customerUpdated)
            setOrdersCreated(ordersCreated => [...ordersCreated, customerUpdated.create])

            const porcentajeCompletado = (ordersCreated.length / customers.length) * 100
            console.log(porcentajeCompletado)
            setProgress(porcentajeCompletado)

            console.log(customerUpdated)
        }
        setLoading(false);
        handleUpdateData()
        setLog([...log, {'order_created' : ordersCreated}])

    }

    useEffect(() => {
        console.log(groupList)
    }, [])

    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} border={{ xl: "1px"}} mb="6">
          <CardHeader p='6px 0px 22px 0px'>
              <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              {title || 'Mover usuarios'}
              </Text>
          </CardHeader>
          <CardBody>
            <Box w={'100%'}>
              <Text fontSize={'2xl'} width="100%">
                <Badge fontSize='0.8em' colorScheme='teal'>
                {customers.length} usuarios seleccionados
                </Badge>
              </Text>

              <FormControl style={{marginTop: '1rem'}}>
                <Select value={valueGroup} onChange={(e) => setValueGroup(e.target.value)}>
                    <option value="">Seleccionar grupo</option>
                    <option value="empty">SIN GRUPO ASIGNADO</option>
                    {groupList && groupList.map((group) => (
                    <option key={group.name} value={group.name}>
                        {group.name}
                    </option>
                    ))}
                </Select>
                <Button
                  type='submit'
                  bg='teal.300'
                  fontSize='14px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  my='24px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  onClick={() => updateGroup()}
                  isLoading={loading}
                  >
                  MOVER USUARIOS AL GRUPO
                </Button>
              </FormControl>

              {ordersCreated.length > 0 && <Progress value={(ordersCreated.length / customers.length) * 100} style={{margin: '1rem'}} />}

          </Box>

          </CardBody>
        </Card>
    );
  }
  
export default BatchUsersGroup;
  