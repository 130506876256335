// services/surveysService.js
import axios from 'axios';

export const getSurveys = async () => {
    try {
        const laravelResponse = await axios.get('https://datofy.cl/api/sites/blaspascal/surveys');
        
        if (laravelResponse.status === 200) {
            return laravelResponse.data.data;
        }
        
        throw new Error('No se pudieron obtener las encuestas');
    } catch (error) {
        console.error('Error en getSurveys:', error);
        return []; 
    }
};

export default {
    getSurveys
};
