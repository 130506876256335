// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState } from "react";
import ModalOrderEdit from "components/Modal/ModalOrderEdit";
import ModalDefault from "components/Modal/ModalDefault";

const OrdersOverview = ({ title, amount, data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [actionOrder, setActionOrder] = useState();

  const [isOpenSurvey, setIsOpenSurvey] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [actionSurvey, setActionSurvey] = useState();

  const handleOpenModal = (record, action) => {
    switch (record?.type) {
      case 'order':
        setSelectedOrder(record);
        setActionOrder(action);
        setIsOpen(true);
        break;
      case 'survey':
        setSelectedSurvey(record);
        setActionSurvey(action);
        setIsOpenSurvey(true);
        break;
      default:
        console.warn('Tipo desconocido');
    }
  };

  return (
    <>
    {selectedOrder && <ModalOrderEdit order={selectedOrder} isOpen={isOpen} onClose={() => setIsOpen(false)} action={actionOrder} />}

    <ModalDefault record={selectedSurvey} isOpen={isOpenSurvey} onClose={() => setIsOpenSurvey(false)} action={actionSurvey} />

    <Card maxH='100%'>
      <CardHeader p='22px 0px 35px 14px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          {/* <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            <Text fontWeight='bold' as='span' color='teal.300'>
            {`${amount}%`}
            </Text>{" "}
            este mes.
          </Text> */}
        </Flex>
      </CardHeader>
      <CardBody ps='20px' pe='0px' mb='31px' position='relative'>
        <Flex direction='column'>
          {data.map((row, index, arr) => {
            return (
              <TimelineRow
              key={index}
              logo={row.logo}
              title={row.title}
              date={row.date}
              color={row.color}
              index={index}
              arrLength={arr.length}
              data={row}
              onClick={ () => handleOpenModal(row) }
              />
            );
          })}
        </Flex>
      </CardBody>
    </Card>
    </>
  );
};

export default OrdersOverview;
