import { Flex, Grid, useColorModeValue, Box } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { DocumentIcon } from "components/Icons/Icons.js";
import { useParams } from 'react-router-dom';   
import { getUserFiles } from "services/userService";
import { getRespondentSurveys } from "services/surveyService";
import { getCustomer, getOrders } from "services/woo"; 
import Header from "./components/Header";
import ProfileInformation from "./components/ProfileInformation";
import Files from "./components/Files";
import Surveys from "./components/Surveys";
import Projects from "../Dashboard/components/Projects";
import { useModuleCheck, withModuleCheck, MODULES } from "utils/site";
import DynamicPaginator from "components/Tables/DynamicPaginator";

const ITEMS_PER_PAGE = 10;

function Profile() {
  // Chakra color mode
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { id } = useParams();
  const [userFiles, setUserFiles] = useState([]);
  const [customer, setCustomer] = useState({ username: null });
  const [userRespondentSurveys, setUserRespondentSurveys] = useState([]);
  const [error, setError] = useState(null);

  // Estados para la paginación y filtros
  const [totalOrders, setTotalOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const [ordersData, setOrdersData] = useState([]);

  const [filters, setFilters] = useState([
    {
      name: 'startDate',
      type: 'date',
      value: '',
      placeholder: 'Fecha desde'
    },
    {
      name: 'endDate',
      type: 'date',
      value: '',
      placeholder: 'Fecha hasta'
    },
    {
      name: 'status',
      type: 'select',
      value: 'all',
      placeholder: false,
      options: [
        { value: 'all', label: 'Todos' },
        { value: 'completed', label: 'Pagado' },
        { value: 'used', label: 'Escaneado' },
        { value: 'pending', label: 'Pendiente' },
        { value: 'failed', label: 'Fallido' },
      ]
    }
  ]);

  // Estado de módulos - memoizado
  const moduleStatus = useMemo(() => ({
    [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
    [MODULES.FILES]: useModuleCheck(MODULES.FILES),
    [MODULES.SURVEYS]: useModuleCheck(MODULES.SURVEYS)
  }), []);

  const ProjectsComponent = useMemo(() => 
    withModuleCheck(Projects, MODULES.PAYMENTS), 
    []
  );

  const handleFilterChange = (name, value) => {
    setFilters(prev => 
      prev.map(f => f.name === name ? {...f, value} : f)
    );
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(Number(newItemsPerPage));
    setCurrentPage(1);
    fetchOrders({
      per_page: Number(newItemsPerPage),
      page: 1
    });
  };

  const fetchOrders = useCallback(async (params = {}) => {
    if (!customer.id || !moduleStatus[MODULES.PAYMENTS]) return;
    
    setIsLoading(true);
    try {
      const filterParams = {
        customer: customer.id,
        per_page: itemsPerPage,
        page: currentPage,
        ...params
      };

      // Manejo del filtro de estado
      const statusFilter = filters.find(f => f.name === 'status')?.value;
      if (statusFilter && statusFilter !== 'all') {
        filterParams.status = statusFilter;
      }
      
      // Manejo de fechas
      const startDate = filters.find(f => f.name === 'startDate')?.value;
      const endDate = filters.find(f => f.name === 'endDate')?.value;

      if (startDate) {
        filterParams.after = startDate;
      }

      if (endDate) {
        filterParams.before = endDate;
      }

      const response = await getOrders(filterParams);
      const orderFormated = response.data.map(order => ({
        logo: DocumentIcon,
        username: order?.customer_info?.username,
        group: order?.customer_info.meta_data.find(m => m.key === 'group_name')?.value,
        name: `${order?.customer_info?.first_name} ${order?.customer_info?.last_name}`,
        mes: order?.meta_data.find(m => m.key === 'mes')?.value,
        ...order
      }));
      
      setOrdersData(orderFormated);
      setTotalOrders(response.total);
      
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Error al cargar las órdenes');
    } finally {
      setIsLoading(false);
    }
  }, [customer.id, moduleStatus, currentPage, filters, itemsPerPage]);

  const fetchCustomerData = useCallback(async () => {
    try {
      const fetchCustomer = await getCustomer(id);
      setCustomer(fetchCustomer.data);
      
      if (fetchCustomer.data.username) {
        document.title = `Perfil: ${fetchCustomer.data.first_name} ${fetchCustomer.data.last_name}`;
        
        // Fetch files if module is enabled
        if (moduleStatus[MODULES.FILES]) {
          const getFiles = await getUserFiles(fetchCustomer.data.username);
          setUserFiles(getFiles.data);
        }

        // Fetch surveys if module is enabled
        if (moduleStatus[MODULES.SURVEYS]) {
          const getSurveys = await getRespondentSurveys(fetchCustomer.data.username);
          setUserRespondentSurveys(getSurveys);
        }
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setError('Error al cargar los datos del cliente');
    }
  }, [id, moduleStatus]);

  useEffect(() => {
    fetchCustomerData();
  }, [fetchCustomerData]);

  useEffect(() => {
    if (customer.id) {
      fetchOrders();
    }
  }, [fetchOrders, customer.id, currentPage, filters]);

  if (error) {
    return (
      <Box p={4} bg="red.50" color="red.500" borderRadius="md">
        {error}
      </Box>
    );
  }

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={customer.username ? `${customer.first_name} ${customer.last_name}` : 'Actualizando...'}
        email={customer.username}
        tabs={[
          {
            name: "RESUMEN",
            icon: <FaCube w='100%' h='100%' />,
          },
          {
            name: "PAGOS",
            icon: <IoDocumentsSharp w='100%' h='100%' />,
          },
          {
            name: "DOCUMENTOS",
            icon: <IoDocumentsSharp w='100%' h='100%' />,
          },
        ]}
      />

      <Grid templateColumns={{ 
          sm: "1fr", 
          lg: (moduleStatus[MODULES.SURVEYS]) ? "2fr 1fr" : "1fr" 
      }} mb={6}>
        <Box>
          {customer.username && (
            <ProfileInformation
              title={"Información de perfil"}
              name={`${customer.first_name} ${customer.last_name}`}
              email={customer.email}
              customer={customer}
            />
          )}

          {moduleStatus[MODULES.FILES] && (
            <Files title={"Archivos"} data={userFiles} />
          )}
        </Box>
        
        {moduleStatus[MODULES.SURVEYS] && (
          <Surveys
            title={"Documentación"}
            description={""}
            data={userRespondentSurveys}
            customer={customer}
            updateSurveyResponses={setUserRespondentSurveys}
          />
        )}
      </Grid>

      {moduleStatus[MODULES.PAYMENTS] && (
        <DynamicPaginator
          filters={filters}
          onFilterChange={handleFilterChange}
          totalItems={totalOrders}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          isLoading={isLoading}
        >
          <ProjectsComponent
            title={"Historial de Pagos"}
            captions={["Nombre", "Pago", "Estado"]}
            data={ordersData}
            isLoading={isLoading}
          />
        </DynamicPaginator>
      )}
    </Flex>
  );
}

export default React.memo(Profile);