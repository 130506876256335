import axios from 'axios';

export const API_URL = `${process.env.REACT_APP_SURVEY_API_URL}/api`; // Reemplaza con la URL de tu API
export const API_TOKEN = process.env.REACT_APP_SURVEY_API_TOKEN; // Reemplaza con el token de tu API
export const API_URL_BASE = process.env.REACT_APP_SURVEY_API_URL; // Reemplaza con la URL de tu API

export const makeAPIRequest = (url, method = 'get', params = {}, data = {}) => {
    const accessToken = localStorage.getItem('token');
  
    const headers = {
      headers: {
        Authorization: accessToken ? 'Bearer ' + accessToken : '',
      },
    };

    return axios({
        method,
        url: `${API_URL}${url}`,
        params,
        data,
        ...headers
    })
    .then((response) => response.data)
    .catch((error) => {
        if (error.response.status === 401) {
            // localStorage.removeItem('token');
        }
        throw error;
    });
}

// Formularios BPS
// TODO: Obtener desde la API los formularios permitidos para cada rol o por categoría
const rolesPermisos = {
    profesor: ["formulario-de-atencion"],  // Formularios permitidos para profesores
    doc: ["formulario-de-atencion-doc", "pre-matriculas-2024-salud", "pre-matriculas-2024-antecedentes", ],      // Formularios permitidos para docentes
};

// Función para filtrar formularios según el rol del usuario autenticado
function filtrarFormulariosPorRol(formularios, rol) {
    // Si el rol es "administrador", devuelve todos los formularios
    if (rol === "administrador") {
        return formularios;
    }
    
    // Para otros roles, solo devuelve los formularios permitidos según el rol
    const slugsPermitidos = rolesPermisos[rol] || [];  // Obtener slugs permitidos según el rol
    return formularios.filter(formulario => slugsPermitidos.includes(formulario.slug));
}

/**
 * Obtiene todas las respuestas de las encuestas.
 * @returns {Promise} La respuesta de la API con los datos de las respuestas de la encuesta.
 */
export const getSurveyResponses = async () => {
    const response = await axios.get(`${API_URL}/survey-responses`);
    return response.data;
};

/**
 * Obtiene las respuestas de la encuesta por usuario.
 * @param {string} userId - El ID del usuario.
 * @returns {Promise} La respuesta de la API con los datos de las respuestas de la encuesta por usuario.
 */
export const getSurveyResponsesByUser = async (userId) => {
    const response = await axios.get(`${API_URL}/survey-responses/user/${userId}`);
    return response.data;
};

/**
 * Obtiene los datos del encuestado.
 * @param {string} respondentId - El ID del encuestado.
 * @returns {Promise} La respuesta de la API con los datos del encuestado.
 */
export const getRespondent = async (respondentId) => {
    const response = await axios.get(`${API_URL}/respondents/${respondentId}`);
    return response.data;
};

/**
 * Obtiene las encuestas del encuestado.
 * @param {string} respondentId - El ID del encuestado.
 * @returns {Promise} La respuesta de la API con los datos de las encuestas del encuestado.
 */
export const getRespondentSurveys = async (respondentId, { orderBy = 'created_at', orderDirection = 'desc', search = '' } = {}) => {
    // Construir los parámetros de consulta
    const params = new URLSearchParams();
    params.append('order_by', orderBy);
    params.append('order_direction', orderDirection);
    if (search) params.append('search', search);

    // Construir la URL con los parámetros de consulta
    const response = await axios.get(`${API_URL}/respondents/${respondentId}/surveys?${params.toString()}`);
    return response.data;
};

/**
 * Obtiene todas las encuestas disponibles.
 * @returns {Promise} La respuesta de la API con los datos de todas las encuestas disponibles.
 */
export const getAllSurveys = async (site_slug, { orderBy = 'created_at', orderDirection = 'desc', search = '', groupBy = '' } = {}) => {
    const params = {
        order_by: orderBy,
        order_direction: orderDirection,
        search,
        group_by: groupBy
    };

    const response = await axios.get(`${API_URL}/sites/${site_slug}/surveys/`, { params });

    const user = await getUserAuth();
    const rolUsuarioAutenticado = user?.role;
    const formulariosFiltrados = filtrarFormulariosPorRol(response.data.data, rolUsuarioAutenticado);
    
    return formulariosFiltrados;
};

/**
 * Obtiene los datos de la encuesta.
 * @param {string} surveyId - El ID de la encuesta.
 * @returns {Promise} La respuesta de la API con los datos de la encuesta.
 */
export const getSurvey = async (surveyId) => {
    const response = await axios.get(`${API_URL}/surveys/${surveyId}`);
    return response.data;
}

/**
 * Ver SurveyResponse
 * @param {string} surveyResponseId - El ID de la respuesta de la encuesta.
 * @returns {Promise} La respuesta de la API con los datos de la respuesta de la encuesta.
 */
export const getSurveyResponse = async (surveyResponseId) => {
    const response = await axios.get(`${API_URL}/survey-responses/${surveyResponseId}`);
    return response.data;
}

export const getUserAuth = async () => {
    return makeAPIRequest('/user', 'get')
    .then((data) => {
      console.log(data);
      return data.user
    })
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Obtiene todas las respuestas de las encuestas.
 * @returns {Promise} La respuesta de la API con los datos de todas las respuestas de las encuestas.
 */
export const getAllSurveyResponses = async ({ 
    orderBy = 'created_at', 
    orderDirection = 'desc', 
    search = '', 
    startDate = '', 
    endDate = '' 
} = {}) => {
    const params = {
        order_by: orderBy,
        order_direction: orderDirection,
        search,
        start_date: startDate,
        end_date: endDate
    };

    const response = await axios.get(`${API_URL}/survey-responses`, { params });
    return response.data;
}

/**
 * Obtiene todos los archivos subidos a los perfiles de los encuestados.
 * @returns {Promise} La respuesta de la API con los datos de todos los archivos subidos a los perfiles de los encuestados.
 */
export const getAllFiles = async () => {
    const response = await axios.get(`${API_URL}/files`);
    return response.data;
}

  // Función auxiliar para obtener el rango de fechas
export const getDateRange = (rangeType) => {
    const endDate = new Date();
    let startDate;

    if (rangeType === 'last7days') {
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
    } else if (rangeType === 'currentMonth') {
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    }

    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
    };
  };

export const deleteSurvey = async (surveyId) => {
    if (confirm("¿Estás seguro de que desea eliminar el formulario?")) {
        try {
            const response = await makeAPIRequest(`/survey-responses/${surveyId}`, 'delete');
            console.log(response);
            return response; // Devuelve los datos si todo sale bien
        } catch (error) {
            console.error('Error eliminando la encuesta:', error);
            return false; // Retorna `false` si hay un error
        }
    } else {
        alert("Acción cancelada.");
        return false; // Retorna `false` si el usuario cancela
    }
}


export const getStatistics = async (params) => {
    const response = await axios.post(`${API_URL}/survey/stats`, params)
    return response
}