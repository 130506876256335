import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { 
  Button, Flex, Grid, Spinner, Box, Center,
  useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter, FormControl, 
  FormLabel, Alert, AlertIcon, Text, UnorderedList, ListItem,
  HStack, Input, Tooltip, Select, FormErrorMessage, Checkbox, VStack
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { format } from 'rut.js';

import { getCustomers, createCustomer, getProductsByCategories, updateCustomer } from "services/woo";
import { signUpApoderado } from "services/userService";
import { getSiteConfig } from 'utils/site';
import Customers from "./components/Customers";
import DynamicPaginator from "components/Tables/DynamicPaginator";
import { withModuleCheck, useModuleCheck, MODULES } from "utils/site";
import { getSurveys } from "services/surveysService"; 
import SurveyGroupSelector from "./SurveyGroupSelector";
import { useDebouncedCallback } from 'use-debounce';

function Suscriptions({ 
  defaultGroup = '', 
  showFilters = {
    surveys: true,      // Si se muestra el filtro de encuestas
  },
  // Nuevas props para controlar el comportamiento de Customers
  customersConfig = {
    showActionsMenu: true,        // Si se muestra el menú de acciones
    defaultAction: null,          // Acción por defecto ('tickets', 'group', null)
    title: "Usuarios"             // Título personalizable
  }
}) {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

  const isSearchingRef = useRef(false);

  const [selectedSurveys, setSelectedSurveys] = useState('');
  const [surveysList, setSurveysList] = useState([]);
  const [includeUserSurveys, setIncludeUserSurveys] = useState(false);

  const debouncedFetchCustomers = useDebouncedCallback(
    (options) => {
      fetchCustomers(options);
    },
    500 // 500ms de espera entre llamadas
  );

  // Estado de módulos - memoizado
  const moduleStatus = useMemo(() => ({
    [MODULES.PAYMENTS]: useModuleCheck(MODULES.PAYMENTS),
    [MODULES.TICKETS]: useModuleCheck(MODULES.TICKETS),
    [MODULES.SURVEYS]: useModuleCheck(MODULES.SURVEYS),
    [MODULES.FILES]: useModuleCheck(MODULES.FILES),
    [MODULES.SUBSCRIPTIONS]: useModuleCheck(MODULES.SUBSCRIPTIONS)
  }), []);

  // Initial filters generator
  const getInitialFilters = useCallback(() => {
    const baseFilters = [];

    if (showFilters.searchRaw !== false) {
      baseFilters.push({
        name: 'searchRaw',
        type: 'searchRaw',
        value: '',
        placeholder: 'BUSCAR 11222333-1'
      });
    }

    // Añadir filtro de encuestas si el módulo está habilitado
    if (moduleStatus[MODULES.SURVEYS] && showFilters.surveys !== false) {
      baseFilters.push({
        name: 'surveys_responded',
        type: 'checkbox',
        value: [],
        placeholder: 'FICHAS',
        options: []
      });
    }
    
    if (moduleStatus[MODULES.SUBSCRIPTIONS]) {
      baseFilters.push({
        name: 'suscription',
        type: 'select',
        value: '',
        placeholder: 'TODAS LAS SUSCRIPCIONES',
        options: [{ value: '', label: 'TODAS LAS SUSCRIPCIONES' }]
      });
    }

    if (showFilters.group !== false) {
      baseFilters.push({
        name: 'group',
        type: 'select',
        value: defaultGroup, 
        placeholder: 'TODOS LOS GRUPOS',
        options: [
          { value: '', label: 'TODOS LOS GRUPOS' },
          { value: 'empty', label: 'SIN GRUPO ASIGNADO' }
        ]
      });
    }

    if (showFilters.role !== false) {
      baseFilters.push({
        name: 'role',
        type: 'select',
        value: '',
        placeholder: 'TIPO DE USUARIO',
        options: [
          { value: '', label: 'Todos los roles' },
          { value: 'customer', label: 'Cliente' },
          { value: 'subscriber', label: 'Suscriptor' }
        ]
      });
    }

    return baseFilters;
  }, [moduleStatus, showFilters, defaultGroup]);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [valueRUT, setValueRUT] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedSuscription, setSelectedSuscription] = useState('');
  const [suscriptions, setSuscriptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loadingNewCustomer, setLoadingNewCustomer] = useState(false);
  const [formMessage, setFormMessage] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [filters, setFilters] = useState(getInitialFilters());

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  // Referencia para rastrear la última búsqueda realizada
  const lastSearchRef = useRef({
    timestamp: 0,
    params: {}
  });


  // Handle filter changes
  const handleFilterChange = useCallback((name, value) => {
    // Actualizar estado del filtro
    setFilters(prev => prev.map(f => f.name === name ? {...f, value} : f));
    
    // Actualizar estados individuales según el filtro
    switch(name) {
      case 'group':
        setSelectedGroup(value);
        break;
      case 'suscription':
        setSelectedSuscription(value);
        break;
      case 'role':
        setSelectedRole(value);
        break;
      case 'searchRaw':
        setValueRUT(value);
        break;
      case 'surveys_responded':
        // Convertir array a string para el estado
        const surveysString = value.join(',');
        setSelectedSurveys(surveysString);
        setIncludeUserSurveys(!!surveysString);
        break;
    }
    
    // Resetear a primera página
    setCurrentPage(1);
    
    // Preparar parámetros para la búsqueda
    const params = { page: 1 };
    
    if (name === 'surveys_responded') {
      params.surveys_responded = value.join(',');
    } else if (name === 'searchRaw') {
      params.search = value;
    } else {
      params[name] = value;
    }
    
    // Usar la versión con debounce para evitar múltiples llamadas
    debouncedFetchCustomers(params);
  }, [debouncedFetchCustomers]);

  // Modificar el manejador de cancelación para incluir la limpieza del grupo
  const cancelNewCustomer = () => {
    setValueRUT('');
    reset({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      group: ''
    });
    setLoadingNewCustomer(false);
    setFormMessage(null);
    onClose();
  };

  // Versión mejorada de fetchCustomers con prevención de duplicados
  const fetchCustomers = useCallback(async (options = {}) => {
    // Evitar búsquedas duplicadas si ya hay una en curso
    if (isSearchingRef.current) {
      console.log('Búsqueda en curso, ignorando petición:', options);
      return;
    }
    
    // Marcar como búsqueda en curso
    isSearchingRef.current = true;
    
    // Tu código existente de prevención de duplicados puede permanecer
    const paramsHash = JSON.stringify(options);
    const now = Date.now();
    
    // Verificar si esta es una búsqueda duplicada (mismos parámetros en menos de 1 segundo)
    if (
      now - lastSearchRef.current.timestamp < 1000 && 
      JSON.stringify(lastSearchRef.current.params) === paramsHash
    ) {
      console.log('Evitando búsqueda duplicada', options);
      isSearchingRef.current = false; // No olvidar liberar el bloqueo
      return;
    }
    
    lastSearchRef.current = {
      timestamp: now,
      params: {...options}
    };
    
    setLoading(true);
    try {
      const searchParams = {
        page: options.page || currentPage,
        perPage: options.perPage || itemsPerPage,
        suscriptionName: options.suscriptionName || selectedSuscription,
        groupName: options.groupName || selectedGroup,
        search: options.search || valueRUT,
        role: options.role || selectedRole,
        surveysResponded: options.surveys_responded || selectedSurveys,
        includeSurveys: (options.surveys_responded || selectedSurveys) ? 'true' : undefined
      };

      console.log('Ejecutando búsqueda con parámetros:', searchParams);

      const response = await getCustomers(
        searchParams.suscriptionName,
        searchParams.groupName,
        searchParams.search,
        null,
        null,
        searchParams.role,
        searchParams.page,
        searchParams.perPage,
        searchParams.surveysResponded,
        searchParams.includeSurveys
      );

      if (response?.data && Array.isArray(response.data)) {
        setCustomers(response.data);
      } else {
        setCustomers([]);
      }

      if (response?.headers) {
        setTotalCustomers(parseInt(response.headers['x-wp-total']) || 0);
        setTotalPages(parseInt(response.headers['x-wp-totalpages']) || 1);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setCustomers([]);
      setTotalPages(1);
      setTotalCustomers(0);
    } finally {
      setLoading(false);
        // Liberar el bloqueo con un pequeño retraso para evitar llamadas rápidas consecutivas
        setTimeout(() => {
          isSearchingRef.current = false;
        }, 300);
    }
  }, [currentPage, itemsPerPage, selectedSuscription, selectedGroup, valueRUT, selectedRole, selectedSurveys]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    debouncedFetchCustomers({ 
      page: newPage, 
      groupName: selectedGroup || defaultGroup,
      ...(selectedSurveys ? { surveys_responded: selectedSurveys } : {})
    });
  }; 

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(Number(newItemsPerPage));
    setCurrentPage(1);
    debouncedFetchCustomers({ 
      page: 1,
      perPage: Number(newItemsPerPage),
      groupName: selectedGroup || defaultGroup,
      ...(selectedSurveys ? { surveys_responded: selectedSurveys } : {})
    });
  };

  const onSubmit = async (data) => {
    setLoadingNewCustomer(true);
    try {
      let registrationResult = { success: true }; // Default value
  
      if (moduleStatus[MODULES.SURVEYS]) {
        registrationResult = await signUpApoderado({
          username: data.username,
          email: data.email,
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
          role: data.role || 'customer'
        });
      }
  
      const customerResult = await createCustomer(data);
  
      if (registrationResult.success && customerResult.success) {
        setFormMessage({ 
          type: "success", // Corregido a "success"
          message: "Usuario creado exitosamente" 
        });
        setTimeout(() => {
          setCurrentPage(1);
          fetchCustomers({ search: customerResult.data.username, page: 1 });
          reset();
          onClose();
        }, 2000);
      } else {
        const errorMessage = [
          registrationResult.message, 
          customerResult.message
        ].filter(Boolean).join('. ');
  
        setFormMessage({ 
          type: "error",
          message: errorMessage 
        });
      }
    } catch (error) {
      setFormMessage({ 
        type: "error", 
        message: "Error al crear usuario" 
      });
    } finally {
      setLoadingNewCustomer(false);
    }
  };

  const handleGroupSelection = (surveys) => {
    // Actualizar el filtro con las encuestas seleccionadas
    setFilters(prev => prev.map(f => {
      if (f.name === 'surveys_responded') {
        return {
          ...f,
          value: surveys
        };
      }
      return f;
    }));
    
    // Convertir array a string
    const surveysString = surveys.join(',');
    setSelectedSurveys(surveysString);
    setIncludeUserSurveys(!!surveysString);
    
    // Resetear a primera página
    setCurrentPage(1);
    
    // Usar la versión con debounce
    debouncedFetchCustomers({ 
      page: 1,
      surveys_responded: surveysString 
    });
  };

 // Load initial data with single effect
 useEffect(() => {
  const loadInitialData = async () => {
    try {
      const config = JSON.parse(getSiteConfig());
      const loadedGroups = config?.groups || [];
      setGroups(loadedGroups);
      
      // Update group filters
      setFilters(prev => prev.map(f => {
        if (f.name === 'group') {
          return {
            ...f,
            options: [
              { value: '', label: 'TODOS LOS GRUPOS' },
              { value: 'empty', label: 'SIN GRUPO ASIGNADO' },
              ...loadedGroups.map(grupo => ({
                value: grupo.name,
                label: grupo.name
              }))
            ]
          };
        }
        return f;
      }));

      // Load subscriptions if module active
      if (moduleStatus[MODULES.SUBSCRIPTIONS]) {
        const plans = await getProductsByCategories('planes');
        setSuscriptions(plans || []);
        
        setFilters(prev => prev.map(f => {
          if (f.name === 'suscription') {
            return {
              ...f,
              options: [
                { value: '', label: 'TODAS LAS SUSCRIPCIONES' },
                ...(plans || []).map(plan => ({
                  value: plan.name,
                  label: plan.name
                }))
              ]
            };
          }
          return f;
        }));
      }

      // Cargar lista de encuestas si el módulo está activo
      if (moduleStatus[MODULES.SURVEYS]) {
        try {
          const surveysResponse = await getSurveys();
          console.log('Encuestas:', surveysResponse);
          if (Array.isArray(surveysResponse)) {
            setSurveysList(surveysResponse);

            // Actualizar opciones del filtro de encuestas
            setFilters(prev => prev.map(f => {
              if (f.name === 'surveys_responded') {
                return {
                  ...f,
                  options: surveysResponse.map(survey => ({
                    value: survey.slug,
                    label: survey.name || `Encuesta #${survey.id}`
                  }))
                };
              }
              return f;
            }));
          }
        } catch (surveyError) {
          console.error('Error cargando encuestas:', surveyError);
        }
      }

      // Fetch customers with default group
      fetchCustomers({ groupName: defaultGroup });
    } catch (error) {
      console.error('Error loading initial data:', error);
    }
  };
  
  loadInitialData();
}, [moduleStatus, fetchCustomers, defaultGroup]);

  
return (
  <Flex direction='column' pt={{ base: "70px", md: "70px" }}>
    <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr" }} gap='24px'>
      <Box>
        <Flex justify="space-between" mb={4} align="center" wrap="wrap" gap={4}>
          <Button
            colorScheme='teal' size='sm' mb='1rem'
            leftIcon={<AddIcon />}
            onClick={onOpen}
          >
            Registrar Usuario
          </Button>
          
          <HStack>
            <Text fontSize="sm" color="gray.600">
              Total: {totalCustomers} usuarios
            </Text>
            <Tooltip label="Actualizar lista">
              <Button
                size="sm"
                onClick={() => fetchCustomers({ groupName: selectedGroup || defaultGroup })}
                isLoading={loading}
              >
                ↻
              </Button>
            </Tooltip>
          </HStack>
        </Flex>

        {moduleStatus[MODULES.SURVEYS] && (
          <SurveyGroupSelector onSelectGroup={handleGroupSelection} />
        )}

        <DynamicPaginator
          filters={filters}
          onFilterChange={handleFilterChange}
          totalItems={totalCustomers}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          isLoading={loading}
        >
          {loading ? (
            <Center py={'40'}><Spinner /></Center>
          ) : customers.length > 0 ? (
            <Customers 
              title={customersConfig.title}
              customers={customers}
              showActionsMenu={customersConfig.showActionsMenu}
              defaultAction={customersConfig.defaultAction}
            />
          ) : (
            <Box p={4} textAlign="center">
              No se encontraron usuarios para los filtros seleccionados.
            </Box>
          )}
        </DynamicPaginator>

        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader>Crear nueva cuenta</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FormControl isInvalid={errors.username}>
                    <FormLabel htmlFor="username">RUT</FormLabel>
                    <Input
                      ref={initialRef}
                      type="text"
                      id="username"
                      {...register("username", { required: true })}
                      onChange={(e) => e.target.value = format(e.target.value, { dots: false })}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.email} mt={4}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      type="email"
                      id="email"
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.first_name} mt={4}>
                    <FormLabel htmlFor="first_name">Nombres</FormLabel>
                    <Input 
                      type="text" 
                      id="first_name" 
                      {...register("first_name", { required: true })} 
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.last_name} mt={4}>
                    <FormLabel htmlFor="last_name">Apellidos</FormLabel>
                    <Input 
                      type="text" 
                      id="last_name" 
                      {...register("last_name", { required: true })} 
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.password} mt={4}>
                    <FormLabel htmlFor="password">Contraseña</FormLabel>
                    <Input 
                      type="text" 
                      id="password" 
                      {...register("password", { required: true })} 
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.group} mt={4}>
                  <FormLabel htmlFor="group">Grupo</FormLabel>
                  <Select
                    id="group"
                    placeholder="Seleccione un grupo"
                    {...register("group")}
                  >
                    <option value="">Sin grupo</option>
                    {groups.map((grupo) => (
                      <option key={grupo.name} value={grupo.name}>
                        {grupo.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.group && errors.group.message}
                  </FormErrorMessage>
                </FormControl>

                <FormMessage formMessage={formMessage} />
                </ModalBody>

                <ModalFooter>
                  <Button 
                    mr={4} 
                    colorScheme="teal" 
                    type="submit" 
                    isLoading={loadingNewCustomer}
                  >
                    Guardar
                  </Button>
                  <Button onClick={cancelNewCustomer}>Cancelar</Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
      </Box>
    </Grid>
  </Flex>
);
}

const FormMessage = ({ formMessage }) => {
  if (!formMessage) return null;

  return (
    <Alert mt={'6'} status={formMessage.type}>
      <AlertIcon />
      <Box flex="1">
        <Text>{formMessage.message}</Text>
        {formMessage.errorMessages && formMessage.errorMessages.length > 0 && (
          <UnorderedList>
            {formMessage.errorMessages.map((error, index) => (
              <ListItem key={index}>{error}</ListItem>
            ))}
          </UnorderedList>
        )}
      </Box>
    </Alert>
  );
};

export default Suscriptions;