import React, { useState, useRef } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, Image, Box, Flex } from "@chakra-ui/react";
import SignaturePad from "react-signature-canvas";

function SignatureInput({ onChange, value }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageURL, setImageURL] = useState(value);
  const sigCanvas = useRef({});

  const clearSignature = () => {
    if (sigCanvas.current) { // Verifica que sigCanvas.current no sea null
      sigCanvas.current.clear();
    }
  };

  const save = () => {
    const canvas = sigCanvas.current.getTrimmedCanvas();
    const dataURL = canvas.toDataURL("image/png");
  
    // Convertir base64 a Blob
    const fetchResponse = fetch(dataURL);
    fetchResponse.then(res => res.blob()).then(blob => {
      // Crear un objeto File a partir del Blob
      const file = new File([blob], "signature.png", { type: "image/png" });
  
      // Utilizar DataTransfer para crear un FileList
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      const fileList = dataTransfer.files;
  
      console.log(fileList);
  
      // Actualizar setImageURL si es necesario
      setImageURL(dataURL);
  
      // Llamar a onChange con el FileList
      onChange(fileList);
  
      // Cerrar el modal o componente actual
      onClose();
    });
  };

  const handleClear = () => {
    clearSignature();
    setImageURL(null);
    onChange(null);
  };

  return (
    <Box>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
          <Flex alignItems="center" justifyContent="center" w="100%" h="90vh">
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas"
              }}
            />
          </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={save}>
              Guardar
            </Button>
            <Button variant="ghost" onClick={clearSignature}>Limpiar</Button>
            <Button variant="ghost" onClick={onClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      

      {imageURL && (
        <>
          <Image
            src={imageURL}
            alt="signature"
            style={{
              display: "block",
              margin: "0 auto",
              width: "250px",
            }}
          />
          <Button onClick={handleClear} mt={2} mr={2}>Borrar</Button>
        </>
      )}
      <Button onClick={onOpen} mt={2}>
        {imageURL ? "Cambiar" : "Firmar"}
      </Button>

    </Box>
  );
}

export default SignatureInput;