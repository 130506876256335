import axios from 'axios';

const API_URL = `${process.env.REACT_APP_SURVEY_API_URL}/api`;

// Crear una instancia de axios con configuración base
const axiosInstance = axios.create({
    baseURL: API_URL,
    // withCredentials: true, // Importante para CORS en Safari
});

// Interceptor para manejar los headers de forma consistente
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // Headers adicionales necesarios para Safari
        config.headers['Accept'] = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Función helper para manejar errores de manera consistente
const handleApiError = (error, defaultMessage) => {
    console.error('API Error:', error);
    return {
        success: false,
        message: error.response?.data?.message || defaultMessage,
        errors: error.response?.data?.errors || {},
        statusCode: error.response?.status
    };
};

/**
 * Servicio signUpApoderado simplificado
 * @param {Object} registrationData Datos de registro del usuario
 * @returns {Promise<Object>} Resultado del registro
 */
export const signUpApoderado = async (registrationData) => {
    try {
      // Para depuración: mostrar los datos que se envían
      console.log("[signUpApoderado] Enviando datos:", registrationData);
      
      // Realizar la petición
      const response = await axiosInstance.post('/register-apoderado', registrationData);
      
      // Para depuración: mostrar la respuesta completa
      console.log("[signUpApoderado] Respuesta exitosa:", response.data);
      
      // Devolver directamente la respuesta sin modificarla
      return response.data;
    } catch (error) {
      // Para depuración: mostrar error completo
      console.error("[signUpApoderado] Error:", error);
      
      // Si hay respuesta con datos, devolver esos datos
      if (error.response && error.response.data) {
        console.log("[signUpApoderado] Error con datos:", error.response.data);
        return error.response.data;
      }
      
      // Si no hay datos en la respuesta, lanzar el error para que sea capturado
      throw error;
    }
  };

/**
 * Busca un usuario por su dirección de email
 * @param {string} email Email del usuario a buscar
 * @returns {Promise<Object>} Usuario encontrado o null
 */
export const getUserByEmail = async (email) => {
    try {
        const response = await axiosInstance.get(`/users/find-by-email/${email}`);
        return response.data.data;
    } catch (error) {
        console.error('Error al buscar usuario por email:', error);
        return null;
    }
};

/**
 * Actualiza los datos de un apoderado existente
 * @param {number} userId ID del usuario a actualizar
 * @param {Object} userData Datos actualizados del usuario
 * @returns {Promise<Object>} Resultado de la actualización
 */
export const updateApoderado = async (userId, userData) => {
    try {
        const response = await axiosInstance.put(`/users/${userId}`, userData);
        return {
            success: true,
            data: response.data,
            message: 'Usuario actualizado exitosamente'
        };
    } catch (error) {
        console.error('Error al actualizar usuario:', error);
        return handleApiError(error, 'Error al actualizar usuario');
    }
};

/**
 * Obtiene la lista de todos los usuarios
 * @returns {Promise<Array>} Lista de usuarios
 */
export const getAllUsers = async () => {
    try {
        const response = await axiosInstance.get('/users');
        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        return handleApiError(error, 'Error al obtener usuarios');
    }
};

/**
 * Obtiene los archivos de un usuario
 * @param {string} userId ID del usuario
 * @returns {Promise<Object>} Resultado con los archivos
 */
export const getUserFiles = async (userId) => {
    try {
        const response = await axiosInstance.get(`/users/${userId}/files`);
        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        return handleApiError(error, 'Error al obtener los archivos del usuario.');
    }
};

/**
 * Obtiene la lista de sitios
 * @returns {Promise<Object>} Resultado con los sitios
 */
export const getSites = async () => {
    try {
        const response = await axiosInstance.get('/sites');
        console.log('Response from API:', response); // Para debugging

        if (response.data) {
            return {
                success: true,
                data: response.data // Asegúrate que esto sea un array
            };
        }
        
        return {
            success: false,
            message: 'No se recibieron datos'
        };
    } catch (error) {
        console.error('Error en getSites:', error);
        return {
            success: false,
            message: error.response?.data?.message || 'Error al obtener los sitios',
            error: error.response?.data
        };
    }
};

/**
 * Verifica el estado de la autenticación
 * @returns {Promise<Object>} Resultado de la verificación
 */
export const checkAuthStatus = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return { success: false, message: 'No token found' };
        }

        const response = await axiosInstance.get('/auth/check');
        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        return handleApiError(error, 'Error al verificar la autenticación');
    }
};