import { Box, Flex, Icon, Text, useColorModeValue, Tag } from "@chakra-ui/react";
import React from "react";

function TimelineRow(props) {
  const { logo, title, date, color, index, arrLength, onClick, data } = props;
  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  const questionConfig = {
    'Alumno': { render: (answer) => <Text fontSize="sm" color="gray.400" fontWeight="normal">Alumno: {answer ?? 'Sin respuesta'}</Text> },
    'Curso': { render: (answer) => <Text fontSize="sm" color="gray.400" fontWeight="normal">Curso: {answer ?? 'Sin respuesta'}</Text> },
    'Categoría entrevista': { render: (answer) => <Tag colorScheme={answer === 'Académica' ? 'green' : 'blue'} alignSelf="flex-start" maxWidth="fit-content">{answer ?? 'Otro categoría'}</Tag> }
  };

  const filteredMetadata = data?.responses?.filter(item => questionConfig.hasOwnProperty(item.question.text));

  return (
    <Flex alignItems="center" minH="78px" justifyContent="start" mb="5px" onClick={onClick}>
      <Flex direction="column" h="100%">
        <Icon
          as={logo}
          bg={bgIconColor}
          color={color}
          h={"30px"}
          w={"26px"}
          pe="6px"
          zIndex="1"
          position="relative"
          right={document.documentElement.dir === "rtl" ? "-8px" : ""}
          left={document.documentElement.dir === "rtl" ? "" : "-8px"}
        />
        <Box
          w="2px"
          bg="gray.200"
          h={index === arrLength - 1 ? "15px" : "100%"}
        ></Box>
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%">
        <Text fontSize="sm" color={textColor} fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="sm" color="gray.400" fontWeight="normal">
          {date}
        </Text>
        {filteredMetadata?.map(item => (
          <React.Fragment key={item.question.id}>
            {questionConfig[item.question.text].render(item.answer)}
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
}

export default TimelineRow;