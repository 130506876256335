// MobilePOSApp.js

import React, { useState, useCallback } from 'react';
import {
  Box,
  Flex,
  Button,
  Input,
  Avatar,
  Stack,
  Image,
  Text,
  Heading,
  IconButton,
  RadioGroup,
  Radio,
  SimpleGrid,
  useColorModeValue,
  HStack,
  VStack,
  InputGroup,
  InputLeftElement,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Badge,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  SettingsIcon,
  SearchIcon,
  MinusIcon,
  AddIcon,
  EditIcon,
  InfoOutlineIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import {
  AiOutlinePrinter,
  AiOutlineTable,
  AiOutlineCreditCard,
  AiOutlineDollarCircle,
} from 'react-icons/ai';
import TableManagement from './TableManagement';
import Dishes from './Dishes';

const products = [
  { id: 1, name: 'Cappuccino', price: 8, category: 'Hot Drink', image: 'https://placehold.co/80x80' },
  { id: 2, name: 'Matcha Latte', price: 10, category: 'Hot Drink', image: 'https://placehold.co/80x80' },
  { id: 3, name: 'Iced Coffee', price: 8, category: 'Cold Drink', image: 'https://placehold.co/80x80' },
  { id: 4, name: 'Fruit Smoothies', price: 7, category: 'Cold Drink', image: 'https://placehold.co/80x80' },
  { id: 5, name: 'Chicken Sandwich', price: 12, category: 'Food', image: 'https://placehold.co/80x80' },
  { id: 6, name: 'Caesar Salad', price: 9, category: 'Food', image: 'https://placehold.co/80x80' },
  { id: 7, name: 'Margherita Pizza', price: 14, category: 'Food', image: 'https://placehold.co/80x80' },
  { id: 8, name: 'Green Tea', price: 6, category: 'Hot Drink', image: 'https://placehold.co/80x80' },
  { id: 9, name: 'Espresso', price: 5, category: 'Hot Drink', image: 'https://placehold.co/80x80' },
  { id: 10, name: 'Lemonade', price: 4, category: 'Cold Drink', image: 'https://placehold.co/80x80' },
];

const initialOrders = [
  { id: 2140, customer: 'Angel', items: [{ id: 1, quantity: 2 }, { id: 5, quantity: 1 }], status: 'ready', tableId: 2 },
  { id: 2145, customer: 'Lucas', items: [{ id: 3, quantity: 1 }, { id: 7, quantity: 1 }], status: 'pending', tableId: 2 },
  { id: 2141, customer: 'Marcus', items: [{ id: 3, quantity: 1 }, { id: 6, quantity: 1 }], status: 'cooking', tableId: 3 },
  { id: 2146, customer: 'Liam', items: [{ id: 4, quantity: 2 }, { id: 8, quantity: 1 }], status: 'delivered', tableId: 3 },
  { id: 2142, customer: 'Sophia', items: [{ id: 2, quantity: 1 }, { id: 7, quantity: 1 }], status: 'ready', tableId: 5 },
  { id: 2147, customer: 'Emma', items: [{ id: 1, quantity: 1 }, { id: 6, quantity: 1 }], status: 'pending', tableId: 5 },
  { id: 2143, customer: 'Liam', items: [{ id: 4, quantity: 2 }, { id: 8, quantity: 1 }], status: 'cooking', tableId: 1 },
  { id: 2144, customer: 'Emma', items: [{ id: 1, quantity: 1 }, { id: 6, quantity: 1 }], status: 'ready', tableId: 7 },
  { id: 2148, customer: 'Oliver', items: [{ id: 9, quantity: 2 }, { id: 10, quantity: 1 }], status: 'pending', tableId: 4 },
  { id: 2149, customer: 'Charlotte', items: [{ id: 2, quantity: 1 }, { id: 5, quantity: 2 }], status: 'cooking', tableId: 6 },
];

const initialTables = [
  { id: 1, name: 'Mesa 1', seats: 2, status: 'occupied' },
  { id: 2, name: 'Mesa 2', seats: 4, status: 'occupied' },
  { id: 3, name: 'Mesa 3', seats: 2, status: 'occupied' },
  { id: 4, name: 'Mesa 4', seats: 4, status: 'occupied' },
  { id: 5, name: 'Mesa 5', seats: 2, status: 'occupied' },
  { id: 6, name: 'Mesa 6', seats: 4, status: 'occupied' },
  { id: 7, name: 'Mesa 7', seats: 2, status: 'occupied' },
  { id: 8, name: 'Mesa 8', seats: 4, status: 'available' },
  { id: 9, name: 'Mesa 9', seats: 2, status: 'available' },
  { id: 10, name: 'Mesa 10', seats: 4, status: 'available' },
];

export default function MobilePOSApp() {
  const [selectedProducts, setSelectedProducts] = useState({});
  const [activeCategory, setActiveCategory] = useState('');
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [currentTableId, setCurrentTableId] = useState(null);
  const [customer, setCustomer] = useState('Ever');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [searchQuery, setSearchQuery] = useState('');
  const [orders, setOrders] = useState(initialOrders);
  const [tables, setTables] = useState(initialTables);
  const [productsState, setProductsState] = useState(products);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState('orders');

  const currentTable = tables.find((t) => t.id === currentTableId);
  const tableOrders = currentTableId
    ? orders.filter((order) => order.tableId === currentTableId)
    : orders;
  const categories = [...new Set(productsState.map((product) => product.category))];

  const filteredProducts = productsState.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (activeCategory === '' || product.category === activeCategory)
  );

  const addToOrder = useCallback((productId, quantity) => {
    setSelectedProducts((prev) => {
      const newQuantity = (prev[productId] || 0) + quantity;
      if (newQuantity <= 0) {
        const { [productId]: _, ...rest } = prev;
        return rest;
      }
      return { ...prev, [productId]: newQuantity };
    });
  }, []);

  const updateOrderItemQuantity = useCallback(
    (productId, quantityChange) => {
      if (selectedOrder) {
        const updatedItems = selectedOrder.items
          .map((item) => {
            if (item.id === productId) {
              return { ...item, quantity: item.quantity + quantityChange };
            }
            return item;
          })
          .filter((item) => item.quantity > 0);

        const updatedOrder = { ...selectedOrder, items: updatedItems };
        setSelectedOrder(updatedOrder);

        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.id === selectedOrder.id ? updatedOrder : order))
        );
      } else {
        addToOrder(productId, quantityChange);
      }
    },
    [selectedOrder, addToOrder]
  );

  const totalItems = Object.values(selectedProducts).reduce((sum, quantity) => sum + quantity, 0);
  const totalPrice = productsState.reduce(
    (sum, product) => sum + (selectedProducts[product.id] || 0) * product.price,
    0
  );

  const handleOrderClick = useCallback((order) => {
    setSelectedOrder(order);
    setShowOrderDetails(true);
  }, []);

  const handleChangeTable = useCallback(
    (newTableId) => {
      setCurrentTableId(newTableId);
      setIsTableModalOpen(false);

      if (selectedOrder) {
        const updatedOrder = { ...selectedOrder, tableId: newTableId };
        setSelectedOrder(updatedOrder);

        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.id === selectedOrder.id ? updatedOrder : order))
        );
      }
    },
    [selectedOrder]
  );

  const handleChangeCustomer = useCallback(
    (newCustomer) => {
      setCustomer(newCustomer);
      setIsCustomerModalOpen(false);

      if (selectedOrder) {
        const updatedOrder = { ...selectedOrder, customer: newCustomer };
        setSelectedOrder(updatedOrder);

        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.id === selectedOrder.id ? updatedOrder : order))
        );
      }
    },
    [selectedOrder]
  );

  const handlePlaceOrder = useCallback(() => {
    const newOrder = {
      id: Date.now(),
      customer,
      items: Object.entries(selectedProducts).map(([id, quantity]) => ({
        id: parseInt(id),
        quantity,
      })),
      status: 'pending',
      tableId: currentTableId,
    };

    setOrders((prevOrders) => [...prevOrders, newOrder]);

    if (currentTableId) {
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.id === currentTableId ? { ...table, status: 'occupied' } : table
        )
      );
    }

    setSelectedProducts({});
    setShowOrderDetails(false);
    setCustomer('Ever');
    setPaymentMethod('card');
  }, [customer, selectedProducts, currentTableId]);

  const handleUpdateOrders = useCallback((updatedOrders) => {
    setOrders(updatedOrders);
  }, []);

  const handleUpdateProducts = useCallback((updatedProducts) => {
    setProductsState(updatedProducts);
  }, []);

    const updateOrderStatus = (orderId, newStatus) => {
    setOrders((prevOrders) =>
        prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: newStatus } : order
        )
    );
    // Actualiza el estado de la orden seleccionada también
    if (selectedOrder && selectedOrder.id === orderId) {
        setSelectedOrder((prevOrder) => ({ ...prevOrder, status: newStatus }));
    }
    };

  return (
    <Box h="100vh" display="flex" flexDirection="column" bg={useColorModeValue('gray.50', 'gray.900')}>
      {showOrderDetails ? (
        <OrderDetailsScreen
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setShowOrderDetails={setShowOrderDetails}
          productsState={productsState}
          updateOrderItemQuantity={updateOrderItemQuantity}
          currentTable={currentTable}
          setIsTableModalOpen={setIsTableModalOpen}
          isTableModalOpen={isTableModalOpen}
          handleChangeTable={handleChangeTable}
          tables={tables}
          currentTableId={currentTableId}
          customer={customer}
          setCustomer={setCustomer}
          isCustomerModalOpen={isCustomerModalOpen}
          setIsCustomerModalOpen={setIsCustomerModalOpen}
          handleChangeCustomer={handleChangeCustomer}
          handlePlaceOrder={handlePlaceOrder}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          selectedProducts={selectedProducts}
          updateOrderStatus={updateOrderStatus}
        />
      ) : (
        <MainOrderScreen
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          viewMode={viewMode}
          setViewMode={setViewMode}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          filteredProducts={filteredProducts}
          selectedProducts={selectedProducts}
          addToOrder={addToOrder}
          totalItems={totalItems}
          totalPrice={totalPrice}
          handleOrderClick={handleOrderClick}
          tableOrders={tableOrders}
          tables={tables}
          currentTable={currentTable}
          setCurrentTableId={setCurrentTableId}
          setShowOrderDetails={setShowOrderDetails}
          categories={categories}
          orders={orders}
          setOrders={setOrders}
          handleUpdateProducts={handleUpdateProducts}
          productsState={productsState}
        />
      )}
    </Box>
  );
}

const MainOrderScreen = ({
  searchQuery,
  setSearchQuery,
  viewMode,
  setViewMode,
  activeCategory,
  setActiveCategory,
  filteredProducts,
  selectedProducts,
  addToOrder,
  totalItems,
  totalPrice,
  handleOrderClick,
  tableOrders,
  tables,
  currentTable,
  setCurrentTableId,
  setShowOrderDetails,
  categories,
  orders,
  setOrders,
  handleUpdateProducts,
  productsState,
}) => {
  const bgSelected = useColorModeValue('green.100', 'green.900');
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const cardColor = useColorModeValue('white', 'gray.800');

  return (
    <>
      <Flex p={4} align="center" justify="center">
        <HStack align="center">
          <Text fontWeight="bold">Comida Rapida</Text>
          <Text fontSize="sm" color="gray.500">
            Mesero
          </Text>
        </HStack>
      </Flex>
      <Flex p={4} justify="space-between" align="center">
        <HStack spacing={2}>
          <Button
            size="sm"
            onClick={() => setViewMode('orders')}
            colorScheme={viewMode === 'orders' ? 'green' : 'gray'}
          >
            Órdenes
          </Button>
          <Button
            size="sm"
            onClick={() => setViewMode('tables')}
            colorScheme={viewMode === 'tables' ? 'green' : 'gray'}
          >
            Mesas
          </Button>
          <Button
            size="sm"
            onClick={() => setViewMode('dishes')}
            colorScheme={viewMode === 'dishes' ? 'green' : 'gray'}
          >
            Platos
          </Button>
        </HStack>

        <IconButton icon={<SettingsIcon />} aria-label="Settings" />
      </Flex>

      {viewMode === 'orders' ? (
        <>
          <Box px={4} py={2}>
            <Flex justify="space-between" align="center" mb={4}>
              <Heading size="md">
                Órdenes {currentTable ? `para ${currentTable.name}` : 'Todas'}
              </Heading>
              {currentTable && (
                <Button size="sm" leftIcon={<CloseIcon />} onClick={() => setCurrentTableId(null)}>
                  Ver todas
                </Button>
              )}
            </Flex>
            <Flex gap={4} overflowX="scroll" pb={4}>
              {tableOrders.map((order) => (
                <Box
                  key={order.id}
                  w="200px"
                  p={4}
                  bg={
                    order.status === 'ready'
                      ? 'green.100'
                      : order.status === 'pending'
                      ? 'orange.100'
                      : 'yellow.100'
                  }
                  rounded="md"
                  onClick={() => handleOrderClick(order)}
                  cursor="pointer"
                  boxShadow="md"
                  transition="transform 0.2s"
                  _hover={{ transform: 'scale(1.05)' }}
                >
                  <Text
                    fontWeight="bold"
                    color={order.status === 'ready' ? 'green.700' : 'yellow.700'}
                  >
                    {order.status === 'ready'
                      ? 'Listo para servir'
                      : order.status === 'pending'
                      ? 'Pendiente'
                      : 'En preparación'}
                  </Text>
                  <Flex align="center" mt={2}>
                    <Avatar size="sm" name={order.customer} />
                    <Text ml={2} fontSize="sm">
                      {order.customer}
                    </Text>
                  </Flex>
                  <Text mt={1} fontSize="sm" color="gray.500">
                    {order.items.length} items
                  </Text>
                  <Badge mt={2}>{tables.find((t) => t.id === order.tableId)?.name}</Badge>
                </Box>
              ))}
            </Flex>
          </Box>

          <Flex h="full" direction="column">
            <Box p={4} borderBottom="1px solid" borderColor="gray.200">
              <Text fontWeight="bold" mb={4}>
                Categorías
              </Text>
              <Box overflowX="auto">
                <HStack spacing={2} minWidth="max-content">
                  <Button
                    variant={activeCategory === '' ? 'solid' : 'ghost'}
                    onClick={() => setActiveCategory('')}
                  >
                    Todas
                  </Button>
                  {categories.map((category) => (
                    <Button
                      key={category}
                      variant={activeCategory === category ? 'solid' : 'ghost'}
                      onClick={() => setActiveCategory(category)}
                    >
                      {category}
                    </Button>
                  ))}
                </HStack>
              </Box>
            </Box>

            <Box flex="1" p={4}>
              <InputGroup mb={4}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Buscar en el menú"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>

              <SimpleGrid columns={[2, 3]} spacing={4}>
                {filteredProducts.map((product) => (
                  <Box
                    key={product.id}
                    bg={selectedProducts[product.id] ? bgSelected : cardColor}
                    p={4}
                    borderRadius="md"
                    boxShadow="md"
                    transition="transform 0.2s"
                    _hover={{ transform: 'scale(1.05)' }}
                  >
                    <Image
                      src={product.image}
                      boxSize="80px"
                      objectFit="cover"
                      borderRadius="md"
                      mb={2}
                    />
                    <Text fontWeight="bold">{product.name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      ${product.price}
                    </Text>
                    <HStack mt={2} spacing={4} justifyContent="center">
                      <IconButton
                        icon={<MinusIcon />}
                        aria-label="Decrease"
                        onClick={() => addToOrder(product.id, -1)}
                        size="sm"
                        isDisabled={!selectedProducts[product.id]}
                      />
                      <Text>{selectedProducts[product.id] || 0}</Text>
                      <IconButton
                        icon={<AddIcon />}
                        aria-label="Increase"
                        onClick={() => addToOrder(product.id, 1)}
                        size="sm"
                      />
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </Flex>

          <Box p={4} bg={bgColor} position="fixed" bottom={0} left={0} right={0} zIndex={1000}>
            <Button
              w="full"
              colorScheme="green"
              isDisabled={totalItems === 0}
              onClick={() => setShowOrderDetails(true)}
              transition="background-color 0.2s"
              _hover={{ bg: 'green.600' }}
            >
              Nueva orden ({totalItems} items - ${totalPrice.toFixed(2)})
            </Button>
          </Box>
        </>
      ) : viewMode === 'tables' ? (
        <TableManagement
          orders={orders}
          tables={tables}
          onSelectTable={(tableId) => {
            setCurrentTableId(tableId);
            setViewMode('orders');
          }}
          onClose={() => setViewMode('orders')}
        />
      ) : viewMode === 'dishes' ? (
        <Dishes
          products={productsState}
          onUpdateProducts={handleUpdateProducts}
        />
      ) : null}
    </>
  );
};

const OrderDetailsScreen = ({
  selectedOrder,
  setSelectedOrder,
  setShowOrderDetails,
  productsState,
  updateOrderItemQuantity,
  currentTable,
  setIsTableModalOpen,
  isTableModalOpen,
  handleChangeTable,
  tables,
  currentTableId,
  customer,
  setCustomer,
  isCustomerModalOpen,
  setIsCustomerModalOpen,
  handleChangeCustomer,
  handlePlaceOrder,
  paymentMethod,
  setPaymentMethod,
  selectedProducts,
  updateOrderStatus,
}) => {
  const items = selectedOrder
    ? selectedOrder.items
    : Object.entries(selectedProducts).map(([id, quantity]) => ({
        id: parseInt(id),
        quantity,
      }));

  const subtotal = items.reduce((sum, item) => {
    const product = productsState.find((p) => p.id === item.id);
    return sum + product.price * item.quantity;
  }, 0);

  const handleStatusChange = (newStatus) => {
    if (selectedOrder) {
      updateOrderStatus(selectedOrder.id, newStatus);
    }
  };

  return (
    <>
      <Flex p={4} justify="space-between" align="center">
        <IconButton
          icon={<ChevronLeftIcon />}
          aria-label="Back"
          onClick={() => {
            setSelectedOrder(null);
            setShowOrderDetails(false);
            }}
          />
          <Heading size="md">Detalle de la orden</Heading>
          <IconButton icon={<SettingsIcon />} aria-label="Settings" />
          </Flex>

          <Box p={4}>
          {selectedOrder && (
            <HStack spacing={4} mt={4}>
            <Button
              colorScheme={selectedOrder?.status === 'ready' ? 'green' : 'gray'}
              onClick={() => handleStatusChange('ready')}
            >
              Listo
            </Button>
            <Button
              colorScheme={selectedOrder?.status === 'pending' ? 'yellow' : 'gray'}
              onClick={() => handleStatusChange('pending')}
            >
              Pendiente
            </Button>
            <Button
              colorScheme={selectedOrder?.status === 'cooking' ? 'orange' : 'gray'}
              onClick={() => handleStatusChange('cooking')}
            >
              Cocinando
            </Button>
            <Button
              colorScheme={selectedOrder?.status === 'delivered' ? 'blue' : 'gray'}
              onClick={() => handleStatusChange('delivered')}
            >
              Entregado
            </Button>
            </HStack>
          )}
          </Box>

          <Box p={4} overflowY="auto" flex="1">
          <VStack align="start" spacing={6}>
            <Flex justify="space-between" align="center" w="full">
            <Flex align="center">
              <Avatar size="lg" bg="blue.500" color="white" icon={<AiOutlineTable />} />
              <Box ml={4}>
              <Text fontWeight="medium">Mesa</Text>
              <Text fontSize="sm" color="gray.500">
                {currentTable ? currentTable.name : 'Sin asignar'}
              </Text>
              </Box>
            </Flex>
            <IconButton
              icon={<EditIcon />}
              aria-label="Editar Mesa"
              onClick={() => setIsTableModalOpen(true)}
            />
            </Flex>

            <Divider />

            <Flex justify="space-between" align="center" w="full">
            <Flex align="center">
              <Avatar size="lg" bg="green.500" color="white" icon={<AiOutlineCreditCard />} />
              <Box ml={4}>
              <Text fontWeight="medium">Cliente</Text>
              <Text fontSize="sm" color="gray.500">
                {selectedOrder ? selectedOrder.customer : customer}
              </Text>
              </Box>
            </Flex>
            <IconButton
              icon={<EditIcon />}
              aria-label="Editar Cliente"
              onClick={() => setIsCustomerModalOpen(true)}
            />
            </Flex>

            <Divider />

            <Box w="full">
            <Heading size="sm" mb={2}>
              Items
            </Heading>
            {items.map((item) => {
              const product = productsState.find((p) => p.id === item.id);
              if (!product) return null;
              return (
                <Flex key={item.id} justify="space-between" align="center" mb={4}>
                  <Flex align="center">
                    <Image
                      src={product.image}
                      boxSize="50px"
                      objectFit="cover"
                      borderRadius="md"
                    />
                    <Box ml={4}>
                      <Text fontWeight="medium">{product.name}</Text>
                      <Text fontSize="sm" color="gray.500">
                        ${product.price} x {item.quantity}
                      </Text>
                    </Box>
                  </Flex>
                  <HStack spacing={2}>
                    <IconButton
                      icon={<MinusIcon />}
                      aria-label="Decrease"
                      onClick={() => updateOrderItemQuantity(product.id, -1)}
                      size="sm"
                      isDisabled={item.quantity <= 1}
                    />
                    <Text>{item.quantity}</Text>
                    <IconButton
                      icon={<AddIcon />}
                      aria-label="Increase"
                      onClick={() => updateOrderItemQuantity(product.id, 1)}
                      size="sm"
                    />
                  </HStack>
                </Flex>
              );
            })}
          </Box>

          <Divider />

          <Box w="full">
            <Heading size="sm" mb={2}>
              Resumen
            </Heading>
            <VStack w="full" spacing={2}>
              <Flex justify="space-between" w="full">
                <Text>Subtotal</Text>
                <Text>${subtotal.toFixed(2)}</Text>
              </Flex>
              <Flex justify="space-between" w="full">
                <Text>IVA (10%)</Text>
                <Text>${(subtotal * 0.1).toFixed(2)}</Text>
              </Flex>
              <Flex justify="space-between" w="full" fontWeight="bold">
                <Text>Total</Text>
                <Text>${(subtotal * 1.1).toFixed(2)}</Text>
              </Flex>
            </VStack>
          </Box>

          <Divider />

          <Box w="full">
            <Heading size="sm" mb={2}>
              Método de pago
            </Heading>
            <RadioGroup onChange={setPaymentMethod} value={paymentMethod}>
              <HStack spacing={4}>
                <Radio value="cash">
                  <HStack>
                    <AiOutlineDollarCircle />
                    <Text>Efectivo</Text>
                  </HStack>
                </Radio>
                <Radio value="card">
                  <HStack>
                    <AiOutlineCreditCard />
                    <Text>Tarjeta</Text>
                  </HStack>
                </Radio>
                <Radio value="scan">
                  <HStack>
                    <InfoOutlineIcon />
                    <Text>Scan</Text>
                  </HStack>
                </Radio>
              </HStack>
            </RadioGroup>
          </Box>

        </VStack>
      </Box>

      <Flex p={4} justify="space-between" align="center">
        <IconButton icon={<AiOutlinePrinter />} aria-label="Print" />
        <Button
          flex="1"
          colorScheme="green"
          ml={4}
          onClick={selectedOrder ? () => setShowOrderDetails(false) : handlePlaceOrder}
          transition="background-color 0.2s"
          _hover={{ bg: 'green.600' }}
        >
          {selectedOrder ? 'Actualizar Orden' : 'Confirmar Orden'}
        </Button>
      </Flex>

      <Modal isOpen={isTableModalOpen} onClose={() => setIsTableModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cambiar Mesa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Mesa</FormLabel>
              <Select
                value={currentTableId || ''}
                onChange={(e) =>
                  handleChangeTable(e.target.value ? parseInt(e.target.value) : null)
                }
              >
                <option value="">Sin asignar</option>
                {tables.map((table) => (
                  <option key={table.id} value={table.id}>
                    {table.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsTableModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCustomerModalOpen} onClose={() => setIsCustomerModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cambiar Cliente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Nombre del Cliente</FormLabel>
              <Input
                value={selectedOrder ? selectedOrder.customer : customer}
                onChange={(e) => setCustomer(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsCustomerModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};