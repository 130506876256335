// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Select,
    HStack,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
    Image,
    Tag,
    Avatar,
    Badge,
  } from "@chakra-ui/react";
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, { useState, useEffect } from "react";
  import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import { useAppContext } from "../../../../context/appContext";
  import { updateCustomer, listOrdersCustomer, getProducts, deleteOrder } from "../../../../services/woo";
  import { arancelesList } from "../../../../services/cursosList";
import { map } from "lodash";

  function BatchUsersOrderCreate({title, customers, productsList}) {
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
    const { customersSelected, handleUpdateData } = useAppContext();
    const [month, setMonth] = useState("2024-03");
    const [log, setLog] = useState([]);
    const [loadingButtonFind, setLoadingButtonFind] = useState(false);
    const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
    const [products, setProducts] = useState([]);
    const [suscriptionName, setSuscriptionName] = useState('')
    const [ordersFound, setOrdersFound] = useState([])

    const month_cuota = _.find(arancelesList, { month: month })
    const format_month = month_cuota?.month.split('-')[1]
    const format_year = month_cuota?.month.split('-')[0]
  
    const deleteOrderSuscription = async () => {
        setLoadingButtonDelete(true)
        const deletedOrder = []
        for(const order of ordersFound) {
            const orderDeleted = await deleteOrder(order.order_id)
            console.log(orderDeleted)
            deletedOrder.push(orderDeleted)
        }
        console.log(month, deletedOrder)
        setLoadingButtonDelete(false)
        handleUpdateData()
    }

    const findOrderCustomers = async (mes, customers) => {
        setLoadingButtonFind(true)
        
        const orders_customer = []
        const ordersCustomerFound = []
        for (const customer of customers) {
            const orders = []
            const ordersCustomer = await listOrdersCustomer(customer);

            for (const order of ordersCustomer) {
                const getOrder = {
                    'meta_data' : order.meta_data,
                    'mes' : _.find(order.line_items[0].meta_data, {key: 'mes'}).value, // order.line_items[0].meta_data o order.meta_data
                    'order_id': order.id,
                    'customer_id': order.customer_id,
                    'status' : order.status,
                    'total' : order.total
                }                
                orders.push(getOrder)
            }
            
            const findOrder = _.find(orders, { mes: mes })
            console.log('buscando mes', mes)
            console.log(findOrder)
            findOrder && ordersCustomerFound.push(findOrder)

            orders_customer.push({ 
                    'customer' : customer,
                    'orders' : orders,
                    'order_found' : findOrder
                })
        }
        setLoadingButtonFind(false)
        console.log(orders_customer)
        setOrdersFound(ordersCustomerFound)
        return ordersCustomerFound
    }

    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} border={{ xl: "1px"}} mb="6">
          <CardHeader p='6px 0px 22px 0px'>
              <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              {title || 'Borrar orden por Alumno'}
              </Text>
          </CardHeader>
          <CardBody>
            <Box w={'100%'}>
                <Text fontSize={'2xl'} width="100%">
                  <Badge fontSize='0.8em' colorScheme='teal'>
                  {customers.length} usuarios seleccionados
                  </Badge>
                </Text>
                <Text>Mes: {month}</Text>

                <FormControl>
                    <Input
                    fontSize='sm'
                    borderRadius='15px'
                    type='month'
                    placeholder='month'
                    mb='24px'
                    size='lg'
                    onChange={(e) => setMonth(e.target.value)}
                    />
                    <Button
                    type='submit'
                    bg='teal.300'
                    fontSize='14px'
                    color='white'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    onClick={() => findOrderCustomers(month, customers)}
                    isLoading={loadingButtonFind}
                    >
                    BUSCAR COBRO
                    </Button>

                    {ordersFound.length > 0 &&
                    <Button
                    type='submit'
                    bg='teal.300'
                    fontSize='14px'
                    color='white'
                    fontWeight='bold'
                    w='100%'
                    h='45'
                    mb='24px'
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    onClick={() => deleteOrderSuscription(month, customers)}
                    isLoading={loadingButtonDelete}
                    >
                    ELIMINAR COBRO
                    </Button>
                    }
                </FormControl>

                <Box>
                    {ordersFound.length > 0 ? ordersFound.length + 'Resultados' : 'Sin resultados' }
                    {/* <p>{JSON.stringify(ordersFound)}</p> */}
                    <ul>
                        {ordersFound.map((order, key) => {
                            return (
                            <li key={key}>#ID {order.order_id} - Mes: {order.mes} - Estado: {order.status} - Usuario: #{order.customer_id}</li>
                            )
                        })}
                    </ul>
                </Box>
          </Box>
          </CardBody>
        </Card>
    );
  }
  
export default BatchUsersOrderCreate;
  