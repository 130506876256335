import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useBreakpointValue,
  Flex,
  Box,
  Td,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import React from "react";
import ModalOrderEdit from "components/Modal/ModalOrderEdit";

// Projects.js
const Projects = ({ title, captions, data, isLoading }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalOrder, setModalOrder] = React.useState({
    id: '',
    meta_data: [{ key: 'ejemplo', value: 'orden woo' }],
    name: '',
    price: '',
    quantity: '',
  });
  const [modalCustomer, setModalCustomer] = React.useState({
    first_name: '',
    last_name: '',
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleRowClick = (rowData) => {
    setModalOrder(rowData);
    setIsModalOpen(true);
  };

  return (
    <>
      <ModalOrderEdit 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        order={modalOrder} 
        action={'edit'} 
        customer={modalCustomer} 
      />

      <Card p='16px'>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex justify="space-between" align="center">
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              {title}
            </Text>
          </Flex>
        </CardHeader>
        
        <Box position="relative" minHeight="200px" overflowX={isMobile ? "hidden" : "auto"}>
          <Table variant='simple' color={textColor}>
            {!isMobile && (
              <Thead>
                <Tr>
                  {captions.map((caption, idx) => (
                    <Th color='gray.400' key={idx}>
                      {caption}
                    </Th>
                  ))}
                </Tr>
              </Thead>
            )}
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={isMobile ? 1 : captions.length}>
                    <Flex justify="center" py={8}>
                      <Text>Cargando...</Text>
                    </Flex>
                  </Td>
                </Tr>
              ) : data.length === 0 ? (
                <Tr>
                  <Td colSpan={isMobile ? 1 : captions.length}>
                    <Flex justify="center" py={8}>
                      <Text>No hay datos disponibles</Text>
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                data.map((row, key) => (
                  <DashboardTableRow
                    key={key}
                    {...row}
                    onClick={() => handleRowClick(row)}
                  />
                ))
              )}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </>
  );
};

export default Projects;