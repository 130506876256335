import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Flex,
  Link,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import box3d from "assets/svg/box-3d.svg";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { transformarFecha } from "utils/site";

const Files = ({ title, data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    console.log(data);
  } , [data]);

  return (
    <Card p="16px" my="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" 
        w="100%" 
        overflowY={{ sm: "auto" }}
        maxHeight={{ sm: "200px" }}
        className="custom-scrollbar"
        pr={{ sm: "16px" }}
        >
          {data?.success ? (
            data.data.length > 1 ? (
            data.data.map((item, index) => {
              const filename = JSON.parse(item.filenames)[0];
              const fileUrl = `${process.env.REACT_APP_SURVEY_API_URL}${filename}`;
              const fileNameOnly = filename.split("/").pop();
              const fileFormat = fileNameOnly.split(".").pop();
              const created_at = transformarFecha(item.created_at);

              return (
                <Flex justifyContent="space-between" mb="21px" key={index}>
                  <Flex align="center">
                    <Avatar
                      src={box3d}
                      w="30px"
                      h="30px"
                      borderRadius="15px"
                      me="10px"
                    />
                    <Flex direction="column">
                      <Text fontSize="sm" color={textColor} fontWeight="bold">
                        <Tag>{fileFormat}</Tag> {fileNameOnly}
                      </Text>
                      <Text fontSize="xs" color="gray.500" fontWeight="400">
                        {created_at}
                      </Text>
                    </Flex>
                  </Flex>
                  <Link href={fileUrl} isExternal>
                    <Button p="0px" bg="transparent" variant="no-hover">
                      <Text
                        fontSize="sm"
                        fontWeight="600"
                        color="teal.300"
                        alignSelf="center"
                      >
                        VER
                      </Text>
                    </Button>
                  </Link>
                </Flex>
              );
            })
          ) : (
            <Text fontSize="sm" color='gray.500' fontWeight='400'>
              No hay archivos registrados
            </Text>
          ) ) : (
            <Text fontSize="sm" color='gray.500' fontWeight='400'>
              Esta cuenta no registra actividad
            </Text>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default Files;