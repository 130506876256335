import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  useToast,
} from '@chakra-ui/react';

const AddTicketModal = ({ isOpen, onClose, onAddTicket }) => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    stockQuantity: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const newTicket = {
        ...formData,
        price: parseFloat(formData.price),
        stockQuantity: parseInt(formData.stockQuantity),
        stock_status: 'instock',
      };
      
      await onAddTicket(newTicket);
      
      toast({
        title: 'Ticket creado',
        status: 'success',
        duration: 3000,
      });
      
      onClose();
      setFormData({ name: '', price: '', stockQuantity: '' });
    } catch (error) {
      toast({
        title: 'Error al crear ticket',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Agregar Nuevo Ticket</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Nombre</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Nombre del ticket"
                />
              </FormControl>
              
              <FormControl isRequired>
                <FormLabel>Precio</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    name="price"
                    value={formData.price}
                    onChange={(value) => handleChange({ target: { name: 'price', value } })}
                    placeholder="Precio del ticket"
                  />
                </NumberInput>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Cantidad Disponible</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    name="stockQuantity"
                    value={formData.stockQuantity}
                    onChange={(value) => handleChange({ target: { name: 'stockQuantity', value } })}
                    placeholder="Cantidad de tickets disponibles"
                  />
                </NumberInput>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={isLoading}
            >
              Crear Ticket
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddTicketModal;