import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Input,
  IconButton,
  Button,
  SimpleGrid,
  Image,
  Stack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';

export default function Dishes({ products, onUpdateProducts }) {
  const [categories, setCategories] = useState([...new Set(products.map((p) => p.category))]);
  const [activeCategory, setActiveCategory] = useState(categories[0] || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isDishModalOpen, setIsDishModalOpen] = useState(false);
  const [newDish, setNewDish] = useState({ name: '', price: '', category: '', image: '' });

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (activeCategory === '' || product.category === activeCategory)
  );

  const handleAddCategory = () => {
    if (newCategoryName && !categories.includes(newCategoryName)) {
      setCategories([...categories, newCategoryName]);
      setActiveCategory(newCategoryName);
      setNewCategoryName('');
      setIsCategoryModalOpen(false);
    }
  };

  const handleAddDish = () => {
    const newProduct = {
      id: Date.now(),
      name: newDish.name,
      price: parseFloat(newDish.price),
      category: newDish.category || activeCategory,
      image: newDish.image || 'https://placehold.co/80x80',
    };
    onUpdateProducts([...products, newProduct]);
    setNewDish({ name: '', price: '', category: '', image: '' });
    setIsDishModalOpen(false);
  };

  const cardColor = useColorModeValue('white', 'gray.800');

  return (
    <Flex h="full" direction="column">
      <Box p={4} borderBottom="1px solid" borderColor="gray.200">
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontWeight="bold">Categorías</Text>
          <IconButton
            icon={<AddIcon />}
            size="sm"
            aria-label="Agregar Categoría"
            onClick={() => setIsCategoryModalOpen(true)}
          />
        </Flex>
        <Box overflowX="auto">
          <HStack spacing={2} minWidth="max-content">
            <Button
              variant={activeCategory === '' ? 'solid' : 'ghost'}
              onClick={() => setActiveCategory('')}
            >
              Todas
            </Button>
            {categories.map((category) => (
              <Button
                key={category}
                variant={activeCategory === category ? 'solid' : 'ghost'}
                onClick={() => setActiveCategory(category)}
              >
                {category}
              </Button>
            ))}
          </HStack>
        </Box>
      </Box>

      <Box flex="1" p={4}>
        <Flex mb={4} justify="space-between" align="center">
          <InputGroup w="60%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Buscar platos"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
          <Button leftIcon={<AddIcon />} colorScheme="green" onClick={() => setIsDishModalOpen(true)}>
            Agregar
          </Button>
        </Flex>

        <SimpleGrid columns={[2, 3]} spacing={4}>
          {filteredProducts.map((product) => (
            <Box
              key={product.id}
              bg={cardColor}
              p={4}
              borderRadius="md"
              boxShadow="md"
            >
              <Image
                src={product.image}
                boxSize="80px"
                objectFit="cover"
                borderRadius="md"
                mb={2}
              />
              <Text fontWeight="bold">{product.name}</Text>
              <Text fontSize="sm" color="gray.500">
                ${product.price}
              </Text>
              <HStack mt={2} spacing={2}>
                <IconButton icon={<EditIcon />} aria-label="Editar" size="sm" />
                <IconButton icon={<DeleteIcon />} aria-label="Eliminar" size="sm" />
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      {/* Add Category Modal */}
      <Modal isOpen={isCategoryModalOpen} onClose={() => setIsCategoryModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar Categoría</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Nombre de la Categoría</FormLabel>
              <Input
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleAddCategory}>
              Agregar
            </Button>
            <Button variant="ghost" onClick={() => setIsCategoryModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Dish Modal */}
      <Modal isOpen={isDishModalOpen} onClose={() => setIsDishModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar Plato</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Nombre del Plato</FormLabel>
              <Input
                value={newDish.name}
                onChange={(e) => setNewDish({ ...newDish, name: e.target.value })}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Precio</FormLabel>
              <Input
                type="number"
                value={newDish.price}
                onChange={(e) => setNewDish({ ...newDish, price: e.target.value })}
              />
            </FormControl>
            <FormControl mb={3}>
              <FormLabel>Categoría</FormLabel>
              <Select
                value={newDish.category || activeCategory}
                onChange={(e) => setNewDish({ ...newDish, category: e.target.value })}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Imagen (URL)</FormLabel>
              <Input
                value={newDish.image}
                onChange={(e) => setNewDish({ ...newDish, image: e.target.value })}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleAddDish}>
              Agregar
            </Button>
            <Button variant="ghost" onClick={() => setIsDishModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}