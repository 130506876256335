import React, { useMemo } from 'react';
import {
  Grid,
  GridItem,
  Box,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const SalesStats = ({ orders }) => {
  // Color mode values
  const titleColor = useColorModeValue("teal.600", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const statBg = useColorModeValue("white", "gray.700");
  const labelColor = useColorModeValue("gray.500", "gray.400");

  // Colores para los gráficos - matices de teal
  const COLORS = [
    useColorModeValue("#319795", "#4FD1C5"), // teal.600, teal.300
    useColorModeValue("#2C7A7B", "#38B2AC"), // teal.700, teal.400
    useColorModeValue("#285E61", "#319795"), // teal.800, teal.500
    useColorModeValue("#234E52", "#2C7A7B"), // teal.900, teal.600
    useColorModeValue("#1D4044", "#285E61"), // teal.950, teal.700
  ];

  // Usar el hook para obtener el resumen general
  const { totalAmount, totalTickets, formattedAveragePrice } = useSalesStats(orders);

  // Análisis por hora del día
  const hourlyData = useMemo(() => {
    const hourCount = orders.reduce((acc, order) => {
      const hour = new Date(order.date_created).getHours();
      acc[hour] = (acc[hour] || 0) + 1;
      return acc;
    }, {});

    return Array.from({ length: 24 }, (_, hour) => ({
      name: `${hour.toString().padStart(2, '0')}:00`,
      ventas: hourCount[hour] || 0
    }));
  }, [orders]);

  // Análisis de tipos de ticket
  const ticketTypeData = useMemo(() => {
    const typeCount = orders.reduce((acc, order) => {
      const ticketType = order.line_items[0]?.meta_data?.find(
        m => m.key === 'pa_tipo-de-ticket'
      )?.display_value || 'Sin tipo';
      
      acc[ticketType] = (acc[ticketType] || 0) + (order.line_items[0]?.quantity || 0);
      return acc;
    }, {});

    return Object.entries(typeCount).map(([name, value]) => ({
      name,
      value
    }));
  }, [orders]);

  // Análisis de tasa de conversión
  const conversionStats = useMemo(() => {
    const total = orders.length;
    const used = orders.filter(order => order.status === 'used').length;
    const completed = orders.filter(order => order.status === 'completed').length;

    return {
      totalTickets: total,
      usedTickets: used,
      completedTickets: completed,
      useRate: ((used / total) * 100).toFixed(1),
      completionRate: ((completed / total) * 100).toFixed(1)
    };
  }, [orders]);

  // Análisis de dispositivos
  const deviceStats = useMemo(() => {
    const deviceCount = orders.reduce((acc, order) => {
      const userAgent = order.customer_user_agent?.toLowerCase() || '';
      let device = 'Desconocido';
      
      if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        device = 'iOS';
      } else if (userAgent.includes('android')) {
        device = 'Android';
      } else if (!userAgent.includes('mobile')) {
        device = 'Desktop';
      }

      acc[device] = (acc[device] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(deviceCount).map(([name, value]) => ({
      name,
      value
    }));
  }, [orders]);

  return (
    <Box>
      {/* Primera fila de gráficos */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={6}>
        {/* Gráfico de Tipos de Ticket */}
        <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="sm" borderWidth="1px" borderColor={borderColor}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Distribución por Tipo de Ticket
          </Text>
          <Box h="300px">
            {ticketTypeData.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={ticketTypeData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {ticketTypeData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: bgColor,
                      borderColor: borderColor,
                      borderRadius: '8px',
                      color: textColor
                    }}
                  />
                  <Legend 
                    formatter={(value) => <span style={{ color: textColor }}>{value}</span>}
                  />
                </PieChart>
              </ResponsiveContainer>
            )}
          </Box>
        </Box>

        {/* Gráfico de Ventas por Hora */}
        <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="sm">
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Ventas por Hora del Día
          </Text>
          <Box h="300px">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={hourlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="ventas" fill="#4FD1C5" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </SimpleGrid>

      {/* Segunda fila de gráficos */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
        {/* Tasa de Conversión */}
        <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="sm">
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Tasa de Tickets Escaneados
          </Text>
          <SimpleGrid columns={2} spacing={4}>
            <Stat>
              <StatLabel>Escaneados</StatLabel>
              <StatNumber>{conversionStats.useRate}%</StatNumber>
              <Text fontSize="sm" color="gray.500">
                {conversionStats.usedTickets} de {conversionStats.totalTickets} tickets
              </Text>
            </Stat>
            <Stat>
              <StatLabel>No Escaneados</StatLabel>
              <StatNumber>{conversionStats.completionRate}%</StatNumber>
              <Text fontSize="sm" color="gray.500">
                {conversionStats.completedTickets} de {conversionStats.totalTickets} tickets
              </Text>
            </Stat>
          </SimpleGrid>
        </Box>

        {/* Dispositivos */}
        <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="sm">
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Dispositivos Utilizados
          </Text>
          <Box h="300px">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={deviceStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {deviceStats.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default SalesStats;

export const useSalesStats = (orders) => {
  return useMemo(() => {
    const stats = orders.reduce((acc, order) => {
      const total = parseFloat(order.total) || 0;
      acc.totalAmount += total;
      acc.totalOrders += 1;
      acc.totalTickets += order.line_items[0]?.quantity || 0;
      return acc;
    }, {
      totalAmount: 0,
      totalOrders: 0,
      totalTickets: 0
    });

    stats.averageTicketPrice = stats.totalAmount / stats.totalTickets;
    
    return {
      ...stats,
      formattedTotalAmount: stats.totalAmount.toLocaleString('es-CL'),
      formattedAveragePrice: Math.round(stats.averageTicketPrice).toLocaleString('es-CL')
    };
  }, [orders]);
};