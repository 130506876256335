// Chakra imports
import {
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import React, { useState } from "react";
import { FaDollarSign, FaMoneyBill, FaSearch, FaUser, FaUserPlus, FaMailBulk } from "react-icons/fa";
import { Star } from "lucide-react";
import SalesDetailsModal from './SalesDetailsModal';
import { Clock } from 'lucide-react';
import Suscriptions from "views/Dashboard/Suscriptions";

const PaymentMethod = ({ title, productId, allOrders }) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
  const [isEmbajadoresModalOpen, setIsEmbajadoresModalOpen] = useState(false);
  const [isCortesiassModalOpen, setIsCortesiassModalOpen] = useState(false);
  
  return (
    <Card p='16px' mt='24px' mb='24px'>
        <CardHeader>
          <Flex justify='' align='center' minHeight='60px' w='100%' direction={{ sm: 'column', md: 'row' }}>
            <Text fontSize='lg' color={textColor} fontWeight='bold' mb={{ sm: '1rem', md: '0' }}>
              {title}
            </Text>
            <Button
              onClick={() => setIsSalesModalOpen(true)}
              colorScheme='teal'
              borderColor='teal.300'
              color='teal.300'
              variant='outline'
              fontSize='xs'
              p='8px 32px'
              mx='2'
              mb={{ sm: '1rem', md: '0' }}
              w={{ sm: '100%', md: 'auto' }}>
              <FaDollarSign style={{ marginRight: '5px' }} />
              VENTAS
            </Button>
            <Button
              colorScheme='teal'
              borderColor='teal.300'
              color='teal.300'
              variant='outline'
              fontSize='xs'
              p='8px 32px'
              mx='2'
              mb={{ sm: '1rem', md: '0' }}
              w={{ sm: '100%', md: 'auto' }}
              onClick={() => setIsEmbajadoresModalOpen(true)}>
                <Star style={{ marginRight: '5px' }} width={'15px'} />
              EMBAJADORES
            </Button>
            <Button
              colorScheme='teal'
              borderColor='teal.300'
              color='teal.300'
              variant='outline'
              fontSize='xs'
              p='8px 32px'
              mx='2'
              mb={{ sm: '1rem', md: '0' }}
              w={{ sm: '100%', md: 'auto' }}
              onClick={() => setIsCortesiassModalOpen(true)}
              >
              <FaMailBulk style={{ marginRight: '5px' }} />
              CORTESÍAS
            </Button>
          </Flex>
        </CardHeader>
      <CardBody>
      </CardBody>

      <SalesDetailsModal
        isOpen={isSalesModalOpen}
        onClose={() => setIsSalesModalOpen(false)}
        productId={productId}
        allOrders={allOrders}
      />
      
      <Modal isOpen={isEmbajadoresModalOpen} onClose={() => setIsEmbajadoresModalOpen(false)} size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Todos los embajadores</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Suscriptions 
              defaultGroup="Embajadores"
              showFilters={{ searchRaw: false, group: false, role: false }} 
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCortesiassModalOpen} onClose={() => setIsCortesiassModalOpen(false)} size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enviar cortesias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Suscriptions 
            customersConfig={{
              showActionsMenu: false,
              defaultAction: 'tickets',
              title: ""
            }}
            showFilters={{ searchRaw: false, group: false, role: false }}
          />
          </ModalBody>
        </ModalContent>
      </Modal>

    </Card>
  );
};

export default PaymentMethod;
